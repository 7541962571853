import React from 'react'
import { useSelector } from 'react-redux'
import { isMissionPaused } from '../../../store/selectors/sharedSelectors'
import Robot from './Robot'

import type { WorkingRobotLookup } from './constants'

type Props = {
	workingRobots: WorkingRobotLookup,
}

export default function Robots({ workingRobots }: Props): React$Node {
	let pauseStyle = {}
	const isPaused = useSelector(isMissionPaused)
	if (isPaused) {
		pauseStyle.pointerEvents = 'none'
	}

	return (
		<div
			className="h-full w-[25%] ml-24 p-2.5 grid grid-cols-2 grid-rows-2 overflow-hidden gap-4"
			style={pauseStyle}>
			<Robot id="1" className="row-start-1 col-start-1" isBusy={Boolean(workingRobots['1'])} />
			<Robot
				className="row-span-2 col-start-2 flex items-center"
				id="2"
				isBusy={Boolean(workingRobots['2'])}
			/>
			<Robot className="row-start-2 col-start-1" id="3" isBusy={Boolean(workingRobots['3'])} />
		</div>
	)
}
