// @flow
import React, { useEffect, useState } from 'react'
import config from '../config'
import { ONE_SECOND } from '../constants'
import styled from 'styled-components'
import { PRIMARY_PURPLE_FAINT, DARK_RED } from '../constants/styles'
import { GoIssueOpened } from 'react-icons/go'
import { useNoSleep, usePageIsVisible } from '../utility/hooks'
import { connectWebsocketToMission } from '../websockets/websocket'
import { determineJoinAs, getMissionCodeFromUrl } from '../utility/urls'
import { useMissionServerUrls } from '../websockets/urls'

const MISSION_SERVER_URL_RETRY_TIME = 2.5 * ONE_SECOND // ms
const COOKIE_CHECK_RETRY_TIME = 5 * ONE_SECOND

export default function AppSetup({ children }: { children: React$Node }): React$Node {
	const [cookieData, setCookieData] = useState(areCookiesSetupCorrectly())
	const [missionUrlResolvingError, setMissionUrlResolvingError] = useState(null)
	const [showErrorDetails, setShowErrorDetails] = useState(false)

	useNoSleep(usePageIsVisible())

	// get urls of mission server connected to missionCode
	const [urlData, setCurrentMissionCode] = useMissionServerUrls()
	const missionCodeFromUrl = getMissionCodeFromUrl()
	useEffect(() => {
		if (missionCodeFromUrl) {
			setCurrentMissionCode(missionCodeFromUrl.toLowerCase())
		}
	}, [missionCodeFromUrl, setCurrentMissionCode])

	// connect to mission once urls are loaded
	const { websocketUrl, missionServerUrl, missionCode: urlMissionCode } = urlData.data ?? {}
	useEffect(() => {
		if (websocketUrl && urlMissionCode) {
			try {
				connectWebsocketToMission(websocketUrl, urlMissionCode)

				if (missionServerUrl) {
					// store what mission is trying to be joined so user can reconnect later (ie. click reconnect button on login screen)
					localStorage.setItem(
						config.localStorageJoinPreviousMissionKey,
						JSON.stringify({
							...determineJoinAs(websocketUrl, urlMissionCode),
							missionServerUrl,
						})
					)
				}
			} catch (error) {
				console.error(error)
			}
		}
	}, [websocketUrl, urlMissionCode, missionServerUrl])

	const { error: urlResolvingError, attempt } = urlData
	useEffect(() => {
		if (!urlResolvingError) {
			return
		}
		console.error(urlResolvingError)
		console.error(
			`unable to get mission url due to the above error, trying again in ${MISSION_SERVER_URL_RETRY_TIME /
				ONE_SECOND} seconds`
		)
		setMissionUrlResolvingError(
			`${urlResolvingError.message}: ${urlResolvingError?.response?.status ??
				'could not connect'}. Will retry in ${MISSION_SERVER_URL_RETRY_TIME /
				ONE_SECOND} seconds. Tried ${attempt - 1} times.`
		)
	}, [urlResolvingError, attempt])

	useEffect(() => {
		const intervalId = setInterval(() => {
			const cookieData = areCookiesSetupCorrectly()
			setCookieData(cookieData)
			if (
				cookieData.websocketCookieExists &&
				cookieData.serverCookieExists &&
				!cookieData.cookiesDisabled
			) {
				clearInterval(intervalId)
			}
		}, COOKIE_CHECK_RETRY_TIME)
		return () => clearInterval(intervalId)
	}, [])

	if (cookieData.cookiesDisabled) {
		return (
			<ErrorWrapper>
				<Card>
					<ErrorTitle>
						<StyledIssue />
						Error: cookies are disabled
					</ErrorTitle>
					<div>You will have to enable cookies in your browser to continue.</div>
				</Card>
			</ErrorWrapper>
		)
	}

	if (urlResolvingError && (!cookieData.serverCookieExists || !cookieData.websocketCookieExists)) {
		return (
			<ErrorWrapper>
				<Card>
					<div>
						<ErrorTitle>
							<StyledIssue />
							Error: Missing necessary cookie(s):
						</ErrorTitle>
					</div>
					<div>
						This can usually be fixed by{' '}
						<a href={config.missionLoginUrl}>joining the mission again.</a>
					</div>
					<ClickableAside onClick={() => setShowErrorDetails(!showErrorDetails)}>
						{showErrorDetails ? 'hide' : 'show'} technical details{' '}
					</ClickableAside>
					{showErrorDetails ? (
						<>
							{!cookieData.serverCookieExists ? (
								<Aside>{'  '}- Server Session Cookie Missing</Aside>
							) : null}
							{!cookieData.websocketCookieExists ? (
								<Aside>{'  '}- Websocket Session Cookie Missing</Aside>
							) : null}{' '}
						</>
					) : null}
				</Card>
			</ErrorWrapper>
		)
	}

	if (urlResolvingError && missionUrlResolvingError) {
		return (
			<ErrorWrapper>
				<Card>
					<ErrorTitle>
						<StyledIssue />
						Error while connecting to mission:
					</ErrorTitle>
					<div>{missionUrlResolvingError}</div>
				</Card>
			</ErrorWrapper>
		)
	}

	return <>{children}</>
}

export type FetchError = { message: string, response?: { status?: string } }

const testCookieName = 'THIS_IS_A_TEST_COOKIE_TO_DETERMINE_IF_COOKIES_ARE_ENABLED'

/**
 * areCookiesSetupCorrectly - returns an object describing the current cookies stored and the cookie permissions
 *
 * @return {{
 *	 websocketCookieExists: boolean, true if the cookie needed for the websocket exists
 *	 serverCookieExists: boolean, true if the cookie needed for the mission-server exists
 *	 cookiesDisabled: boolean, true if cookies are disabled by the browser
 * }}
 */
function areCookiesSetupCorrectly(): {
	websocketCookieExists: boolean,
	serverCookieExists: boolean,
	cookiesDisabled: boolean,
} {
	// clear test cookie
	document.cookie = `${testCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
	// set test cookie
	document.cookie = `${testCookieName}=`
	return {
		cookiesDisabled: document.cookie.indexOf(testCookieName) === -1,
		websocketCookieExists: document.cookie.indexOf(config.websocketCookieName) !== -1,
		serverCookieExists: document.cookie.indexOf(config.serverCookieName) !== -1,
	}
}

const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	weight: 100%;

	${({ theme }) => `
	color: white;

	a {
		color: ${theme.secondary};
	}

	a:hover {
		color: ${theme.secondary}A0;
	}

	div {
		margin-top: ${theme.spacing};
	}
	`}
`
const Card = styled.div`
	${({ theme }) => `
	padding: ${theme.spacing2x};
	background-color: ${PRIMARY_PURPLE_FAINT};
	border-radius: ${theme.spacing};
	border: 1px solid ${theme.neutralLightest};
	`}
`

const StyledIssue = styled(GoIssueOpened)`
	color: ${DARK_RED};
	font-size: ${({ theme }) => theme.fontm};
	margin-right: ${({ theme }) => theme.spacing};
	justify-self: center;
	align-self: center;
`

const ErrorTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${({ theme }) => theme.spacing};
	color: white;
	font-weight: bold;
	font-size: ${({ theme }) => theme.fontm};
`

const Aside = styled.div`
	color: ${({ theme }) => theme.neutralVariant};

	font-size: ${({ theme }) => theme.fontxxs};
`

const ClickableAside = styled(Aside)`
	color: ${({ theme }) => theme.neutral};

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`
