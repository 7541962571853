import type { PingData } from './websocket'

import config from '../config'
import { ONE_SECOND } from '../constants'
import { WEBSOCKET_CLOSE_CODES } from './closeHandler'
import { closeWebsocketDueToNetworkError } from './websocket'
import { WEBSOCKET_STATUS, getWebsocketStatus } from './websocketStatus'
import uuid from 'uuid/v4'

import { useEffect } from 'react'

export const outstandingPings: Array<PingData> = [] // pings which have not been responded to
export const MAX_OUTSTANDING_PING_DELAY_BEFORE_DISCONNECT = 17 * ONE_SECOND // the maximum amount of time a ping can wait for a response before determining that the websocket has failed
let outstandingPingCheckerIntervalId: IntervalID | null = null // the id of the interval which checks if any ping has been waiting more than the maximum allowed time
export const OUTSTANDING_PING_CHECK_INTERVAL_DELAY = 5 * ONE_SECOND // how often the interval to check outstanding pings is ran

/**
 * handlePingResponse - handle when a ping is responded to by the server
 *
 * @param {string} forPingId - the id of the ping that the server responded to
 */
export function handlePingResponse(forPingId: string) {
	const pingIndex = outstandingPings.findIndex(({ pingId }) => pingId === forPingId)
	if (pingIndex < 0) {
		return
	}
	outstandingPings.splice(pingIndex, 1)
}

/**
 * registerPingForNetworkMonitoring - register that the given ping was sent to the server
 *
 * @param {PingData} pingData - the ping that sent to the server
 */
export function registerPingForNetworkMonitoring(pingData: PingData) {
	outstandingPings.push(pingData)
}

/**
 * clearAllOutstandingPings - stop listening for responses for all previously registered pings
 */
export function clearAllOutstandingPings() {
	outstandingPings.length = 0
}

/**
 * startOutStandingPingCheckerInterval - start an interval which will check which pings have not yet been responded to by the server.
 * If a ping has taken too long to be responded to, consider the connection broken and close the current websocket connection.
 */
function startOutStandingPingCheckerInterval() {
	if (!config.featureFlags.useManualWebsocketPings) {
		return
	}

	if (outstandingPingCheckerIntervalId) {
		clearInterval(outstandingPingCheckerIntervalId)
	}

	clearAllOutstandingPings()

	outstandingPingCheckerIntervalId = setInterval(() => {
		if (getWebsocketStatus().status !== WEBSOCKET_STATUS.CONNECTED) {
			return
		}
		const currentTime = Date.now()
		if (
			outstandingPings.some(
				({ clientTime }) => currentTime - clientTime > MAX_OUTSTANDING_PING_DELAY_BEFORE_DISCONNECT
			)
		) {
			closeWebsocketDueToNetworkError(WEBSOCKET_CLOSE_CODES.PING_FAILURE)
		}
	}, OUTSTANDING_PING_CHECK_INTERVAL_DELAY)
}

/**
 * stopOutStandingPingCheckerInterval - stop the interval which will kill the current websocket connection if pings
 * are not responded to in time.
 */
function stopOutStandingPingCheckerInterval() {
	clearAllOutstandingPings()

	if (outstandingPingCheckerIntervalId) {
		clearInterval(outstandingPingCheckerIntervalId)
	}
}

// ================================== React Bridge ======================================

// NOTE: these are sets to better handle situations in testing. Since screen cleanup (ie. component unmount) is ran after `resetNetworkHealthMonitoringGlobals` is called, if these were numbers, they would go negative.
let blockingNetworkChecks: Set<string> = new Set()
let enablingNetworkChecks: Set<string> = new Set()

/**
 * useWebsocketNetworkCheckPings - a hook which will monitor how long pings take to return, if it takes too long, it will
 * consider the network as broken and close the websocket to cause the connection to try to reconnect. Monitoring will only
 * be done if there is at least one use of this hook and all hooks have monitoring enabled.
 *
 * @param {boolean} enabled - if false, there will be no network checks using pings.
 *
 */
export function useWebsocketNetworkCheckPings(enabled?: boolean = true) {
	useEffect(() => {
		const instanceId = uuid()
		if (enabled) {
			if (enablingNetworkChecks.size === 0 && blockingNetworkChecks.size === 0) {
				startOutStandingPingCheckerInterval()
			}
			enablingNetworkChecks.add(instanceId)
		} else {
			if (blockingNetworkChecks.size === 0) {
				stopOutStandingPingCheckerInterval()
			}
			blockingNetworkChecks.add(instanceId)
		}

		return () => {
			if (enabled) {
				enablingNetworkChecks.delete(instanceId)
				if (enablingNetworkChecks.size === 0) {
					stopOutStandingPingCheckerInterval()
				}
			} else {
				blockingNetworkChecks.delete(instanceId)
				if (blockingNetworkChecks.size === 0 && enablingNetworkChecks.size > 0) {
					startOutStandingPingCheckerInterval()
				}
			}
		}
	}, [enabled])
}

export const TEST_ONLY = {
	resetNetworkHealthMonitoringGlobals: () => {
		blockingNetworkChecks.clear()
		enablingNetworkChecks.clear()
		stopOutStandingPingCheckerInterval()
	},
}
