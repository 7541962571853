// @flow
import React from 'react'
import { useMemo, useEffect, useCallback } from 'react'
import 'styled-components/macro'
import classnames from 'classnames'
import {
	StudentAnnotatableMarkdown,
	ANNOTATION_INFORMATION_METHODS,
	CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES,
} from '@mission.io/question-views'
import {
	useLiteracyEvent,
	useReadonlyCloseReadingAnnotations,
	type LiteracyEventForStudent,
} from '../hooks/useLiteracyEvent'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import type { AvailableAnnotationTool } from '@mission.io/mission-toolkit/actions'
import { CLOSE_READING, type StudentAnnotation } from '@mission.io/question-toolkit'
import {
	RoundToolWithOptionalPopover,
	Extension,
	ExtensionOption,
} from '../../CreativeCanvas/components/Toolbox'
import Markdown from '../../Markdown'
import { FaEraser } from 'react-icons/fa'
import { DivWithStyledScroll } from './DivWithStyledScroll'
import { sendMessage } from '../../../websockets/websocket'
import { HighlightIcon, UnderlineIcon, StrikethroughIcon } from '../../../images/literacyEvent'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as EraserIcon } from '../../../images/Eraser.svg'
import EraserArrowUrl from '../../../images/EraserArrow.svg'
import HighlightUrl from '../../../images/literacyEvent/Highlight.svg'
import UnderlineArrowUrl from '../../../images/literacyEvent/UnderlineArrow.svg'
import StrikethroughArrowUrl from '../../../images/literacyEvent/StrikethroughArrow.svg'

const HIGHLIGHT = CLOSE_READING.ANNOTATION_TOOL.HIGHLIGHT
const UNDERLINE = CLOSE_READING.ANNOTATION_TOOL.UNDERLINE
const STRIKE_THROUGH = CLOSE_READING.ANNOTATION_TOOL.STRIKE_THROUGH
const ERASE = 'ERASE'
type ColorOption = $Keys<typeof CLOSE_READING.COLOR>
type ToolOption = $Keys<typeof CLOSE_READING.ANNOTATION_TOOL>
type AnnotationStyleType =
	| { tool: 'ERASE' }
	| {
			tool: ToolOption,
			color: ColorOption,
	  }

// A map of annotation tools to their corresponding icons
const iconMap = {
	[HIGHLIGHT]: HighlightIcon,
	[UNDERLINE]: UnderlineIcon,
	[STRIKE_THROUGH]: StrikethroughIcon,
}

// A map of annotation tools to their corresponding cursor icon urls and coordinates
const iconCursorMap = {
	[HIGHLIGHT]: { url: HighlightUrl, coordinates: '0 32' },
	[UNDERLINE]: { url: UnderlineArrowUrl, coordinates: '0 0' },
	[STRIKE_THROUGH]: { url: StrikethroughArrowUrl, coordinates: '0 0' },
	[ERASE]: { url: EraserArrowUrl, coordinates: '0 0' },
}

/**
 * This hook is for managing the annotations for a close reading task.
 * It will update the annotations in the task data when the annotations are updated.
 * @param {$PropertyType<LiteracyEventForStudent, 'taskData'>} taskData the task data: includes the task instance id, the task type and the task student state
 * @returns
 */
function useOptimisticAnnotations(
	taskData: $PropertyType<LiteracyEventForStudent, 'taskData'>
): [StudentAnnotation[], (StudentAnnotation[]) => void] {
	const [annotations, _setAnnotations] = React.useState([])
	const mounted = React.useRef()

	useEffect(() => {
		if (mounted.current) {
			return
		}
		mounted.current = true
		if (taskData && taskData.type === LITERACY_EVENT['TASK']['TYPE']['CLOSE_READING']) {
			_setAnnotations(taskData.studentState.annotations)
		}
	}, [taskData])

	const setAnnotations = useCallback(
		(newAnnotations: StudentAnnotation[]) => {
			_setAnnotations(newAnnotations)
			if (!taskData) {
				return
			}
			sendMessage('LITERACY_EVENT_SET_CLOSE_READING_ANNOTATIONS', {
				taskInstanceId: taskData.instanceId,
				annotations: newAnnotations,
			})
		},
		[taskData]
	)

	return [annotations, setAnnotations]
}

/**
 * This component is for displaying the text event input during a literacy event.
 * The text event may be annotated if the task is a close reading task. Eventually we will support annotations for all text events.
 * @returns {React$Node}
 */
export default function TextEventInput({
	text,
	title,
	className,
}: {
	text: string,
	title?: string,
	className?: string,
}): React$Node {
	const taskData = useLiteracyEvent()?.taskData
	let availableAnnotationTools
	let annotationLimit
	let isCloseReadingTask = false
	if (taskData && taskData.type === LITERACY_EVENT['TASK']['TYPE']['CLOSE_READING']) {
		availableAnnotationTools = taskData.task.availableAnnotationTools
		isCloseReadingTask = true
		annotationLimit = taskData.task.annotationLimit
	}
	const [annotationStyle, _setAnnotationStyle] = React.useState<AnnotationStyleType | null>(null)
	const [annotations, setAnnotations] = useOptimisticAnnotations(taskData)
	const readonlyAnnotations = useReadonlyCloseReadingAnnotations()

	const annotationInformation = annotationStyle
		? annotationStyle.tool === ERASE
			? { method: ANNOTATION_INFORMATION_METHODS.ERASE_WHOLE_ANNOTATION, color: null, tool: null }
			: {
					tool: annotationStyle.tool,
					color: annotationStyle.color,
					method: ANNOTATION_INFORMATION_METHODS.ANNOTATE,
			  }
		: null

	// Do not allow the user to select a tool if they have reached the annotation limit
	const reachedAnnotationLimit = annotationLimit && annotations.length >= annotationLimit
	const setAnnotationStyle = useCallback(
		(style: AnnotationStyleType | null) => {
			if (reachedAnnotationLimit && style?.tool !== ERASE) {
				return
			}
			_setAnnotationStyle(style)
		},
		[reachedAnnotationLimit]
	)

	const [errorMessageType, setErrorMessageType] = React.useState<$Keys<
		typeof CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES
	> | null>(null)
	const handleError = React.useCallback(
		(error: $Keys<typeof CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES>) => {
			if (error === CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES.ANNOTATION_LIMIT_EXCEEDED) {
				setErrorMessageType(CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES.ANNOTATION_LIMIT_EXCEEDED)
			} else {
				console.error('Unhandled error type during annotations:', error)
			}
		},
		[]
	)

	// Checks if the annotation limit is still exceeded, resets the error if not.
	React.useEffect(() => {
		if (
			errorMessageType === CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES.ANNOTATION_LIMIT_EXCEEDED &&
			!reachedAnnotationLimit
		) {
			setErrorMessageType(null)
		}
	}, [errorMessageType, reachedAnnotationLimit])

	return (
		<div className={classnames('h-full flex flex-col', className)}>
			<DivWithStyledScroll className={'p-4 h-full'}>
				{title && <h3 className="text-black w-fit">{title}</h3>}
				{isCloseReadingTask || readonlyAnnotations?.length ? (
					<div
						className="can-highlight"
						translate="no" // This is to prevent the browser from translating the text since student annotations will break with translations.
						style={{
							// This cursor style is for the eraser tool the values 12 12 need to be exactly half of the width/height defined on the svg in images/Eraser.svg
							cursor: annotationStyle?.tool
								? `url(${iconCursorMap[annotationStyle.tool].url}) ${
										iconCursorMap[annotationStyle.tool].coordinates
								  }, auto`
								: 'auto',
						}}>
						<StudentAnnotatableMarkdown
							annotationLimit={annotationLimit}
							onError={handleError}
							className="mt-2"
							markdown={text}
							readOnlyAnnotations={readonlyAnnotations}
							annotations={annotations}
							setAnnotations={setAnnotations}
							annotationInformation={isCloseReadingTask ? annotationInformation : null}
						/>
					</div>
				) : (
					<Markdown textToSpeechPosition="start" disabledComponents={['a', 'img']}>
						{text}
					</Markdown>
				)}
			</DivWithStyledScroll>
			{errorMessageType === CLOSE_ANNOTATION_ERROR_MESSAGE_TYPES.ANNOTATION_LIMIT_EXCEEDED && (
				<div className="p-4 flex-1">
					<span className="italic text-white bg-error/45 rounded p-2 text-base">
						Looks like you have reached your limit.{' '}
						<button
							className="inline-block space-1 rounded-full  px-3 border-white border hover:bg-primary-600/80 cursor-pointer"
							onClick={() => {
								_setAnnotationStyle({ tool: ERASE })
							}}>
							Erase <FaEraser className="inline" />
						</button>
					</span>
				</div>
			)}

			{availableAnnotationTools && (
				<AnnotationToolbar
					selected={annotationStyle}
					onSelect={setAnnotationStyle}
					allowedTools={reachedAnnotationLimit ? [] : availableAnnotationTools}
				/>
			)}
		</div>
	)
}

/**
 * A toolbar for selecting annotation tool and color, or an eraser
 * @param {AnnotationStyleType} selected the selected style (tool and color)
 * @param {function} onSelect function to call when a style (tool and color) is selected
 * @param {AvailableAnnotationTool[]} allowedTools the available tools and colors
 */
function AnnotationToolbar({
	selected,
	onSelect,
	allowedTools,
}: {
	allowedTools: AvailableAnnotationTool[],
	selected: AnnotationStyleType | null,
	onSelect: (style: AnnotationStyleType | null) => void,
}) {
	const [expandedTool, setExpandedTool] = React.useState<ToolOption | null>(null)
	const toolOptions: {
		HIGHLIGHT?: ColorOption[],
		STRIKE_THROUGH?: ColorOption[],
		UNDERLINE?: ColorOption[],
	} = useMemo(() => {
		const options = {}
		allowedTools.forEach(tool => {
			options[tool.type] ??= new Set()
			tool.colors.forEach(color => options[tool.type].add(color))
		})
		Object.keys(options).forEach(tool => {
			options[tool] = Array.from(options[tool]).sort(
				(a, b) => colorMap[a].order - colorMap[b].order
			)
		})
		return options
	}, [allowedTools])

	return (
		<div className="absolute p-2  w-14 h-fit py-8 top-1/2 -translate-y-1/2 -ml-16 bg-primary-600 rounded-l-xl flex flex-col justify-center gap-2">
			{toolOptions[HIGHLIGHT] && (
				<AnnotationOption
					tool={HIGHLIGHT}
					colors={toolOptions[HIGHLIGHT]}
					{...{ selected, onSelect, expandedTool, setExpandedTool }}
				/>
			)}
			{toolOptions[UNDERLINE] && (
				<AnnotationOption
					tool={UNDERLINE}
					colors={toolOptions[UNDERLINE]}
					{...{ selected, onSelect, expandedTool, setExpandedTool }}
				/>
			)}
			{toolOptions[STRIKE_THROUGH] && (
				<AnnotationOption
					tool={STRIKE_THROUGH}
					colors={toolOptions[STRIKE_THROUGH]}
					{...{ selected, onSelect, expandedTool, setExpandedTool }}
				/>
			)}
			<RoundToolWithOptionalPopover
				buttonIcon={EraserIcon}
				onClick={() => onSelect({ tool: ERASE })}
				isActive={selected?.tool === ERASE}
			/>
		</div>
	)
}

// A map of color options to their corresponding tailwind class names
const colorMap = {
	[CLOSE_READING.COLOR.ORANGE]: {
		order: 1,
		className: 'bg-accent-orange',
		ring: 'ring-accent-orange',
	}, // use accent-orange from mission.io/styles game theme.
	[CLOSE_READING.COLOR.YELLOW]: { order: 2, className: 'bg-prose-gold', ring: 'ring-prose-gold' }, // use a lightened prose.gold from mission.io/styles game theme.
	[CLOSE_READING.COLOR.GREEN]: {
		order: 3,
		className: 'bg-accent-green',
		ring: 'ring-accent-green',
	}, // use accent-green from mission.io/styles game theme.
	[CLOSE_READING.COLOR.BLUE]: { order: 4, className: 'bg-accent-blue', ring: 'ring-accent-blue' }, // use accent-blue from mission.io/styles game theme.
	[CLOSE_READING.COLOR.PURPLE]: {
		order: 5,
		className: 'bg-prose-lavender',
		ring: 'ring-prose-lavender',
	}, // use prose.lavender from mission.io/styles game theme.
	[CLOSE_READING.COLOR.PINK]: { order: 6, className: 'bg-prose-coral', ring: 'ring-prose-coral' }, // use prose.coral from mission.io/styles game theme.
	[CLOSE_READING.COLOR.BLACK]: { order: 7, className: 'bg-black', ring: 'ring-black' }, // mission.io/styles black
}

/**
 * A button for selecting an annotation tool. When clicked, it displays a color picker.
 * @param {Function} props.setExpandedTool function to set the expanded tool
 * @param {HIGHLIGHT|UNDERLINE|STRIKE_THROUGH} props.expandedTool the expanded tool
 * @param {HIGHLIGHT|UNDERLINE|STRIKE_THROUGH} props.tool the tool
 * @param {AnnotationStyleType} props.selected the selected style (tool and color)
 * @param {function} props.onSelect function to call when a style (tool and color) is selected
 * @param {Object} props.colors the available tool colors
 */
const AnnotationOption = ({
	setExpandedTool,
	expandedTool,
	tool,
	colors,
	selected,
	onSelect,
}: {
	setExpandedTool: (ToolOption | null) => void,
	expandedTool: ToolOption | null,
	tool: ToolOption,
	colors: ColorOption[],
	selected: AnnotationStyleType | null,
	onSelect: AnnotationStyleType => void,
}) => {
	return (
		<RoundToolWithOptionalPopover
			className={
				selected
					? selected.tool === tool
						? `${colorMap[selected.color].ring} ring ring-offset-1 ring-offset-primary-600`
						: ''
					: ''
			}
			buttonIcon={iconMap[tool]}
			onClick={() => {
				// if there is only one color, we can just select it instead of opening the color picker
				if (colors.length === 1) {
					onSelect({ tool, color: colors[0] })
					return
				}
				setExpandedTool(tool)
				// By default, the color chosen is the first color in the list
				let color: ColorOption = colors[0]
				if (selected) {
					// if the current tool is the eraser, there is no color so we should use the first color
					if (selected.tool === ERASE) {
						color = colors[0]
					} else if (colors.includes(selected.color)) {
						// auto select the color that was previously selected if available
						color = selected.color
					} else {
						color = colors[0]
					}
				}
				onSelect({ tool, color })
			}}
			onClickOutside={() => (expandedTool === tool ? setExpandedTool(null) : undefined)}
			isActive={selected?.tool === tool}
			popover={
				<Extension
					show={expandedTool === tool}
					isToolActive={selected?.tool === tool}
					extensionClassName=" [--item-width:1.2em] ![--grid-gap:6px]">
					<ColorPicker
						selected={selected ? (selected.tool === tool ? selected?.color : undefined) : undefined}
						onSelect={color => {
							onSelect({ tool: tool, color })
							// Close the picker right after the color is selected
							setExpandedTool(null)
						}}
						options={colors}
					/>
				</Extension>
			}
		/>
	)
}

/**
 * A color picker for selecting annotation colors.
 */
const ColorPicker = ({
	selected,
	onSelect,
	options,
}: {
	selected?: ColorOption,
	onSelect: ColorOption => void,
	options: ColorOption[],
}) => {
	return options.map(color => (
		<ExtensionOption
			key={color}
			onClick={e => {
				e.stopPropagation()
				onSelect(color)
			}}
			className={classnames(
				colorMap[color].className,
				'rounded-full relative',
				selected === color ? 'ring-2 ring-accent-blue ring-offset-2 ring-offset-primary-700' : ''
			)}
		/>
	))
}
