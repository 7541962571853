import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { Overlay } from '../helperComponents'
import styled, { keyframes } from 'styled-components/macro'
import { ONE_SECOND } from '../../../constants'
import classnames from 'classnames'
const INTRO_VIDEO_URL =
	'https://resources-cdn.mission.io/public/videos/literacyEvent/IncomingInformation.webm'

const VIDEO_DURATION = 5 * ONE_SECOND
/**
 * Renders an introduction video frame for a literacy event.
 * @param {Object} props - The component props.
 * @param {Function} props.onEnd - The function to be called when the video ends.
 * @returns {ReactNode} - The rendered component.
 */
export function IntroFrame({ onEnd }: { onEnd: () => void }): React$Node {
	const videoRef = useRef(null)
	const videoHasStarted = useRef()
	const onEndRef = useRef(onEnd)

	// Handle fallback if video never plays
	const [showFallback, setShowFallback] = useState(false)
	useLayoutEffect(() => {
		onEndRef.current = onEnd
	}, [onEnd])

	// On the off chance that the video never loads or starts, then we still want to move on from this frame.
	useEffect(() => {
		let onVideoEndTimeout
		const timeout = setTimeout(() => {
			if (!videoHasStarted.current) {
				setShowFallback(true)
				onVideoEndTimeout = setTimeout(() => onEndRef.current(), VIDEO_DURATION)
			}
		}, 1000)
		return () => {
			clearTimeout(timeout)
			if (onVideoEndTimeout) {
				clearTimeout(onVideoEndTimeout)
			}
		}
	}, [])

	return (
		<>
			{showFallback ? (
				<Fallback />
			) : (
				<Overlay
					className={classnames(
						'bg-black flex justify-center items-center',
						videoHasStarted && 'animate-fade-in'
					)}
					style={{ animationDuration: '7s' }}>
					<video
						ref={videoRef}
						autoPlay
						muted
						onPlay={() => {
							videoHasStarted.current = true
						}}
						onEnded={onEnd}
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
						<source src={INTRO_VIDEO_URL} />
					</video>
				</Overlay>
			)}
		</>
	)
}

const Fallback = () => {
	return (
		<Overlay className="animate-fade-in flex justify-center items-center">
			<TransparentHexes>
				<div className="centered-hexes">
					<div className="hex-row">
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
					</div>
					<div className="hex-row even">
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
					</div>
					<div className="hex-row">
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
						<div className="hex">
							<div className="top"></div>
							<div className="middle"></div>
							<div className="bottom"></div>
						</div>
					</div>
				</div>
				<h1 className="text-center">Incoming Information...</h1>
			</TransparentHexes>
		</Overlay>
	)
}

const throb = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
`
const hexHeight = 70
const hexWidth = 124
const TransparentHexes = styled.div`
	animation: ${throb} 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
	position: absolute;
	width: 100%;
	.centered-hexes {
		width: fit-content;
		margin: 0 auto;
	}
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.hex {
		float: left;
		margin-left: 3px;
		margin-bottom: -${hexWidth / 4}px;
	}
	.hex .top {
		width: 0;
		border-bottom: ${hexHeight / 2}px solid var(--color-faded-on-base);
		border-left: ${hexWidth / 2}px solid transparent;
		border-right: ${hexWidth / 2}px solid transparent;
	}
	.hex .middle {
		width: ${hexWidth}px;
		height: ${hexHeight}px;
		background: var(--color-faded-on-base);
	}
	.hex .bottom {
		width: 0;
		border-top: ${hexHeight / 2}px solid var(--color-faded-on-base);
		border-left: ${hexWidth / 2}px solid transparent;
		border-right: ${hexWidth / 2}px solid transparent;
	}
	.hex-row {
		clear: left;
	}
	.hex-row.even {
		margin-left: ${hexWidth / 2 + 1}px;
	}
`
