import React from 'react'
import { Station } from '../../components/basics'
import ShooterGame from './components/ShooterGame'
import 'styled-components/macro'
import { sendMessage } from '../../websockets/websocket'

export type OnHitFunctionType = ({ at: { x: number, y: number }, id: string }) => void

export default function TractorBeam(): React$Node {
	const hit: OnHitFunctionType = payload => {
		sendMessage(
			'TRACTOR_BEAM',
			{ targetHit: true, instanceId: payload.id },
			{ location: payload.at }
		)
	}

	const miss = () => {
		sendMessage('TRACTOR_BEAM', { targetHit: false })
	}

	return (
		<Station css="padding: 0;">
			<ShooterGame onHit={hit} onMiss={miss} />
		</Station>
	)
}
