// @flow
import createReducer, { type Reducer } from '../createReducer'
import { STATIC_DATA, JUNIOR_STATIC_DATA, JUNIOR_PLUS_STATIC_DATA } from '../actionTypes'
import { getMissionEvents } from './general'
import {
	getEstimatedPhaseAndCheckpointDurations,
	getCurrentPhaseAndCheckpoint as getCurrentPhaseAndCheckpointFromMission,
	getCulminatingMomentDataFromMission,
	shouldShowSurveyFromMission,
	getPhaseFromMission,
} from '@mission.io/mission-toolkit'
import { MISSION_CATEGORIES, MISSION_PHASES } from '@mission.io/mission-toolkit/constants'
import { getFullState } from '../selectors/sharedSelectors'
import type { Room, Alert, StationEnum } from '../../types'
import type { ReduxStore } from '../rootReducer'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import type {
	SimulationShape,
	BothFullMissionStates,
	Map,
	MissionEvent,
	MissionCategory,
	Visited,
	CulminatingMomentData,
	HealthTaskInfo,
	CondensedActionGraph,
} from '@mission.io/mission-toolkit'
import type { Action, CulminatingMomentScreenAction } from '@mission.io/mission-toolkit/actions'
import { values } from '../../utility/functions'
import { useSelector } from 'react-redux'
import { getTrainingSensorData } from '../selectors/jrPlusState/training'
import type { IdMap } from '../../types/utilityTypes'

export const HIGH_PRIORITY = 'HIGH'
export const LOW_PRIORITY = 'LOW'
export const PRIORITY_TYPE = {
	HIGH: HIGH_PRIORITY,
	LOW: LOW_PRIORITY,
}

export type Grades =
	| 'K'
	| '1'
	| '2'
	| '3'
	| '4'
	| '5'
	| '6'
	| '7'
	| '8'
	| '9'
	| '10'
	| '11'
	| '12'
	| 'other'

export type CurrentPhaseAndCheckpoint = {
	currentCheck?: Visited | null,
	currentPhase?: Visited | null,
}

type Actions = { [actionId: string]: Action<string> }

// Data that comes in the static data message from the server and is set directly on the store
type SharedPayloadAndStore = {
	missionCode: ?string,
	missionName: ?string,
	defaultStation: ?StationEnum,
	questions: Question[],
	healthTasksInfo: IdMap<HealthTaskInfo>,
	classGrades: Grades[],
	alerts: { [alertId: string]: Alert },
	rooms: { [roomId: string]: Room },
	defaultScannerPosition: number,
	missionCategory: ?MissionCategory,
	questionActions: { [string]: CulminatingMomentScreenAction<string> },
	maps?: Map[],
	condensedActionGraph: CondensedActionGraph<string> | null,
}

export type StaticDataStore = {
	...SharedPayloadAndStore,
	actions: Actions,
	characters: mixed[],
	simulation: SimulationShape<> | null,
}

// The payload of the static data message as we expect it from the server
type StaticDataPayload = {
	...SharedPayloadAndStore,
	actions?: Actions,
	characters?: mixed[],
	initialScreenActionId?: string,
}

/**
 * Gets the initial state for the static data redux store
 */
export function getInitialState(): StaticDataStore {
	return {
		missionCode: null,
		missionName: null,
		questions: [],
		healthTasksInfo: {},
		classGrades: [],
		alerts: {},
		rooms: {},
		defaultStation: null,
		defaultScannerPosition: 0,
		missionCategory: null,
		actions: {},
		questionActions: {},
		characters: [],
		simulation: null,
		condensedActionGraph: null,
	}
}

export default (createReducer<StaticDataStore>(getInitialState(), {
	[STATIC_DATA]: (state: StaticDataStore, { payload }: { payload: StaticDataPayload }) => {
		return {
			...state,
			...readStaticData(payload),
		}
	},
	[JUNIOR_STATIC_DATA]: (state, { payload }) => {
		return {
			...state,
			...readStaticData(payload),
		}
	},
	[JUNIOR_PLUS_STATIC_DATA]: (state, { payload }) => {
		return {
			...state,
			...readStaticData(payload),
		}
	},
}): Reducer<StaticDataStore>)

// Selectors

export function getQuestionById(state: ReduxStore, questionId: ?string): ?Question {
	if (!questionId) {
		return null
	}
	const questions =
		state.jrPlusState.training.status === 'ACTIVE'
			? state.jrPlusState.training.questions
			: state.staticData.questions
	for (let i = 0; i < questions.length; i++) {
		if (questions[i]._id === questionId) {
			return questions[i]
		}
	}
	return null
}

export function getMissionCode(state: ReduxStore): ?string {
	return state.staticData.missionCode
}

export function getMissionName(state: ReduxStore): ?string {
	return state.staticData.missionName
}

export function getQuestionActions(
	state: ReduxStore
): IdMap<CulminatingMomentScreenAction<string>> {
	return state.staticData.questionActions
}

export function getHealthTaskInfo(state: ReduxStore): IdMap<HealthTaskInfo> {
	return state.staticData.healthTasksInfo
}

export function getClassGrades(state: ReduxStore): Grades[] {
	return state.staticData.classGrades || []
}

export function getRooms(state: ReduxStore): IdMap<Room> {
	return state.staticData.rooms
}

export function getAllAlerts(state: ReduxStore): IdMap<Alert> {
	return state.staticData.alerts
}

export function getScannerDefault(state: ReduxStore): number {
	return state.staticData.defaultScannerPosition
}

/**
 * Get Category of mission (on site, remote...)
 */
export function getMissionCategory(state: ReduxStore): ?MissionCategory {
	return state.staticData.missionCategory
}

/** Checks if mission is remote */
export function isRemote(state: ReduxStore): boolean {
	const missionCategory = getMissionCategory(state)
	return !!missionCategory && missionCategory !== MISSION_CATEGORIES.ON_SITE
}

// Get all questions for the mission
export function getAllQuestions(state: ReduxStore): Question[] {
	return state.staticData.questions
}

// Gets all actions
export function getActions(state: ReduxStore): Actions {
	return state.staticData.actions
}

// Gets all characters
export function getCharacters(state: ReduxStore): mixed[] {
	return state.staticData.characters
}

// Timer stuff

export const getSimulation = (state: ReduxStore): ?SimulationShape<> => {
	return state.staticData.simulation
}
export function isInSurveyMode(state: ReduxStore): boolean {
	return shouldShowSurveyFromMission(getFullState(state))
}

/**
 * Several functions from mission-toolkit require the following parameters: mission state, simulation, and events.
 * This function fetches all the parameters, makes sure they exists and returns them in typed array.
 * @param {ReduxStore} state redux store
 * @returns { ?[?BothFullMissionStates, SimulationShape<>, MissionEvent[]] } parameters needed for the below functions
 */
export function getParams(
	state: ReduxStore
): ?[BothFullMissionStates | null, SimulationShape<>, MissionEvent[]] {
	const simulation = getSimulation(state)
	const events = getMissionEvents(state)
	if (!simulation || !events) {
		return null
	}
	return [getFullState(state), simulation, events]
}

/**
 * Gets the current phase and checkpoint for the mission
 * @param {State} state redux store
 * @returns {unknown} an object containing the current phase and checkpoint
 */
export function getCurrentPhaseAndCheckpoint(state: ReduxStore): CurrentPhaseAndCheckpoint {
	const params = getParams(state)
	if (!params) return { currentCheck: null, currentPhase: null }
	return getCurrentPhaseAndCheckpointFromMission(...params)
}

/**
 * Reads static data and returns the values with a simulation shape object attached
 * @param {StaticDataPayload} staticData Static data from the server
 * @returns {StaticDataStore} Our interpretation of the static data so we can use simulation selectors from mission toolkit
 */
function readStaticData(staticData: StaticDataPayload): StaticDataStore {
	const simulation = {
		actions: staticData.actions || {},
		_id: 'this',
		initialScreenActionId: staticData.initialScreenActionId || 'temp',
		expectedPhaseAndCheckpointDurations: [],
	}

	return {
		...staticData,
		actions: staticData.actions || {},
		characters: staticData.characters || [],
		simulation: {
			...simulation,
			expectedPhaseAndCheckpointDurations: getEstimatedPhaseAndCheckpointDurations(simulation),
		},
	}
}

/**
 * Returns the Culminating Moment Screens in the actions.
 */
export function getCulminatingMomentActions(
	store: ReduxStore
): Array<CulminatingMomentScreenAction<string>> {
	const simulation = getSimulation(store)
	const actions = simulation ? simulation.actions : {}

	let culminatingMomentScreens = []

	values(actions).forEach(action => {
		if (action.type === 'CULMINATING_MOMENT_SCREEN') {
			culminatingMomentScreens.push(action)
		}
	})
	// $FlowFixMe[prop-missing] We know that the actions in this array are culminatingMomentScreen and has questionInfo prop
	return culminatingMomentScreens
}

export function getCulminatingMomentData(state: ReduxStore): ?CulminatingMomentData {
	return getCulminatingMomentDataFromMission(getFullState(state))
}

/**
 * Get a game action by its id
 */
export function getGameActionById(state: ReduxStore, actionId: string): ?Action<string> {
	return getActions(state)[actionId]
}

/**
 * Determine if a mission has ended.
 *
 * @param {State} state redux store
 * @returns {boolean} true if the mission has ended false otherwise
 */
export function getHasMissionEnded(state: ReduxStore): boolean {
	return getPhaseFromMission(getFullState(state)) === MISSION_PHASES.CLOSED
}

/**
 * Gets all maps in the static data store.
 */
export function getMaps(state: ReduxStore): Map[] {
	return state.staticData.maps || []
}

/**
 * getCondensedActionGraph - get the condensed action graph for the current mission's simulation
 *
 * @param {ReduxStore} state - the current redux store
 *
 * @return {?CondensedActionGraph<string>} - the condensedActionGraph for the current mission's simulation
 */
export function getCondensedActionGraph(state: ReduxStore): ?CondensedActionGraph<string> {
	return state.staticData.condensedActionGraph
}

/**
 * Gets all maps in the static data store
 */
export function useMaps(): Map[] {
	return useSelector(store => {
		const trainingMap = getTrainingSensorData(store)?.map
		const maps = getMaps(store)
		if (trainingMap) {
			return [trainingMap, ...maps]
		}
		return maps
	})
}
