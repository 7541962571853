import config from '../config'

/**
 Change the url to reflect that we are "connected" to a mission
 * @param {string} missionCode include the mission code in the new url 
 */
export function connectToMission(missionCode: string, studentId?: string) {
	if (studentId) {
		sessionStorage.setItem(config.sessionStorageStudentIdKey, studentId)
	} else {
		sessionStorage.removeItem(config.sessionStorageStudentIdKey)
	}
	const newUrl = `/${missionCode}/run`
	if (window.location.pathname !== newUrl) {
		window.location.href = newUrl
	}
}

/**
 * Checks if url says we are connected to a mission
 * @returns {boolean}
 */
export function isConnectedToMission(): boolean {
	return isWantingToRunMissionAsStudent() || isWantingToRunMissionAsControl()
}

/**
 * Checks if url says we want to connect as a student to the mission
 * @returns {boolean}
 */
export function isWantingToRunMissionAsStudent(): boolean {
	const url = window.location.pathname
	const lastItemIndex = url.lastIndexOf('/') + 1
	if (lastItemIndex < window.location.pathname.length) {
		const action = url.substring(lastItemIndex)
		return action === 'run'
	}
	return false
}

/**
 * Checks if url says we want to connect as a controller to the mission
 * @returns {boolean}
 */
export function isWantingToRunMissionAsControl(): boolean {
	const url = window.location.pathname
	const lastItemIndex = url.lastIndexOf('/') + 1
	if (lastItemIndex < window.location.pathname.length) {
		const action = url.substring(lastItemIndex)
		return action === 'control'
	}
	return false
}

/**
 * An alias for `isWantingToRunMissionAsControl`
 * @returns {boolean}
 */
export function isController(): boolean {
	return isWantingToRunMissionAsControl()
}

/**
 * Gets mission code from the current url
 * @returns {?string} the missionCode
 */
export function getMissionCodeFromUrl(): ?string {
	const url = window.location.pathname
	return url.split('/')[1] || null
}

/**
 * getNewDomain - get the updated domain from the current domain (hostname). This will translate any `infinid.io` website domain to their `mission.io` counterpart.
 *
 * @param {string} currentDomain - the current domain
 *
 * @return {?string} - the new domain of the website that used to be hosted at the "currentDomain". null/undefined if the website has not moved.
 */
function getNewDomain(currentDomain: string, path: string): ?string {
	if (currentDomain === 'infinid.io' || currentDomain === 'www.infinid.io') {
		if (!path || path === '/') {
			return 'mission.io'
		}
		return 'launch.mission.io'
	} else if (currentDomain === 'staging.infinid.io') {
		return 'staging.launch.mission.io'
	}

	if (!currentDomain.endsWith('.infinid.io')) {
		return null
	}

	return currentDomain.replace(/\.infinid\.io$/, '.mission.io')
}

/**
 * If the url is an `infinid.io` url, it will return the new `mission.io` url. It will return null/undefined if there is no new url (ie. it has a mission.io domain)
 *
 * @returns {?string}
 */
export function getNewMissionIOUrlFromInfinidIOUrl(url: string): ?string {
	try {
		const currentUrl = new URL(url)
		const newDomain = getNewDomain(currentUrl.hostname, currentUrl.pathname)
		if (!newDomain) {
			return null
		}

		currentUrl.hostname = newDomain
		return currentUrl.toString()
	} catch (error) {
		console.error(error)
		return null
	}
}

type JoinAs = {
	isTeacher?: boolean,
	studentId?: string,
	connectionType?: string,
	missionCode: string,
}
export function determineJoinAs(url: string, missionCode: string): JoinAs {
	const joinAs: JoinAs = {
		missionCode,
	}
	if (isWantingToRunMissionAsControl()) {
		joinAs.isTeacher = true
		joinAs.connectionType = ['CONTROL', 'STUDENT'].join('&connectionType=')
	} else if (isWantingToRunMissionAsStudent()) {
		const studentId = sessionStorage.getItem(config.sessionStorageStudentIdKey)
		if (!studentId) {
			window.location.href = '/'
			// this error should never be thrown because we redirect to a new location above.
			throw new Error('Tried to connect to the mission as a student, but failed to redirect')
		}
		joinAs.studentId = studentId
	}

	return joinAs
}

/**
 * addJoiningInfoToWebsocketUrl - add joining information (ie. studentId, joiningAs, ...etc) as query parameters to the websocket url
 *
 * @param  {string} baseWebsocketUrl - the websocket url on the mission server
 * @param  {string} missionCode - the code of the mission
 *
 * @returns {string} - the websocket url with joining information
 */
export function addJoiningInfoToWebsocketUrl(
	baseWebsocketUrl: string,
	missionCode: string
): string {
	const joinAs: JoinAs = determineJoinAs(baseWebsocketUrl, missionCode)

	return `${baseWebsocketUrl}?${Object.keys(joinAs)
		.map(key => `${key}=${String(joinAs[key])}`)
		.join('&')}`
}

/**
 * Gets the websocket url for the current mission server
 */
export function getMissionServerWebsocketAddress(missionServerUrl: string): string {
	const protocol = config.isDev ? 'ws' : 'wss'
	return `${protocol}://${missionServerUrl}/ws`
}

/**
 * Given a url without a protocol, gets the url with the correct protocol. In dev, uses `http`, otherwise `https`
 */
export function getMissionServerHttpAddress(missionServerUrl: string): string {
	const protocol = config.isDev ? 'http' : 'https'
	return `${protocol}://${missionServerUrl}`
}
