import { SET_MISSION_ANALYTICS } from '../actionTypes'
import type { ClassStudentAnalytics } from '@mission.io/mission-toolkit'
import type { AnalyticsStore as AnalyticsForMissionPlayer } from '@mission.io/mission-player'

export type AnalyticsStore = ?{
	...AnalyticsForMissionPlayer,
	pastAnalytics: Array<{
		analytics: ClassStudentAnalytics,
		missionName: string,
		createdTime: Date,
	}>,
	hasIncompleteAnalytics: boolean,
}

type SetAnalyticsStateAction = {
	type: 'SET_MISSION_ANALYTICS',
	payload: AnalyticsStore,
}

type AnalyticsActions = SetAnalyticsStateAction

/**
 * Gets the initial state for the analytics redux store
 */
export function getInitialState(): AnalyticsStore {
	return null
}

export default function analyticsReducer(
	state: AnalyticsStore = getInitialState(),
	action: AnalyticsActions
): AnalyticsStore {
	switch (action.type) {
		case SET_MISSION_ANALYTICS: {
			return action.payload
		}
		default:
			return state
	}
}
