import React from 'react'
import { useSelector } from 'react-redux'
import Wifi from './Wifi/WifiSymbol'
import ShooterGame from '../../components/ShooterGame'

import {
	getMyReception,
	isCommunicationActive as isActive,
	getGroupRequiredReception,
	getGroupReception,
} from '../../store/selectors/jrState'

import type { HitResults } from '../../components/ShooterGame/ShooterGame'
import { PROJECTILE_TYPE } from '../../components/ShooterGame/Projectile'
import { sendMessage } from '../../websockets/websocket'

const PROJECTILE_WIDTH = 40
const PROJECTILE_HEIGHT = 15

const SATELLITE_RIGHT_OFFSET = 170
const SATELLITE_DRIFT = 150
const SATELLITE_DRIFT_SPEED = 2219

/**
 * The K-3 Communication Station. Students must click on the canvas to fire a radio wave signal at a moving satellite.
 */
export default function Communication(): React$Node {
	const active = useSelector(isActive)
	const myReception = useSelector(getMyReception)
	const groupReception = useSelector(getGroupReception)
	const targetReception = useSelector(getGroupRequiredReception)

	const onHit = ({ enemyLocation }: HitResults) => {
		const pointLocation = enemyLocation
			? {
					x: (enemyLocation.left + enemyLocation.right) / 2,
					y: (enemyLocation.top + enemyLocation.bottom) / 2,
			  }
			: { x: '50%', y: '50%' }
		sendMessage('COMMUNICATION', { hit: true }, { location: pointLocation })
	}

	const onMiss = () => {
		sendMessage('COMMUNICATION', { hit: false })
	}

	return (
		<div className="w-full h-full relative">
			<ShooterGame
				active={active}
				onHit={onHit}
				onMiss={onMiss}
				enemyRightOffset={SATELLITE_RIGHT_OFFSET}
				enemyDrift={SATELLITE_DRIFT}
				enemyDriftSpeed={SATELLITE_DRIFT_SPEED}
				projectileWidth={PROJECTILE_WIDTH}
				projectileHeight={PROJECTILE_HEIGHT}
				projectileType={PROJECTILE_TYPE.RADIO_WAVE}
				imageType="SATELLITE">
				<Wifi targetReception={targetReception} currentReception={groupReception} />
				<div className="absolute top-10 right-12 text-primary-200 text-left text-lg">
					<div>Your Hits: {myReception}</div>
					<div>Team Hits: {groupReception}</div>
				</div>
			</ShooterGame>
		</div>
	)
}
