import React from 'react'
import { ReactModal as Modal } from '../../basics'
import 'styled-components/macro'
import { ExpandIcon } from '../../CreativeCanvas/components/CanvasGrid'
import { LITERACY_EVENT_Z_INDEX } from '../../../constants/styles'
import { useIsWithinDataViewerContext } from '../../ContextualData/ExpandedDataProvider'
import {
	EXPANDED_DATA_READER_ID,
	EXPANDED_DATA_READER_PARENT_ID,
} from '../../ContextualData/ExpandedDataProvider'

/**
 * A component that displays an icon in the bottom right corner of its next relative ancestor.
 * When clicked, it will open a modal with the children passed to it.
 * @param {React$Node} props.children the content to display in the modal.
 * @param {string|void} props.className the class name to apply to the icon.
 * @returns {React$Node}
 */
export function ContentExpander({
	children,
	className,
	controlModal,
	onExpand,
	modalStyles = {},
}: {
	children: React$Node,
	className?: string,
	controlModal?: {
		isOpen: boolean,
		setIsOpen: (boolean | (boolean => boolean)) => void,
	},
	onExpand?: () => void,
	modalStyles?: {
		overlay?: any,
		content?: any,
	},
}): React$Node {
	const [_expanded, _setExpanded] = React.useState(false)
	const expanded = controlModal ? controlModal.isOpen : _expanded
	const setExpanded = controlModal ? controlModal.setIsOpen : _setExpanded

	/**
	 * The content expander could be used in a data viewer context or a literacy event context.
	 * In the data viewer context, the modal should be rendered in the expanded data reader parent.
	 * In the literacy event context, the modal should be rendered in the document body.
	 */
	const isWithinDataViewerContext = useIsWithinDataViewerContext()
	let props: {
		parentSelector?: () => null | HTMLElement,
		appElement?: null | HTMLElement,
	} = {}
	if (isWithinDataViewerContext) {
		props =
			document.getElementById(EXPANDED_DATA_READER_PARENT_ID) &&
			document.getElementById(EXPANDED_DATA_READER_ID)
				? {
						parentSelector: () => document.getElementById(EXPANDED_DATA_READER_PARENT_ID),
						appElement: document.getElementById(EXPANDED_DATA_READER_ID),
				  }
				: {}
	}

	return (
		<>
			<ExpandIcon
				className={className}
				css="width: 32px;"
				onClick={() => {
					onExpand?.()
					setExpanded(state => !state)
				}}
				expanded={expanded}
			/>

			{expanded && (
				<Modal
					{...props}
					onRequestClose={event => {
						event.stopPropagation()
						setExpanded(false)
					}}
					style={{
						overlay: { ...ModalStyles.overlay, ...modalStyles.overlay },
						content: { ...ModalStyles.content, ...modalStyles.content },
					}}
					isOpen={true}>
					{children}
				</Modal>
			)}
		</>
	)
}

const ModalStyles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		zIndex: LITERACY_EVENT_Z_INDEX,
	},
	content: {
		border: 'none',
		backgroundColor: 'transparent',
		overflow: 'hidden',
		minWidth: '0px',
		maxWidth: '100%',
		minHeight: 'fit',
		padding: '0',
		margin: '8px',
	},
}
