import config from '../config'
import { ONE_SECOND } from '../constants'
import {
	getMissionServerHttpAddress,
	getMissionServerWebsocketAddress,
	isConnectedToMission,
} from '../utility/urls'

import axios from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'uuid/v4'

type PossibleAxiosError = Error & { response?: { status?: number } }

type UrlDeterminationStatus = {|
	data: ?{|
		missionServerUrl: string,
		websocketUrl: string,
		missionCode: string,
	|},
	error: ?PossibleAxiosError,
	isLoading: boolean,
	currentMissionCode: ?string,
	attempt: number,
	refetch: () => mixed,
|}

const STARTING_STATUS: UrlDeterminationStatus = {
	data: null,
	error: null,
	isLoading: false,
	refetch: () => {},
	attempt: 0,
	currentMissionCode: null,
}
let currentStatus: UrlDeterminationStatus = STARTING_STATUS

const MAX_RETRIES = 5
const MISSION_SERVER_URL_RETRY_TIME = 2.5 * ONE_SECOND // ms

/**
 * startFetchingUrlsForMissionCode - dispatches asynchronous tasks which will populate `currentStatus` with the mission server urls (http url and websocket url) for the mission with the given missionCode
 *
 * @param {string} missionCode - the code of the mission to get the urls for
 */
function startFetchingUrlsForMissionCode(missionCode: string) {
	if (currentStatus.currentMissionCode === missionCode) {
		return
	}

	const refetch = () => startFetchingUrlsForMissionCode(missionCode)
	if (!config.useMatchmaker) {
		setCurrentStatus({
			data: {
				missionServerUrl: config.missionServerUrl,
				websocketUrl: config.webSocketServerUrl,
				missionCode,
			},
			error: null,
			isLoading: false,
			refetch,
			attempt: 1,
			currentMissionCode: missionCode,
		})
		return
	}

	setCurrentStatus({
		data: null,
		error: null,
		isLoading: true,
		refetch,
		attempt: 1,
		currentMissionCode: missionCode,
	})

	let retryCount = 0

	const tryToFetch = () => {
		retryCount++
		const matchMakerConnectUrl = `${config.matchmakerUrl}/connect?missionCode=${missionCode}`
		axios
			.get(matchMakerConnectUrl, {
				withCredentials: true,
			})
			.then(res => {
				if (currentStatus.currentMissionCode !== missionCode) {
					return
				}
				const missionServerUrl = getMissionServerHttpAddress(res.data.missionServerUrl)

				setCurrentStatus({
					data: {
						missionServerUrl: missionServerUrl,
						websocketUrl: getMissionServerWebsocketAddress(res.data.missionServerUrl),
						missionCode,
					},
					error: null,
					isLoading: false,
					refetch,
					attempt: retryCount,
					currentMissionCode: missionCode,
				})
			})
			.catch(err => {
				if (currentStatus.currentMissionCode !== missionCode) {
					return
				}

				setCurrentStatus({
					data: null,
					error: err,
					isLoading: false,
					refetch,
					attempt: retryCount,
					currentMissionCode: missionCode,
				})

				if (retryCount === MAX_RETRIES) {
					if (isConnectedToMission()) {
						window.location.href = '/'
					}
					return
				}
				if (isConnectedToMission()) {
					setTimeout(tryToFetch, MISSION_SERVER_URL_RETRY_TIME)
				}
			})
	}
	tryToFetch()
}

/**
 * setCurrentStatus - set the current status of the mission server urls being fetched. Also updates all react components listening for status changes.
 *
 * @param {UrlDeterminationStatus} newStatus - the new status of the urls being fetched
 */
function setCurrentStatus(newStatus: UrlDeterminationStatus) {
	// need to cause update
	currentStatus = newStatus
	for (let listener of statusListeners.values()) {
		listener(newStatus)
	}
}

const statusListeners: Map<
	string, // listenerId
	(newStatus: UrlDeterminationStatus) => mixed
> = new Map()

/**
 * useMissionServerUrls - a hook used to get the urls of the currently running mission, or update the mission the client should connect to.
 *
 * @return [
 *   UrlDeterminationStatus, - the current status of the mission server urls
 *   (missionCode: string) => void, - a callback used to set the code of the mission to return urls for (Note: this is global, so calling this will cause all uses of `useMissionServerUrls` to start using the urls for the mission with given code)
 * ]
 */
export function useMissionServerUrls(): [UrlDeterminationStatus, (missionCode: string) => void] {
	const [status, setStatus] = useState(currentStatus)

	useEffect(() => {
		const listenerId = uuid()

		statusListeners.set(listenerId, setStatus)

		return () => {
			statusListeners.delete(listenerId)
		}
	}, [setStatus])

	return [status, startFetchingUrlsForMissionCode]
}

export const TEST_ONLY = {
	/**
	 * resetMissionServerUrlGlobals - reset all global variables used to determine the mission server urls to their default state values. Should only be called during tests
	 */
	resetMissionServerUrlGlobals: () => {
		statusListeners.clear()
		currentStatus = STARTING_STATUS
	},
	/**
	 * getMissionUrlStatus - get the current status of fetching the mission urls, use only during testing. Use `useMissionServerUrls` in prod.
	 *
	 * @return {UrlDeterminationStatus} - the current url status
	 */
	getMissionUrlStatus: (): UrlDeterminationStatus => {
		return currentStatus
	},
	getUrlsForMissionCode: startFetchingUrlsForMissionCode,
	setWebsocketStatus: setCurrentStatus,
}
