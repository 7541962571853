import React from 'react'
import styled from 'styled-components/macro'
import { INDIGO, EMPTY_SPACE, PRIMARY_GREEN, APP_WHITE } from '../../constants/styles'
import { ALPHABET_ARRAY } from '../../constants'
import MarkdownTextToSpeech from '../Markdown/MarkdownTextToSpeech'
import type { CulminatingMomentOption } from '@mission.io/mission-toolkit'
import { sendMessage } from '../../websockets/websocket'

type Props = {
	content: CulminatingMomentOption,
	index: number,
	isSelected: boolean,
}

export default function VoteOptionCard({ content, index, isSelected }: Props): React$Node {
	return (
		<CardWrapper
			onClick={() => {
				if (!isSelected) {
					sendMessage('CLASS_QUESTION_VOTE', { optionId: content._id })
				}
			}}>
			<IndexContainer className="font-game-headline">
				{ALPHABET_ARRAY[index % ALPHABET_ARRAY.length]}
			</IndexContainer>
			<OptionContainer $highlight={isSelected}>
				{content.imageUrl && <OptionImage src={content.imageUrl} />}
				<Column>
					<OptionText>
						<MarkdownTextToSpeech
							disabledComponents={['a', 'img']}
							className="prose-on-game-light-purple">
							{content.text}
						</MarkdownTextToSpeech>
					</OptionText>
				</Column>
			</OptionContainer>
		</CardWrapper>
	)
}

const OptionContainer = styled.div`
	background: ${({ $highlight }) => ($highlight ? PRIMARY_GREEN : INDIGO)};
	width: 100%;
	display: flex;
	height: 100%;
	padding: 15px;
`

const CardWrapper = styled.button`
	padding: 0;
	border: none;
	border-radius: 6px;
	overflow: hidden;
	display: flex;
	align-items: stretch;
	flex-direction: row;
	margin-bottom: 10px;
	width: 100%;
	color: ${APP_WHITE};
	font-size: 1.2rem;
	&:hover ${OptionContainer} {
		filter: brightness(110%);
	}
`
const Column = styled.div`
	height: 100%;
	flex: 1;
	padding: 0px 15px;
`

const IndexContainer = styled.div`
background: ${EMPTY_SPACE};
font: Open Sans
height: 100%;
width: fit-content;
padding: 0 15px;
display: flex;
flex-direction: column;
justify-content: center;
`

const OptionText = styled.div.attrs({ className: 'font-game-body' })`
	text-align: left;
	font-weight: normal;
	letter-spacing: 0;
`

const OptionImage = styled.img`
	object-fit: contain;
	margin: auto 0;
	width: auto
	height: 100%;
	width: 20%;
	min-width: 10vw;
`
