import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-rangeslider'
import { LEVER_BOX, TEXT_OFFSET } from './EngineSVGLayout'
import { range } from '../../utility/functions'
import styled from 'styled-components'

import { getCurrentStudentPower, getStartTime } from '../../store/selectors/jrPlusState/power'
import { sendMessage } from '../../websockets/websocket'

const LABEL_COUNT = 10

const LeverProgress = styled.div`
	position: absolute;
	left: ${props => props.x}px;
	top: ${props => props.y}px;
	width: ${props => props.width}px;
	height: ${props => props.height}px;
	z-index: 2;

	.slider {
		box-shadow: none;
		height: 100%;
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 0;

		.rangeslider__fill,
		.rangeslider__handle {
			position: absolute;
			border-width: 0;
		}

		.rangeslider__fill {
			width: 100%;
			background-color: rgb(121, 245, 225);
			bottom: 0;
		}

		.rangeslider__handle {
			background: radial-gradient(circle, rgba(75, 74, 74, 1) 0%, rgba(0, 0, 0, 1) 100%);
			width: 150%;
			height: 5%;
			left: -25%;

			border-radius: 2vh;
			border: 1vh outset gray;
			background-color: gray;
			cursor: pointer;
			box-shadow: 0 0 0.25vh rgb(121, 245, 225);
		}

		.rangeslider__labels {
			pointer-events: none;
		}

		.label {
			position: absolute;
		}
	}
`

const Label = styled.div`
	position: absolute;
	pointer-events: none;
	top: calc(${props => `${props.$y}px + ${props.$proportion} * calc(${props.$height}px - 1em)`});
	left: ${props => props.$x}px;
	margin-left: 10px;
	color: var(--color-primary-darkest);
`

export default function Lever({
	min,
	max,
	currentDimensions,
}: {
	min: number,
	max: number,
	currentDimensions: { x: number, y: number, width: number, height: number },
}): React$Node {
	const POWER_SCALE = max - min
	const labelXOffset =
		currentDimensions.x + currentDimensions.width * (LEVER_BOX.x + LEVER_BOX.width + TEXT_OFFSET)
	const labelYOffset = currentDimensions.y + currentDimensions.height * LEVER_BOX.y
	const FUEL_HEIGHT = currentDimensions.height * LEVER_BOX.height

	const serverStudentPower = useSelector(getCurrentStudentPower)

	// the lever position is considered the primary truth, serverStudentPower will lag behind
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setLeverPercentage(serverStudentPower), [useSelector(getStartTime)])

	const [leverPercentage, setLeverPercentage] = useState(serverStudentPower)

	const onChange = value => {
		setLeverPercentage(value / POWER_SCALE)
	}

	const onChangeComplete = () => {
		sendMessage('POWER_UPDATE_STUDENT_VALUE', leverPercentage)
	}

	return (
		<>
			<LeverProgress
				x={currentDimensions.x + currentDimensions.width * LEVER_BOX.x}
				y={currentDimensions.y + currentDimensions.height * LEVER_BOX.y}
				width={currentDimensions.width * LEVER_BOX.width}
				height={FUEL_HEIGHT}>
				<Slider
					min={min}
					max={max}
					value={min + leverPercentage * POWER_SCALE}
					orientation="vertical"
					onChange={value => onChange(value)}
					onChangeComplete={() => onChangeComplete()}
					tooltip={false}
					className="slider"
				/>
			</LeverProgress>
			{range(LABEL_COUNT + 1)
				.map(current => min + (current * POWER_SCALE) / LABEL_COUNT)
				.map(value => (
					<Label
						$x={labelXOffset}
						$y={labelYOffset}
						$proportion={1 - (value - min) / max}
						$height={FUEL_HEIGHT}
						key={value}>
						{value}
					</Label>
				))}
		</>
	)
}
