import { ONE_SECOND } from '../constants'
import React from 'react'

import { Component, useState } from 'react'
import useInterval from 'use-interval'
import RalfErrorIllustration from '../images/RALFErrorIllustration.png'
import { sendMetrics } from '../websockets/websocket'

const RELOAD_TIME_SECONDS = 8

// this components acts as a transient error boundary (ie. the error will continue propagating to the parent components) that will try to report the crash via a websocket
export default class ReportCrashViaWebsocket extends Component<
	{| children: React$Node |},
	{ error: ?Error }
> {
	constructor() {
		super()
		this.state = { error: null }
	}

	componentDidCatch(error: Error) {
		this.setState({ error: error })
		sendMetrics([
			{
				metric: 'clientCrashes',
				value: 1,
			},
		])
		throw error // rethrow error so it is caught by the sentry fallback
	}

	render(): React$Node {
		if (this.state.error) {
			// react may try to rerender this component after it has caught and thrown an error, show fallback in that situation
			return <FallbackComponent />
		}

		return this.props.children
	}
}

/**
 * This is a fallback component for when the app crashes. Automatically refreshes the page after RELOAD_TIME_SECONDS seconds
 */
export function FallbackComponent(): React$Node {
	const [timeTilReload, setTimeTilReload] = useState(RELOAD_TIME_SECONDS)

	useInterval(() => {
		if (timeTilReload <= 0) {
			window.location.reload()
			return
		}
		setTimeTilReload(t => t - 1)
	}, ONE_SECOND)

	return (
		<div className="text-center w-full min-h-screen max-h-screen flex flex-col justify-center items-center gap-2 p-20 text-white bg-gradient-to-r from-[#0d3a58] to-[#3d357d]">
			<img src={RalfErrorIllustration} className="h-[50vh] w-auto" alt="Confused RALF" />
			<h1>RALF&apos;s batteries ran out!</h1>
			<span>You will be reconnected in {timeTilReload}...</span>
		</div>
	)
}
