import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import type {
	CollaborativeCulminatingMomentDocumentData,
	CollaborativeCulminatingMomentDocumentScores,
} from '../../store/selectors/sharedSelectors'
import { FaCheck, FaCircle } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'
import styled from 'styled-components'
import {
	didAnalyticsDocumentPass,
	type CreativeCanvasGradingOption,
	type CreativeCanvasStationCriterion,
} from '@mission.io/mission-toolkit'
import { AttemptFlipper, SimpleQuestionWrapper, StyledQuestionGrid } from './sharedComponents'
import { Loading } from '../basics'
import { getMissionCode } from '../../store/stores/staticData'
import { useMissionServerUrls } from '../../websockets/urls'

/**
 * CollaborativeCulminatingMomentReview - shows all the documents that the student has interacted with throughout the mission
 *
 * @param {Object} props - the react props
 * @param {?Array<CollaborativeCulminatingMomentDocumentData>} props.culminatingMomentData - the collaborative culminating moment data for the student
 *
 * @return {React$Node}
 */
export function CollaborativeCulminatingMomentReview({
	culminatingMomentData,
}: {
	culminatingMomentData: ?Array<CollaborativeCulminatingMomentDocumentData>,
}): React$Node {
	if (!culminatingMomentData?.length) {
		return null
	}

	return (
		<StyledQuestionGrid>
			{culminatingMomentData.map(data => (
				<DocumentFlipper data={data} key={data.documentId} />
			))}
		</StyledQuestionGrid>
	)
}

/**
 * DocumentFlipper - A component for showing the attempts a student had on a document
 *
 * @param {Object} props - the react props
 * @param {CollaborativeCulminatingMomentDocumentData} props.data - the collaborative culminating moment data for a single document
 *
 * @return {React$Node}
 */
function DocumentFlipper({
	data,
}: {
	data: CollaborativeCulminatingMomentDocumentData,
}): React$Node {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const selectedScoreData: ?CollaborativeCulminatingMomentDocumentScores =
		data.collaborativeCulminatingMomentData[selectedIndex]
	const documentPassed = selectedScoreData
		? didAnalyticsDocumentPass(selectedScoreData.scores, selectedScoreData.issue)
		: false

	return (
		<Column>
			<Prompt>{selectedScoreData?.issue.prompt ?? ''}</Prompt>
			<Row>
				<CanvasWrapper>
					<CanvasPositioner className={documentPassed ? 'success' : 'failure'}>
						<CanvasImage documentId={data.documentId} />
					</CanvasPositioner>
					<CanvasPassIndicatorPositioner className="canvas-pass-indicator">
						{documentPassed ? (
							<FaCheck className="icon radio-button success" />
						) : (
							<FaX className="icon radio-button failure" />
						)}
					</CanvasPassIndicatorPositioner>
				</CanvasWrapper>
				<CriteriaList>
					{selectedScoreData ? <CriteriaScoreViewer scoreData={selectedScoreData} /> : null}
				</CriteriaList>
			</Row>
			<AttemptFlipper
				currentIndex={selectedIndex}
				length={data.collaborativeCulminatingMomentData.length}
				setCurrentIndex={setSelectedIndex}
			/>
		</Column>
	)
}

/**
 * CriteriaScoreViewer - a component which shows the criteria and how a student was scored on the criteria
 *
 * @param {Object} props - the react props
 * @param {CollaborativeCulminatingMomentDocumentScores} props.scoreData - the scores the student got
 *
 * @return {React$Node}
 */
function CriteriaScoreViewer({
	scoreData,
}: {
	scoreData: CollaborativeCulminatingMomentDocumentScores,
}): React$Node {
	return (
		<Items>
			{scoreData.issue.rubric.criteria.map(criteria => {
				const selectedOption = criteria.gradingOptions.find(
					option => option.id === scoreData.scores.criteriaId[criteria.id]?.selectedOption
				)
				const shouldShowOptions = !isDefaultYesNoCriteria(criteria)
				return (
					<Line
						key={criteria.id}
						className={
							selectedOption?.correct == null
								? 'unanswered'
								: selectedOption.correct
								? 'success'
								: 'failure'
						}>
						{selectedOption?.correct == null ? (
							<FaCircle className="icon radio-button unanswered" />
						) : selectedOption.correct ? (
							<FaCheck className="icon radio-button success" />
						) : (
							<FaX className="icon radio-button failure" />
						)}
						<CriteriaText>
							{criteria.text}
							{criteria.required ? <b> (Required)</b> : null}
						</CriteriaText>
						{shouldShowOptions ? (
							<Options selectedOption={selectedOption} allOptions={criteria.gradingOptions} />
						) : null}
					</Line>
				)
			})}
		</Items>
	)
}

/**
 * Options - A react component which shows the selected grading option as well as shows all the grading options when hovered
 *
 * @param {Object} props - the react props
 * @param {?CreativeCanvasGradingOption} props.selectedOption - the selected grading option
 * @param {CreativeCanvasGradingOption[]} props.allOptions - all the possible grading options
 *
 * @return {React$Node}
 */
function Options({
	selectedOption,
	allOptions,
}: {
	selectedOption: ?CreativeCanvasGradingOption,
	allOptions: CreativeCanvasGradingOption[],
}): React$Node {
	const [areAllOptionsVisible, setAreAllOptionsVisible] = useState(false)

	return (
		<Option
			onMouseEnter={() => setAreAllOptionsVisible(true)}
			onMouseLeave={() => setAreAllOptionsVisible(false)}
			className={selectedOption == null ? '' : selectedOption.correct ? 'correct' : 'incorrect'}>
			{selectedOption?.text ?? 'Not Graded'}{' '}
			{areAllOptionsVisible ? (
				<AllOptionPositioner>
					{allOptions
						.sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA)
						.map(option => (
							<AllOptionsOption
								key={option.id}
								className={option.correct ? 'correct' : 'incorrect'}>
								{option.text}
							</AllOptionsOption>
						))}
				</AllOptionPositioner>
			) : null}
		</Option>
	)
}

/**
 * Displays the canvas as an svg image. If the canvas is not available, it will display an error message
 * @param {string} props.documentId the id of the document to display
 * @returns {React$Node}
 */
function CanvasImage({ documentId }: { documentId: string }): React$Node {
	const missionServerUrl = useMissionServerUrls()[0].data?.missionServerUrl
	const missionCode = useSelector(getMissionCode)
	const src =
		missionCode && missionServerUrl
			? `${missionServerUrl}/api/creative-canvas-image/${documentId}?code=${missionCode}`
			: null
	const [status, setStatus] = useState('loading')

	return (
		<div className="image-container">
			{status === 'loading' && <Loading className="size-1/5 status loading" />}
			{src && status !== 'error' ? (
				<img
					className="canvas-image"
					src={src}
					alt={`Canvas design for issue`}
					onError={() => setStatus('error')}
					onLoad={() => {
						setStatus('complete')
					}}
				/>
			) : (
				<div className="status error">Unable to display image</div>
			)}
		</div>
	)
}

/**
 * isDefaultYesNoCriteria - check if the given criteria only has the default "yes" and "no" grading options
 *
 * @param {CreativeCanvasStationCriterion} criteria - the criteria to check
 *
 * @return {boolean}
 */
function isDefaultYesNoCriteria(criteria: CreativeCanvasStationCriterion): boolean {
	if (criteria.gradingOptions.length !== 2) {
		return false
	}
	return (
		criteria.gradingOptions.some(option => option.text.toLowerCase() === 'yes' && option.correct) &&
		criteria.gradingOptions.some(option => option.text.toLowerCase() === 'no' && !option.correct)
	)
}

const Prompt = styled.div`
	font-weight: bold;
	font-size: var(--font-size-m);
`

const CriteriaText = styled.div`
	flex: 1 1 0;
`

const Option = styled.div`
	border: 2px solid black;
	border-radius: var(--border-radius);
	&.correct {
		border-color: ${({ theme }) => theme.success};
	}
	&.incorrect {
		border-color: ${({ theme }) => theme.error};
	}
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 var(--spacing);
	position: relative;

	&:hover {
		cursor: pointer;
		background-color: #fff3;
	}
`

const AllOptionPositioner = styled.div`
	position: absolute;

	top: -2.5em;
	flex-wrap: none;
	text-wrap: none;
	display: flex;
	flex-direction: row;
	background-color: #364984cc;
	border-radius: var(--border-radius);
	border: 2px solid #fffa;
	padding: var(--spacing-half) var(--spacing);

	white-space: nowrap;
	gap: var(--spacing);

	pointer-events: none;
`

const AllOptionsOption = styled.div`
	border: 2px solid black;
	border-radius: var(--border-radius);
	&.correct {
		border-color: ${({ theme }) => theme.success};
		background-color: ${({ theme }) => theme.success}AA;
	}
	&.incorrect {
		border-color: ${({ theme }) => theme.error};
		background-color: ${({ theme }) => theme.error}AA;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 var(--spacing);
	white-space: no-wrap;
`

const Items = styled.div`
	padding-top: var(--spacing);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-half);
`

const Column = styled(SimpleQuestionWrapper)`
	gap: var(--spacing);
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing);

	.icon.success {
		background-color: ${({ theme }) => theme.success};
		color: ${({ theme }) => theme.neutralLightest};
		padding: ${({ theme }) => theme.spacingHalf};
	}
	.icon.failure {
		background-color: ${({ theme }) => theme.error};
		color: ${({ theme }) => theme.neutralLightest};
		padding: ${({ theme }) => theme.spacingHalf};
	}
	.icon.unanswered {
		background-color: ${({ theme }) => theme.neutral};
		color: ${({ theme }) => theme.neutral};
		padding: ${({ theme }) => theme.spacingHalf};
	}
	.radio-button {
		border-radius: 50%;
		background-color: ${({ theme }) => theme.success};
		color: ${({ theme }) => theme.neutralLightest};
		padding: ${({ theme }) => theme.spacingHalf};
	}
`

const Line = styled.div`
	&.success {
		background-color: ${({ theme }) => theme.success}AA;
	}
	&.failure {
		background-color: ${({ theme }) => theme.error}AA;
	}
	&.unanswered {
		background-color: ${({ theme }) => theme.neutral}AA;
	}
	border-radius: var(--spacing);
	text-align: left;
	padding: var(--spacing-half) var(--spacing);
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: var(--spacing);
`

const CanvasWrapper = styled.div`
	flex: 1 1 0;
	position: relative;

	&:hover {
		.canvas-pass-indicator {
			opacity: 0;
		}
	}
`

const CriteriaList = styled.div`
	flex: 1 1 0;
	position: relative;
`

const CanvasPassIndicatorPositioner = styled.div`
	position: absolute;
	bottom: var(--spacing2x);
	right: var(--spacing3x);
	z-index: 1;
	font-size: 2em;
	opacity: 0.5;

	transition: opacity 0.5s;
`

const CanvasPositioner = styled.div`
	border-radius: 24px;
	padding: var(--spacing);

	&.success {
		background-color: ${({ theme }) => theme.success}AA;
	}
	&.failure {
		background-color: ${({ theme }) => theme.error}AA;
	}
	.image-container {
		position: relative;
		height: 30vh;
		width: auto;
		border-radius: 16px;
		overflow: hidden;
		background-color: white;
		.status {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&.error {
				color: ${({ theme }) => theme.error};
			}
			&.loading {
				svg {
					fill: ${({ theme }) => theme.neutral};
				}
			}
		}
		.canvas-image {
			max-height: 100%;
		}
	}
`
