import { combineReducers, type CombinedReducer } from 'redux'
import type {
	JuniorMissionFullState,
	JuniorPlusMissionFullState,
} from '@mission.io/mission-toolkit'

import * as stores from './stores'
import jrStateReducer from './stores/jrState'
import jrPlusStateReducer from './stores/jrPlusState'
import analyticsReducer from './stores/analytics'

import type { GeneralStore } from './stores/general'
import type { PointsStore } from './stores/points'
import type { StaticDataStore } from './stores/staticData'
import type { ThrusterStore } from './stores/thrusters'
import type { TransporterStore } from './stores/transporter'
import type { SettingsStore } from './stores/settings'
import type { AnalyticsStore } from './stores/analytics'

import type { Action } from './actionTypes'

export type ReduxStore = {|
	general: GeneralStore,
	jrPlusState: JuniorPlusMissionFullState,
	jrState: JuniorMissionFullState,
	analytics: AnalyticsStore,
	points: PointsStore,
	staticData: StaticDataStore,
	thrusters: ThrusterStore,
	transporter: TransporterStore,
	settings: SettingsStore,
|}

export default (combineReducers({
	general: stores.generalReducer,
	jrPlusState: jrPlusStateReducer,
	jrState: jrStateReducer,
	points: stores.pointReducer,
	staticData: stores.staticDataReducer,
	thrusters: stores.thrustersReducer,
	transporter: stores.transporterReducer,
	settings: stores.settingsReducer,
	analytics: analyticsReducer,
}): CombinedReducer<*, Action>)
