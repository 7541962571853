// @flow
import React, { useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components/macro'
import { HORIZONTAL_DRAW_START } from '../InformationFrame/DecalInformation'
import { BaseSVG, type SvgProps } from '../BaseSVG'
import { LENGTHS, IDS, MAX_MULTIPLE_CHOICE_OPTIONS } from './constants'
import { AnimationWrapper } from './AnimationWrapper'
import { useOnClickOutside } from '../../../../../utility/hooks'
import classnames from 'classnames'
type Props = {
	style?: {},
	orientation: 'VERTICAL' | 'HORIZONTAL',
	optionsTitle: string,
	optionsJsx: Array<React$Node>,
	onFinishExit: () => void,
	onClickOption?: (index: ?number) => void,
	isExiting: boolean,
}

const TITLE_MEASURE_ID = 'invisible-title-to-measure'

export const Title: React$AbstractComponent<
	{ text: string, id: string, className?: string },
	HTMLDivElement
> = React.forwardRef(
	({ text, ...props }: { text: string, id: string, className?: string }, ref) => {
		return (
			<div
				{...props}
				ref={ref}
				className="border-2 border-accent-aqua bg-black/50 p-2"
				css={`
					border-radius: ${LENGTHS.pathBorderRadius}px;
					margin-top: -20px;
					z-index: 1;
					max-width: 50vw;
				`}>
				<h4 css="padding: 0; margin: 0;">{text}</h4>
			</div>
		)
	}
)
/**
 * A wrapper around the InternalOptionsFrame that will calculate the length the svg path needs to be
 * @param {*} props
 * @returns
 */
export function OptionsFrame(props: Props): React$Node {
	const containerRef = React.useRef()
	const [horizontalPathLength, setHorizontalPathLength] = React.useState()
	let optionsJsx = props.optionsJsx
	if (optionsJsx.length > MAX_MULTIPLE_CHOICE_OPTIONS) {
		optionsJsx = optionsJsx.slice(0, MAX_MULTIPLE_CHOICE_OPTIONS)
	}
	const measureTitleRef = React.useRef<?HTMLDivElement>()
	// Function that will figure out how long the svg path needs to be based on container size, total options, and option title length.
	const setPathLength = useCallback(() => {
		if (!measureTitleRef.current) {
			return
		}
		const titleSVGLength = measureTitleRef.current.offsetWidth * LENGTHS.domToSVGRatio
		const length = containerRef.current?.offsetWidth
		if (!length) {
			return
		}
		const lengthToFill =
			Math.floor(optionsJsx.length === 1 ? length * 0.5 : length * 0.75) * LENGTHS.domToSVGRatio
		const spaceAlreadyTakenUp =
			HORIZONTAL_DRAW_START * LENGTHS.domToSVGRatio +
			titleSVGLength +
			LENGTHS.preTitleSpace +
			LENGTHS.halfSvgHeight
		const actual = Math.max(lengthToFill - spaceAlreadyTakenUp, 20)
		setHorizontalPathLength(actual)
	}, [optionsJsx.length])

	useLayoutEffect(() => {
		window.addEventListener('resize', setPathLength)
		setPathLength()
		return () => {
			window.removeEventListener('resize', setPathLength)
		}
	}, [setPathLength])

	return (
		<AbsoluteDiv ref={containerRef} $totalOptions={optionsJsx.length}>
			<Title
				ref={measureTitleRef}
				text={props.optionsTitle}
				id={TITLE_MEASURE_ID}
				style={{ position: 'fixed', top: '-40', left: 0, opacity: 0 }}
			/>
			{horizontalPathLength && measureTitleRef.current && (
				<InternalOptionsFrame
					{...props}
					horizontalPathLength={horizontalPathLength}
					titleSize={{
						x: measureTitleRef.current.offsetWidth * LENGTHS.domToSVGRatio,
						y: (measureTitleRef.current.offsetHeight - 20) * LENGTHS.domToSVGRatio,
					}}
				/>
			)}
		</AbsoluteDiv>
	)
}

/**
 * Displays the options frame. This is the frame that contains the options and the action title
 * for the literacy event.
 * @param {{}} props.style
 * @param {'VERTICAL' | 'HORIZONTAL'} props.orientation
 * @param {string} props.optionsTitle the title. This is the action title for the literacy event.
 * @param {Array<React$Node>} props.optionsJsx the options to display.
 * @returns {React$Node}
 */
function InternalOptionsFrame({
	style,
	orientation,
	optionsJsx,
	optionsTitle,
	horizontalPathLength,
	onFinishExit,
	isExiting,
	onClickOption,
	titleSize,
}): React$Node {
	const titleSpace = titleSize.x + LENGTHS.preTitleSpace

	// $FlowFixMe[incompatible-type] the optionsJsx length should only ever have a max length of 4.
	const totalLeafPaths: 0 | 1 | 2 | 3 = optionsJsx.length > 1 ? optionsJsx.length - 1 : 0
	const totalPathLength = horizontalPathLength + titleSpace
	const positionsOnPath = React.useMemo(() => {
		return totalLeafPaths === 1
			? [totalPathLength * 0.5 - titleSpace]
			: totalLeafPaths === 2
			? [totalPathLength * 0.33 - titleSpace, totalPathLength * 0.66 - titleSpace]
			: totalLeafPaths === 3
			? [
					totalPathLength * 0.25 - titleSpace,
					totalPathLength * 0.5 - titleSpace,
					totalPathLength * 0.75 - titleSpace,
			  ]
			: null
	}, [totalLeafPaths, totalPathLength, titleSpace])
	const optionWidth =
		(positionsOnPath && positionsOnPath.length > 1
			? positionsOnPath[positionsOnPath.length - 1] - positionsOnPath[positionsOnPath.length - 2]
			: positionsOnPath && positionsOnPath.length === 1
			? totalPathLength / 2
			: totalPathLength) - 16

	// When we click outside of the option frame, we need the parent container to know so an option can be de-selected.
	const ref = useOnClickOutside(() => {
		onClickOption?.(null)
	})

	return (
		<AnimationWrapper
			reverse={isExiting}
			totalLeafPaths={totalLeafPaths}
			positionsOnPath={positionsOnPath}
			onAnimationEnd={isExiting ? onFinishExit : undefined}
			titleHeight={titleSize.y}
			horizontalPathLength={horizontalPathLength}>
			<NodeMarker id={IDS['animate-1-marker']} />
			<BaseSVG style={{ position: 'absolute' }}>
				<path
					id={IDS['animate-2-options-path']}
					className="stroke-accent-aqua"
					fill="transparent"
					strokeWidth="2"
					d={`M${LENGTHS.halfSvgHeight} ${LENGTHS.largeNodeMarkerRadius} v${LENGTHS.handleLength} a${LENGTHS.pathBorderRadius},${LENGTHS.pathBorderRadius} 1 0 0 ${LENGTHS.pathBorderRadius},${LENGTHS.pathBorderRadius} h${LENGTHS.preTitleSpace}`}
				/>
			</BaseSVG>
			<div
				css={`
					position: absolute;
					top: ${(LENGTHS.handleLength + LENGTHS.pathBorderRadius + LENGTHS.largeNodeMarkerRadius) *
						(LENGTHS.svgDomHeight / LENGTHS.svgHeight)}px;
					left: ${LENGTHS.pathBorderRadius + LENGTHS.preTitleSpace + LENGTHS.svgDomHeight}px;
					display: flex;
					align-items: flex-start;
					height: 0px;
				`}>
				<Title text={optionsTitle} id={IDS['animate-3-options-title']} />

				<div css="position: relative;">
					<BaseSVG>
						<g>
							{positionsOnPath &&
								positionsOnPath.map((leafPath, index) => {
									const start = positionsOnPath[index]

									const path =
										start > 0
											? `M${start} 0 a${LENGTHS.pathBorderRadius},${
													LENGTHS.pathBorderRadius
											  } 0 0 1 ${LENGTHS.pathBorderRadius},${
													LENGTHS.pathBorderRadius
											  } v${titleSize.y + LENGTHS.handleLength - LENGTHS.pathBorderRadius}`
											: `M${start} ${titleSize.y}  v${LENGTHS.handleLength}`

									return (
										<g key={`option-${index}`}>
											<path
												id={IDS[`animate-4-${index + 1}-leaf-from-main-path`]}
												className="stroke-accent-aqua"
												fill="transparent"
												strokeWidth={2}
												d={path}
											/>
										</g>
									)
								})}
						</g>
						<path
							id={IDS['animate-4-path-out-from-title']}
							className="stroke-accent-aqua"
							fill="transparent"
							strokeWidth="2"
							d={`M0 0 h${horizontalPathLength} a${LENGTHS.pathBorderRadius},${
								LENGTHS.pathBorderRadius
							} 0 0 1 ${LENGTHS.pathBorderRadius},${
								LENGTHS.pathBorderRadius
							} v${LENGTHS.handleLength - LENGTHS.pathBorderRadius + titleSize.y}`}
						/>
					</BaseSVG>

					<div ref={ref}>
						{positionsOnPath &&
							positionsOnPath.map((leafPath, index) => {
								const start = positionsOnPath[index]

								return (
									<SingleOptionWrap
										key={index}
										onClick={onClickOption ? () => onClickOption(index) : undefined}
										index={index}
										id={IDS[`animate-${4}-${index + 1}-option-container`]}
										markerId={IDS[`animate-${4}-${index + 1}-marker`]}
										width={optionWidth}
										y={titleSize.y}
										x={start > 0 ? start + LENGTHS.pathBorderRadius : start}>
										{optionsJsx[index]}
									</SingleOptionWrap>
								)
							})}
						<SingleOptionWrap
							maximizeSize={optionsJsx.length === 1}
							onClick={onClickOption ? () => onClickOption(optionsJsx.length - 1) : undefined}
							index={optionsJsx.length - 1}
							width={optionWidth}
							x={horizontalPathLength + LENGTHS.pathBorderRadius}
							y={titleSize.y}
							markerId={IDS[`animate-4-marker`]}
							id={IDS[`animate-4-option-container`]}>
							{optionsJsx[optionsJsx.length - 1]}
						</SingleOptionWrap>
					</div>
				</div>
			</div>
		</AnimationWrapper>
	)
}

/**
 * Design wrapper for a single option to a literacy event action.
 * @param {number} props.width the width of the option
 * @param {number} props.x the x position of the option
 * @param {React$Node} props.children the jsx to display for the option
 * @returns {React$Node}
 */
export function SingleOptionWrap({
	id,
	width,
	markerId,
	x: _x,
	y: _y,
	children,
	onClick,
	className,
	wrapperRef,
	maximizeSize,
}: {
	id: string,
	markerId: string,
	children: React$Node,
	width?: number,
	className?: string,
	maximizeSize?: boolean,
	x?: number,
	y?: number,
	onClick?: void | (() => void),
	wrapperRef?: ?(ref: ?HTMLDivElement) => void,
}): React$Node {
	const y =
		_y !== undefined
			? (_y + LENGTHS.handleLength + LENGTHS.smallNodeMarkerRadius) * LENGTHS.svgToDomRatio
			: undefined
	const x = _x !== undefined ? _x * LENGTHS.svgToDomRatio : undefined

	return (
		<div
			onClick={onClick}
			className={className}
			ref={wrapperRef}
			css={`${
				x !== undefined && y !== undefined && width !== undefined
					? `
				position: absolute;
				top: ${y}px;
				left: ${x}px;
				width: ${maximizeSize ? 'auto' : width * LENGTHS.svgToDomRatio}px;
				height: ${maximizeSize ? 'auto' : LENGTHS.optionContainerHeight * LENGTHS.svgToDomRatio}px;
				transform: translateX(-50%);
			`
					: `position: relative; top: ${LENGTHS.smallNodeMarkerRadius *
							LENGTHS.svgToDomRatio}px; max-width: 50%;`
			}
				cursor: ${onClick ? 'pointer' : 'default'};
			`}>
			<NodeMarker
				style={{
					position: 'absolute',
					top: '0',
					zIndex: 1,
					left: '50%',
					pointerEvents: 'none',
					transform: 'translateX(-50%)',
				}}
				small
				id={markerId}
			/>

			<div
				id={id}
				style={{
					borderRadius: `${LENGTHS.pathBorderRadius * LENGTHS.svgToDomRatio}px`,
					transformOrigin: 'center 0',
				}}
				className="option-flex-height bg-primary-600 font-game-body p-2 pt-4 w-full flex">
				<div
					className={classnames(
						!maximizeSize ? 'max-w-full' : '',
						'bg-primary-200 pt-3 px-1.5 pb-1.5 flex-grow max-w-full text-left'
					)}
					style={{ borderRadius: `${LENGTHS.pathBorderRadius * LENGTHS.svgToDomRatio}px` }}>
					<div
						className={classnames(
							`h-full ${IDS['animate-5-display-content']}`,
							maximizeSize ? 'w-max max-w-[90vw]' : ''
						)}>
						{children}
					</div>
				</div>
				<BaseSVG
					css={`
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						pointer-events: none;
					`}>
					<clipPath id="cut-off-circle-svg-option-decal">
						<rect x="0" y="0" width={LENGTHS.svgHeight} height="18" />
					</clipPath>

					<circle
						cx={LENGTHS.halfSvgHeight}
						cy={0}
						r={18}
						fill="var(--color-primary-dark)"
						clipPath="url(#cut-off-circle-svg-option-decal)"
					/>
					<circle
						cx={LENGTHS.halfSvgHeight}
						cy={0}
						r={LENGTHS.largeNodeMarkerRadius}
						fill="#020223;"
						clipPath="url(#cut-off-circle-svg-option-decal)"
					/>
				</BaseSVG>
			</div>
		</div>
	)
}

/**
 * An svg circle marker that is animated and will grow from its center as it appears.
 */
const NodeMarker = ({
	children,
	small = false,
	id,
	...props
}: {|
	...SvgProps,
	small?: boolean,
	id: string,
|}) => {
	return (
		<BaseSVG {...props}>
			<g style={{ transformOrigin: 'center', transformBox: 'fill-box' }} id={id}>
				<circle
					cx={LENGTHS.halfSvgHeight}
					cy="0"
					r={small ? LENGTHS.smallNodeMarkerRadius : LENGTHS.largeNodeMarkerRadius}
					className="stroke-accent-aqua stroke-2 fill-transparent"
				/>
				<circle
					cx={LENGTHS.halfSvgHeight}
					cy="0"
					r={small ? LENGTHS.smallNodeMarkerRadius / 2 : LENGTHS.largeNodeMarkerRadius / 2}
					className="fill-accent-aqua"
				/>
			</g>
		</BaseSVG>
	)
}

const AbsoluteDiv = styled.div`
	padding-left: ${HORIZONTAL_DRAW_START}px;
	width: 100%;
	z-index: 1;
	position: absolute;
	overflow: visible;
	bottom: -${LENGTHS.svgDomHeight}px;
	left: 0;
	display: flex;
	.option-flex-height {
		${({ $totalOptions }) => ($totalOptions === 1 ? 'max-height: 100%;' : 'height: 100%;')}
	}
`
