import React, { useState } from 'react'
import type { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { isTraining } from '../../../../../store/stores/general'
import styled, { css, keyframes } from 'styled-components'
import { SoundIcon, LanguageIcon } from '../../../../../images/remote'
import { CLASS_QUESTION_INDEX, LITERACY_EVENT_Z_INDEX } from '../../../../../constants/styles'
import { BACKGROUND_PANEL } from './SharedColors'
import RemoteSettings from '../../../../RemoteSettings'
import TextToSpeechSettings from '../../../../TextToSpeechSettings'
import { sendMessage } from '../../../../../websockets/websocket'

const GrowShrink = keyframes`
	0% {
		transform:scale(1);
		fill:#CCCCCC;
	}
	50% {
		transform:scale(1.25);
		fill: #ffffff;
	}
	100% {
		transform:scale(1);
		fill: #CCCCCC;
	}
`
const ICON_SIZE = '60px'
const Icon = styled.div`
	max-width: ${ICON_SIZE};
	max-height: ${ICON_SIZE};
	width: ${ICON_SIZE};
	height: ${ICON_SIZE};
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	background-color: ${BACKGROUND_PANEL};
	cursor: pointer;
	pointer-events: all;
	svg {
		padding: 8px;
		height: 100%;
		width: 100%;
		${({ $blink }) =>
			$blink &&
			css`
				animation: ${GrowShrink} 1.5s ease-in-out infinite;
			`}
	}
	z-index: ${CLASS_QUESTION_INDEX + 2};
`

const StyledToolkit = styled.div`
	position: absolute;
	overflow-x: hidden;
	top: 0;
	left: 0;
	height: 100%;
	z-index: ${LITERACY_EVENT_Z_INDEX};
	display: flex;
	${({ open }) => `
		transform: translateX(${open ? 0 : `calc(-100% + ${ICON_SIZE})`});
		${!open ? 'pointer-events: none;' : ''};
	`}
	transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.2s;

	opacity: ${({ open }) => (open ? 1 : 0.5)};
	&:hover {
		opacity: 1;
	}

	${Icon} {
		&:first-child {
			margin-top: 4em;
		}
		&:not(:first-child) {
			margin-top: 8px;
		}
	}
`

export const toolkitTabTypes = {
	REMOTE_SETTINGS: 'REMOTE_SETTINGS',
	TEXT_TO_SPEECH_SETTINGS: 'TEXT_TO_SPEECH_SETTINGS',
}

type ToolkitTabKey = $Keys<typeof toolkitTabTypes>

const tabToIcon: { [tabName: ToolkitTabKey]: ComponentType<{ color: string }> } = {
	REMOTE_SETTINGS: SoundIcon,
	TEXT_TO_SPEECH_SETTINGS: LanguageIcon,
}

const tabToContent: { [tabName: ToolkitTabKey]: () => React$Node } = {
	REMOTE_SETTINGS: RemoteSettings,
	TEXT_TO_SPEECH_SETTINGS: TextToSpeechSettings,
}

const tabToTitle: { [tabName: ToolkitTabKey]: string } = {
	// ENGINEERING: 'Engineering Panel',
	REMOTE_SETTINGS: 'Audio Settings',
	TEXT_TO_SPEECH_SETTINGS: 'Text To Speech Settings',
}

/**
 * Takes ordered array of tabs as a parameter and
 * generates an expandable tab list along the left side of the screen
 */
export default function Toolkit({ tabs }: { tabs: ToolkitTabKey[] }): React$Node {
	const isTrainingMode = useSelector(isTraining)
	const [selectedPanel, setSelectedPanel] = useState(null)
	const [panelOpen, setPanelOpen] = useState(false)

	const TabContent = (selectedPanel && tabToContent[selectedPanel]) || tabToContent[tabs[0]]
	if (tabs.length > 0) {
		return (
			<StyledToolkit open={panelOpen}>
				<TabContent />
				<div>
					{tabs.map(tab => {
						const TabIcon: ComponentType<{ color: string }> = tabToIcon[tab]
						return (
							<Icon
								key={tab}
								className="toolkitIcon"
								onClick={() => {
									if (panelOpen) {
										selectedPanel === tab ? setPanelOpen(false) : setSelectedPanel(tab)
									} else {
										setPanelOpen(true)
										setSelectedPanel(tab)
									}
									if (isTrainingMode) {
										sendMessage('ENG_PANEL_CLICK')
									}
								}}
								$blink={selectedPanel === tab && panelOpen ? 'true' : undefined}
								title={tabToTitle[tab]}>
								<TabIcon color="white" />
							</Icon>
						)
					})}
				</div>
			</StyledToolkit>
		)
	}
	return null
}
