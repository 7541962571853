import React from 'react'
import { ROBOTS } from './constants'

export default function WorkingRobot(props: { id: string, systemId: string }): React$Node {
	const robot = ROBOTS[props.id]
	return (
		<div
			className="absolute top-0 left-0"
			style={{
				height: robot.dimensions[1],
				width: robot.dimensions[0],
			}}>
			<div
				style={{
					backgroundImage: `url(${robot.dragSrc})`,
					width: robot.dimensions[0],
					height: robot.dimensions[1],
				}}
				alt="Robot"
				className="animate-robot-working absolute top-0 left-0 bg-no-repeat bg-contain bg-center"
			/>
			<div className="w-full h-2 absolute bottom-[-20px] border-2 border-white rounded-[2px] outset-border">
				<div className="bg-error h-[99%] rounded-[2px] animate-working-progress" />
			</div>
		</div>
	)
}
