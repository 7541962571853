import React, { Component, memo, type AbstractComponent } from 'react'

type Props = {
	src: string,
	percentage: number,
}

class PartialImage extends Component<Props> {
	render() {
		return (
			<div
				className="relative transition duration-[5s] flex-1 size-full"
				style={this.getContainerStyle()}
			/>
		)
	}

	getContainerStyle() {
		return {
			background: `url(${this.props.src}) no-repeat center center`,
			backgroundSize: 'contain',
			opacity: this.props.percentage / 100,
		}
	}
}
export default (memo(PartialImage): AbstractComponent<Props>)
