// @flow
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isRepairsActive } from '../store/selectors/jrState'
import { isRepairsActive as isJrPlusRepairsActive } from '../store/selectors/jrPlusState/decks'
import type { TractorBeamStatus, DefenseStatus } from '../types'
import { isCommunicationActive, isPowerActive, isScannerActive } from '../store/selectors/jrState'
import { isThrustersActive } from '../store/selectors/sharedSelectors'
import { isPowerActive as isPlusPowerActive } from '../store/selectors/jrPlusState/power'
import { isCommunicationActive as isPlusCommunicationActive } from '../store/selectors/jrPlusState/communication'
import { getMissionType, isPaused, JUNIOR_PLUS } from '../store/stores/general'
import { setIsDebugging, getIsDebugging } from '../store/stores/settings'
import { sendMessage } from '../websockets/websocket'

export default function HackerActions(): React$Node {
	const dispatch = useDispatch()

	const isMissionPaused = useSelector(isPaused)
	const isDebugging = useSelector(getIsDebugging)
	const missionType = useSelector(getMissionType)
	const thrusterActiveStatus = useSelector(isThrustersActive)
	const communicationActiveStatus = useSelector(
		missionType === JUNIOR_PLUS ? isPlusCommunicationActive : isCommunicationActive
	)
	const scannerActiveStatus = useSelector(isScannerActive)
	const repairActiveStatus = useSelector(
		missionType === JUNIOR_PLUS ? isJrPlusRepairsActive : isRepairsActive
	)
	const powerActiveStatus = useSelector(
		missionType === JUNIOR_PLUS ? isPlusPowerActive : isPowerActive
	)

	const sendHack = (type: string, payload?: any) => {
		sendMessage('HACKER-' + type, payload)
	}

	const [damageAmount, setDamageAmount] = useState(10)
	const [tractorBeamStatus, setTractorBeamStatus] = useState<TractorBeamStatus>('ASTEROID')
	const [defenseStatus, setDefenseStatus] = useState<DefenseStatus>('ENEMY_SHIP')

	return (
		<div className="p-2 flex flex-col gap-2">
			<div>
				<Button onClick={() => sendHack('DAMAGE-SHIP', Number(damageAmount))}>
					Damage Ship -{damageAmount}
				</Button>
				<input
					className="text-sm w-full"
					type="number"
					value={damageAmount}
					onChange={e => {
						setDamageAmount(e.target.value)
					}}
				/>
			</div>

			<Button onClick={() => sendHack('THRUSTERS-RESET', !thrusterActiveStatus)}>
				Turn thrusters {thrusterActiveStatus ? 'off' : 'on'}
			</Button>
			<Button onClick={() => sendHack('REPAIR-RESET', !repairActiveStatus)}>
				{repairActiveStatus ? 'Deactivate Repairs' : 'Activate Repairs'}
			</Button>
			<Button
				onClick={() => {
					const payload = missionType === JUNIOR_PLUS ? { max: 0.6, min: 0.4 } : { targetPower: 5 }
					sendHack('POWER-SET', { status: !powerActiveStatus, ...payload })
				}}>
				Turn power {powerActiveStatus ? 'off' : 'on'}
			</Button>
			<Button onClick={() => sendHack('TRANSPORTER-RESET')}>Reset Transporter</Button>
			<Button
				onClick={() => {
					sendHack(
						missionType === JUNIOR_PLUS ? 'PLUS-COMMUNICATION-SET' : 'COMMUNICATION-SET',
						!communicationActiveStatus
					)
				}}>
				Turn {communicationActiveStatus ? 'off' : 'on'}
				{missionType === JUNIOR_PLUS ? ' communications' : ' the phone'}
			</Button>
			<div>
				<select
					className="text-sm w-full"
					value={tractorBeamStatus}
					onChange={e => {
						setTractorBeamStatus(e.target.value)
					}}>
					<option value={'OFFLINE'}>Offline</option>
					<option value={'ASTEROID'}>Asteroid</option>
					<option value={'ENEMY_SHIP'}>Enemy Ship</option>
					<option value={'TARGET'}>Target</option>
				</select>
				<Button onClick={() => sendHack('TRACTOR-BEAM-SET', tractorBeamStatus)}>
					{tractorBeamStatus ? `Set Tractor Beam to ${tractorBeamStatus}` : 'Turn off Tractor Beam'}
				</Button>
			</div>
			<Button onClick={() => sendHack('SCANNER-RESET', !scannerActiveStatus)}>
				{scannerActiveStatus ? 'Turn off Scanner' : 'Turn on Scanner'}
			</Button>
			<Button onClick={() => sendHack('CREATIVE-CANVAS')}>Initialize creative canvas</Button>
			<div>
				<select
					className="text-sm w-full"
					value={defenseStatus}
					onChange={e => {
						setDefenseStatus(e.target.value)
					}}>
					<option value={'OFFLINE'}>Offline</option>
					<option value={'ASTEROID'}>Asteroid</option>
					<option value={'ENEMY_SHIP'}>Enemy Ship</option>
					<option value={'TARGET'}>Target</option>
				</select>
				<Button onClick={() => sendHack('DEFENSE-SET', defenseStatus)}>
					{defenseStatus ? `Set Defense to ${defenseStatus}` : 'Turn off Defense'}
				</Button>
			</div>
			<Button onClick={() => sendHack('RESET-ALL')}>RESET ALL</Button>
			<Button onClick={() => sendHack('TOGGLE-PAUSE', !isMissionPaused)}>
				{isMissionPaused ? 'Play ' : 'Pause '}Mission
			</Button>
			<Button onClick={() => sendHack('START-TRAINING')}>Start Training</Button>
			<Button onClick={() => sendHack('START-MISSION')}>Start Mission</Button>
			<CheckBoxWrapper>
				<input
					type="checkbox"
					checked={isDebugging}
					onChange={() => dispatch(setIsDebugging(!isDebugging))}
					id="debug-toggle"
				/>
				<label htmlFor="debug-toggle">Debug</label>
			</CheckBoxWrapper>
		</div>
	)
}

const CheckBoxWrapper = styled.div`
	color: white;
`

function Button({ children, onClick }: { children: React$Node, onClick: () => void }) {
	return (
		<button
			onClick={onClick}
			className="w-full bg-black text-white text-sm p-2 hover:bg-gray-a active:bg-gray-a/80">
			{children}
		</button>
	)
}
