import type { Store } from '../store'

import {
	JR_PATCH,
	JR_FULL_STATE,
	JR_PLUS_PATCH,
	JR_PLUS_FULL_STATE,
	SET_MISSION_ANALYTICS,
	STUDENT_DETAILS,
	DISPLAY_ERROR,
	STATIC_DATA,
	JUNIOR_PLUS_STATIC_DATA,
	JUNIOR_STATIC_DATA,
	ESSENTIAL_DATA,
	FINISHED_REQUEST,
	RESET_MISSION,
	SET_VOLUME,
	SHOW_MODAL,
	UPDATE_OPTIONAL_DATA,
} from '../store/actionTypes'
import { getStation } from '../store/stores/general'
import { POINT_EVENT_RESULT } from '../store/stores/points'
import { handlePingResponse } from './networkHealth'
import { requestHandledCallbackContainer } from './requestHandledCallbacks'

// Websocket message types that should be used as redux actions. If the websocket message type is in this object, the message will be passed through as a redux action
const WEBSOCKET_ACTION_TYPES = {
	[JR_PATCH]: JR_PATCH,
	[JR_PLUS_PATCH]: JR_PLUS_PATCH,
	[JR_FULL_STATE]: JR_FULL_STATE,
	[JR_PLUS_FULL_STATE]: JR_PLUS_FULL_STATE,
	[STUDENT_DETAILS]: STUDENT_DETAILS,
	[STATIC_DATA]: STATIC_DATA,
	[JUNIOR_PLUS_STATIC_DATA]: JUNIOR_PLUS_STATIC_DATA,
	[JUNIOR_STATIC_DATA]: JUNIOR_STATIC_DATA,
	[SET_MISSION_ANALYTICS]: SET_MISSION_ANALYTICS,
	[RESET_MISSION]: RESET_MISSION,
	[ESSENTIAL_DATA]: ESSENTIAL_DATA,
	[SET_VOLUME]: SET_VOLUME,
	[SHOW_MODAL]: SHOW_MODAL,
	[UPDATE_OPTIONAL_DATA]: UPDATE_OPTIONAL_DATA,
}

// this stores the end promise of a linked list (representing a First In First Out queue) used to order the
// handling of websocket messages from the server.
let nextMessagePromise = Promise.resolve()

/**
 * handleMessageInOrder - handles a message from the server in the order they call this method
 *
 * @param {any} message - the message received by the websocket
 * @param {any} store - the redux store
 */
export const handleMessageInOrder = (message: mixed, store: Store) => {
	if (!(typeof message === 'string' || message instanceof Blob)) {
		return
	}
	const textPromise: Promise<string> =
		message instanceof Blob
			? (() =>
					new Promise((resolve, reject) => {
						let blobToJsonConverter = new FileReader()
						blobToJsonConverter.onload = () => resolve(((blobToJsonConverter.result: any): string))
						blobToJsonConverter.onerror = reject
						blobToJsonConverter.readAsText(message)
					}))()
			: Promise.resolve(message || '')

	// this adds the handler to the end of a linked list
	// to guarantee that the messages are handled in the
	// order they were received
	nextMessagePromise = new Promise((resolve, reject) => {
		nextMessagePromise
			.then(() => {
				textPromise
					.then((text: string) => {
						handleServerMessage(text, store)

						resolve()
					})
					.catch(reject)
			})
			.catch(reject)
	}).catch(error =>
		console.log('Error handling server message, Error:', error, 'Message: ', message)
	)
}

/**
 * handleServerMessage - handles the message from the server
 *
 * @param {string} text - the text (JSON) of the message from the server
 * @param {any} store - the redux store
 */
const handleServerMessage = (text: string, store: Store) => {
	try {
		const webSocketMessage = JSON.parse(text)

		if (webSocketMessage && webSocketMessage.type) {
			// redux store
			if (WEBSOCKET_ACTION_TYPES.hasOwnProperty(webSocketMessage.type)) {
				store.dispatch(webSocketMessage)
			}
			// messages that require more data from the store
			else if (webSocketMessage.type === POINT_EVENT_RESULT) {
				store.dispatch({
					type: webSocketMessage.type,
					payload: { ...webSocketMessage.payload, currentStation: getStation(store.getState()) },
				})
			}
			// display error
			else if (webSocketMessage.type === DISPLAY_ERROR) {
				const { station, ...rest } = webSocketMessage.payload
				store.dispatch({ type: `${station}_ERROR`, payload: rest })
			}
			// finished request
			else if (webSocketMessage.type === FINISHED_REQUEST) {
				const { requestId, success } = webSocketMessage.payload
				if (requestHandledCallbackContainer[requestId]) {
					requestHandledCallbackContainer[requestId](success)
				}
			} else if (webSocketMessage.type === 'PONG') {
				// response to a PING from this client
				const pingId = webSocketMessage.payload?.pingId
				if (typeof pingId !== 'string') {
					return
				}
				handlePingResponse(pingId)
			}
			// not recognized
			else {
				console.log('Unrecognized server message: ', webSocketMessage)
			}
		}
	} catch (e) {
		console.log(e)
		console.log(`Unable to parse websocket message data: ${text}`)
	}
}
