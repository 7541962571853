import React, { useState } from 'react'
import Switch from 'react-switch'
import { getToggleSocketMessage } from '../../../../../../store/selectors/jrPlusState/engineering'
import styled from 'styled-components'
import { FOREGROUND_BLUE, HIGHLIGHT_ORANGE, BACKGROUND_BLUE } from '../SharedColors'
import type { ToggleData as ToggleComponent } from '@mission.io/mission-toolkit'
import { sendMessage } from '../../../../../../websockets/websocket'

const StyledSwitchComponent = styled.div`
	color: white;
	height: min-content;
	font-size: ${({ theme }) => theme.fontxxxs};
	font-family: ${({ theme }) => theme.fontFamily};
`

export default function Toggle({ component }: { component: ToggleComponent }): React$Node {
	const [value, setValue] = useState(component.isOn)

	return (
		<StyledSwitchComponent>
			<Switch
				onChange={() => {
					sendMessage('ENGINEERING_PANEL_ACTION', getToggleSocketMessage(component.id, !value))
					setValue(!value)
				}}
				onColor={BACKGROUND_BLUE}
				offColor={BACKGROUND_BLUE}
				onHandleColor={FOREGROUND_BLUE}
				offHandleColor={HIGHLIGHT_ORANGE}
				width={50}
				height={15}
				checked={value}
			/>
			<div className="notranslate">{component.name}</div>
		</StyledSwitchComponent>
	)
}
