import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getTurnTeamSwitchOnMessage } from '../../../../../../store/selectors/jrPlusState/engineering'
import { DarkLayer, PrimaryLayer } from '../SharedColors'
import styled from 'styled-components'
import { getStudentId } from '../../../../../../store/stores/general'
import { useTeamToggleData } from '../../../../../../juniorPlusStations/Decks/components/components'
import { Button } from '../../../../../../components/basics'
import Slider from 'react-rangeslider'
import { useSpring, animated } from '@react-spring/web'
import * as colors from '../../../../../../constants/styles'
import type { TeamToggleData as TeamToggleComponent } from '@mission.io/mission-toolkit'
import { sendMessage } from '../../../../../../websockets/websocket'

const RANGE_SLIDER_MAX = 100
const RANGE_SLIDER_MIN = 0

/**
 * TeamToggle - displays the team toggle component(s)
 *
 * @param {{component:TeamToggleComponent}} {component} - the team toggle component to display
 *
 * @return {React$Node}
 */
export default function TeamToggle({ component }: { component: TeamToggleComponent }): React$Node {
	const { timerId } = component

	const studentId = useSelector(state => getStudentId(state.general))
	const teamData = useTeamToggleData(studentId)

	const [isToggleEnabled, setIsToggleEnabled] = useState(false)

	const [sliderValue, setSliderValue] = useState(timerId ? RANGE_SLIDER_MAX : RANGE_SLIDER_MIN)
	const [isChanging, setIsChanging] = useState(Boolean(timerId))

	const spring = useSpring({
		value: sliderValue,
		immediate: isChanging,
	})

	useEffect(() => {
		if (!timerId) {
			setIsToggleEnabled(false)
			setIsChanging(false)
		}
	}, [timerId])

	useEffect(() => {
		if (!isToggleEnabled) {
			setSliderValue(RANGE_SLIDER_MIN)
		}
	}, [isToggleEnabled])

	return (
		<StyledTeamToggleComponent>
			<AnimatedRangeSlider
				currentValue={spring.value}
				setIsChanging={setIsChanging}
				sliderIcon={teamData.teamTaskSliderIcon}
				disabled={isToggleEnabled}
				setValue={value => {
					if (value > 0.9 * RANGE_SLIDER_MAX) {
						setIsToggleEnabled(true)
						setSliderValue(RANGE_SLIDER_MAX)
						return
					}
					setSliderValue(value)
				}}
			/>

			<ImageWrapper>
				<StyledImage src={teamData.teamTaskIcon} />
			</ImageWrapper>
			<StyledButton
				onClick={() => {
					if (isToggleEnabled) {
						sendMessage('ENGINEERING_PANEL_ACTION', getTurnTeamSwitchOnMessage(component.id))
					}
				}}
				disabled={!isToggleEnabled && !timerId}>
				{teamData.alertActionMessage}
			</StyledButton>
		</StyledTeamToggleComponent>
	)
}

/**
 * AnimatedRangeSlider - the animated component of the range slider
 *
 * @param {Object} props - react props
 * @param {number} props.animatedValue - the animated value by react spring
 * @param {number} props.currentValue - the current (unanimated) value of the slider
 * @param {boolean} props.useAnimatedValue - if true, will use animatedValue for the position of the range slider, false will use currentValue
 * @param {(isChanging: boolean) => void} setIsChanging - a callback when the react slider is being interacted with by the user
 * @param {(value: number) => void} setValue - set the current value
 * @param {string} sliderIcon - the icon to display on the slider MUST BE AN SVG
 *
 * @return React$Node
 */
const AnimatedRangeSlider = animated(function UnAnimatedRangeSlider({
	currentValue,
	setIsChanging,
	setValue,
	sliderIcon,
	disabled,
}: {
	currentValue: number,
	setIsChanging: (isChanging: boolean) => void,
	setValue: (value: number) => void,
	sliderIcon: string,
	disabled: boolean,
}): React$Node {
	return (
		<RangeSliderWrapper>
			<StyledRangeSlider
				value={currentValue}
				orientation="vertical"
				onChange={(value: number) => {
					if (disabled) {
						return
					}
					setValue(value)
				}}
				onChangeStart={() => {
					if (disabled) {
						return
					}
					setIsChanging(true)
				}}
				onChangeComplete={() => {
					if (currentValue < 90) {
						setValue(RANGE_SLIDER_MIN)
					}
					setIsChanging(false)
				}}
				sliderIcon={sliderIcon}
				tooltip={false}
			/>
		</RangeSliderWrapper>
	)
})

const RangeSliderWrapper = styled(PrimaryLayer)`
	grid-column: 1;
	grid-row: 1 / 3;
	padding: 0;
`

const StyledRangeSlider = styled(Slider)`
	background-color: ${colors.DARKEST_SHADE};

	min-height: 40px;
	height: 80%;
	width: 16px;
	max-width: 16px;
	border-radius: 1em;
	border: 4px solid #4e497c;

	& .rangeslider__handle {
		width: 30px;
		height: 30px;
		border-radius: 1em;
		background-color: ${colors.ACCENT_BLUE};

		&:after {
			content: ${({ sliderIcon }) => `url('${sliderIcon}') `};
			position: relative;
			width: 100%;
			height: 100%;
		}
	}

	& .rangeslider__fill {
		background-color: ${colors.ACCENT_BLUE};
		border-radius: 1em;
	}

	& .rangeslider__labels {
		pointer-events: none;
	}
`

const StyledTeamToggleComponent = styled.div`
	display: grid;
	grid-template-rows: 1fr auto;
	grid-template-columns: 40px 1fr;
	gap: 8px;
	height: 100%;
`

const StyledButton = styled(Button)`
	grid-column: 2;
	grid-row: 2;
	margin: 0 !important;
	font-size: ${({ theme }) => theme.fontxxxs} !important;
	background-color: var(--color-primary-dark);
`

const StyledImage = styled.img`
	max-width: 5em;
	height: auto;
	margin: auto;
`

const ImageWrapper = styled(DarkLayer)`
	grid-column: 2;
	grid-row: 1;
	flex: 1;
	width: 100%;
	position: relative;
`
