// @flow
import React, { useEffect, useState, useRef } from 'react'
import { useDrop, useDrag } from 'react-dnd'
import { useSpring, animated } from '@react-spring/web'
import { getEmptyImage } from 'react-dnd-html5-backend'
import type { Vertex } from '../../store/stores/sharedGameStateTypes'
import type { Point } from '../../types'
import type { TransporterLinkItem } from '../../store/stores/transporter'
import { usePrevious } from '../../utility/hooks'
import classnames from 'classnames'

type Props = {
	addLine: (
		TransporterLinkItem,
		TransporterLinkItem,
		{ x: number, y: number },
		{ x: number, y: number }
	) => void,
	beginDrag: TransporterLinkItem => void,
	endDrag: () => void,
	hasConnection: boolean,
	setLinkPosition: Point => mixed,
	canDropItem: (TransporterLinkItem, Object) => boolean,
	canDragItem?: boolean,

	identifier: TransporterLinkItem,
	data: Vertex,
	id?: number,
}
const ENDPOINT = 'ENDPOINT'

function getRectFromRef(ref) {
	return ref.current ? ref.current.getBoundingClientRect() : null
}

export function ConnectionEndpoint({
	addLine,
	beginDrag,
	endDrag,
	hasConnection,
	setLinkPosition,
	canDropItem,
	canDragItem,
	identifier,
	data,
	id,
}: Props): React$Node {
	const ref = useRef<?HTMLDivElement>(null)
	let boundingRect = getRectFromRef(ref)
	useEffect(() => {
		let newBoundingRect = getRectFromRef(ref)
		if (newBoundingRect) {
			setLinkPosition([
				newBoundingRect.left + newBoundingRect.width / 2,
				newBoundingRect.top + newBoundingRect.height / 2,
			])
		}
	}, [boundingRect, setLinkPosition])
	const [, connectDrag, preview] = useDrag(
		{
			type: ENDPOINT,
			canDrag: () => {
				return canDragItem !== undefined ? !hasConnection && canDragItem : !hasConnection
			},
			item: () => {
				beginDrag(identifier)
				return { ...identifier }
			},
			end: (_, monitor) => {
				endDrag()
				if (!monitor.didDrop() || !ref.current) return
				const point1 = ref.current.getBoundingClientRect()
				const point2 = monitor.getDropResult().location
				if (!point1 || !point2) return
				addLine(
					monitor.getItem(),
					monitor.getDropResult().identifier,
					{ x: point1.left, y: point1.top },
					point2
				)
			},
		},
		[canDragItem, hasConnection, beginDrag, addLine, endDrag, identifier]
	)
	const [, connectDrop] = useDrop({
		accept: ENDPOINT,
		drop: () => {
			return {
				location: ref.current?.getBoundingClientRect(),
				identifier,
			}
		},
		canDrop: (item, monitor) => {
			return canDropItem(item, monitor)
		},
	})

	connectDrag(ref)
	connectDrop(ref)

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true })
	}, [preview])

	const [local, setLocal] = useState(data)

	return (
		<div ref={ref} style={{ gridColumnStart: local.column, gridRowStart: local.row }}>
			<DisappearingPoint
				setLocal={setLocal}
				data={data}
				id={'ConnectionEndpoint' + String(id)}
				className={classnames(
					'size-12 rounded-full shadow-[0_1px_16px_black_inset]',
					local.color ? colorToClassName[local.color] : 'bg-gray-c'
				)}
			/>
		</div>
	)
}

const colorToClassName = {
	red: 'bg-error',
	orange: 'bg-accent-orange',
	yellow: 'bg-warning',
	green: 'bg-[lime]',
	blue: 'bg-[blue]',
	purple: 'bg-[darkviolet]',
	pink: 'bg-[#F879AF]',
	cyan: 'bg-[cyan]',
}

const DisappearingPoint = ({
	setLocal,
	data,
	...rest
}: {
	setLocal: Vertex => void,
	data: Vertex,
}) => {
	const [visible, setVisible] = useState(true)
	const prevData = usePrevious(data)

	useEffect(() => {
		if (prevData) {
			setLocal(prevData)
			setVisible(false)
		}
		// We do not want to add prevData to the dependency array, but eslint throws an error. We only run this effect when data changes.
		// eslint-disable-next-line
	}, [data.column, data.row, data.color, setLocal, setVisible])

	const reRender = () => {
		setLocal(data)
		setVisible(true)
	}

	const springProps = useSpring({
		config: { duration: 3000 },
		onRest: reRender,
		from: { opacity: visible ? 0 : 1 },
		opacity: visible ? 1 : 0,
	})
	return <animated.div {...rest} style={springProps} />
}

export default ConnectionEndpoint
