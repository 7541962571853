import React, { useRef, useState, useEffect } from 'react'
import { useLiteracyEventGrade } from '../hooks'
import { Overlay } from '../helperComponents'
import { ONE_SECOND } from '../../../constants'
import classnames from 'classnames'

const VIDEOS = {
	FAIL: {
		URL: 'https://resources-cdn.mission.io/public/videos/literacyEvent/FailX.webm',
		DURATION: 6 * ONE_SECOND,
	},
	SUCCESS: {
		URL: 'https://resources-cdn.mission.io/public/videos/literacyEvent/SuccessCheck.webm',
		DURATION: 6 * ONE_SECOND,
	},
}

const VIDEO_DURATION_FALLBACK_PADDING = 2 * ONE_SECOND

/**
 * Functional component that displays a video over a darkened overlay covering the whole screen.
 * When the video is over, call the prop, onEnd.
 * @param {Object} props - Component props
 * @param {string} props.videoUrl - URL of the video to display
 * @param {function} props.onEnd - Function to call when the video is over
 * @returns {JSX.Element} - Rendered component
 */
export function DisplayGrade({ onEnd }: { onEnd: () => void }): React$Node {
	const videoRef = useRef(null)

	const correct = useLiteracyEventGrade()
	const videoUrl = correct ? VIDEOS.SUCCESS.URL : VIDEOS.FAIL.URL
	const videoHasStarted = useRef()

	// On the off chance that the video never loads or starts, then we still want to move on from this frame.
	useEffect(() => {
		const videoDuration = VIDEOS[correct ? 'SUCCESS' : 'FAIL'].DURATION
		let timeoutId
		if (!videoHasStarted.current) {
			timeoutId = setTimeout(() => {
				if (!videoHasStarted.current) {
					onEnd()
				}
			}, videoDuration)
		}

		// it is possible for the video to be paused while playing on iPads and never resume, this can also occur if the media button (ie. pause/play on keyboard) is pressed
		const fallbackTimeoutId = setTimeout(
			() => onEnd(),
			videoDuration + VIDEO_DURATION_FALLBACK_PADDING
		)
		return () => {
			clearTimeout(fallbackTimeoutId)
			clearTimeout(timeoutId)
		}
	}, [onEnd, correct])

	const [showFallback, setShowFallback] = useState(false)
	useEffect(() => {
		setTimeout(() => {
			if (!videoHasStarted.current) {
				setShowFallback(true)
			}
		}, 1000)
	}, [])

	return (
		<Overlay
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			{showFallback ? (
				<Fallback correct={correct} />
			) : (
				<video
					ref={videoRef}
					src={videoUrl}
					autoPlay
					onEnded={onEnd}
					onPlay={() => {
						videoHasStarted.current = true
					}}
					style={{
						maxWidth: '100%',
						maxHeight: '100%',
					}}
					muted="true"
				/>
			)}
		</Overlay>
	)
}

const Fallback = ({ correct }: { correct: boolean | void }) => {
	const text = correct ? 'Correct' : 'Incorrect'
	return (
		<div className="animate-5s-fade-in-out opacity-0 absolute w-full h-full flex items-center justify-center">
			<span
				className={classnames(
					correct ? 'text-primary-500' : 'text-error',
					'absolute text-[500px]'
				)}>
				&#x2B22;
			</span>
			<div className="absolute top-1/2">
				<h2 className="m-auto">{text}</h2>
			</div>
		</div>
	)
}
