import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { WEBSOCKET_STATUS, useWebsocketStatus } from '../websockets/websocketStatus'
import { reconnect } from '../websockets/websocket'
import Countdown, { zeroPad } from 'react-countdown'
import { WEBSOCKET_CONNECTION_STATUS_BANNER_Z_INDEX } from '../constants/styles'
import { pluralize } from '../utility/functions'
import { useSelector } from 'react-redux'
import { isMissionComplete as getIsMissionComplete } from '../store/stores/general'

/**
 * ConnectionStatus - shows a banner if the websocket disconnects with information about the status of the websocket.
 */
export default function ConnectionStatus(): React$Node {
	const connectionStatus = useWebsocketStatus()
	const isMissionComplete = useSelector(getIsMissionComplete)

	if (connectionStatus.status === WEBSOCKET_STATUS.CONNECTED) {
		return null
	}

	let message = null

	if (connectionStatus.status === WEBSOCKET_STATUS.DISCONNECTED) {
		if (connectionStatus.missionCode && !isMissionComplete) {
			// the websocket should be connected to a mission at this point, but is not
			message = 'Station has lost contact with the ship.'
		}
	} else if (connectionStatus.status === WEBSOCKET_STATUS.GAVE_UP) {
		message = (
			<span>
				Station has completely lost contact with the ship.{' '}
				<button className="underline" onClick={() => reconnect()}>
					Reconnect
				</button>
			</span>
		)
	} else if (connectionStatus.status === WEBSOCKET_STATUS.CONNECTING) {
		if (
			!(
				// do not show when first trying to connect to the mission
				(connectionStatus.connectedTimesBefore === 0 && connectionStatus.attempt === 0)
			)
		) {
			message = 'Attempting to connect...'
		}
	} else if (connectionStatus.status === WEBSOCKET_STATUS.RETRY_AT) {
		message = (
			<div>
				{connectionStatus.connectedTimesBefore === 0
					? 'Failed to connect to mission. Tried'
					: 'Disconnected! Tried to reconnect'}{' '}
				{connectionStatus.attempt} {pluralize('time', connectionStatus.attempt)}. Will retry in{' '}
				<Countdown
					date={connectionStatus.retryAt}
					renderer={({ minutes, seconds }) => {
						return (
							<span>
								{minutes}:{zeroPad(seconds)}
							</span>
						)
					}}
				/>
				.
			</div>
		)
	} else {
		message = `Unknown websocket status: "${connectionStatus.status}"`
	}

	if (message == null) {
		return null
	}

	return (
		<div
			className="text-error top-0 right-0 left-0 fixed text-left bg-[#000000A0] text-lg p-5 flex flex-row items-center gap-2"
			style={{ zIndex: WEBSOCKET_CONNECTION_STATUS_BANNER_Z_INDEX }}>
			<FaExclamationTriangle className="w-6" title="Connection Problem" />
			<span>{message}</span>
		</div>
	)
}
