// @flow
import { useEffect, useState, useRef, useCallback } from 'react'
import { react, transact } from '@tldraw/state'
import type { TLStoreWithStatus, TLStore, TLRecord } from '@tldraw/tldraw'
import { getFrameLockShape } from '../../customShapes/CustomFrameShapeUtil'
import { shapes } from '../../customShapes'
import {
	InstancePresenceRecordType,
	createTLStore,
	DocumentRecordType,
	PageRecordType,
	createPresenceStateDerivation,
} from '@tldraw/tldraw'
import { useDocument, useOneYjsWebsocket, type Identity } from '../CreativeCanvasDocumentContext'
import { useMyAwarenessIdentifiers } from './useMyAwarenessIdentifiers'
import { FRAME_LOCK_ID } from './useCanvasMaterial'

const STATIC_IDS = new Set([FRAME_LOCK_ID])
const DEFAULT_THROTTLE_TIME = 300
const DEFAULT_AWARENESS_THROTTLE_TIME = 200

/**
 * Creates a smooth animation between two cursor positions
 * @param store - The TLStore instance
 * @param updatedPresence - The presence object to update to
 * @param previousPresence - The previous presence state to update from
 * @param lastPresenceUpdateRef - Ref to track latest updates
 */
function tweenPresenceCursor({ store, updatedPresence, previousPresence, lastPresenceUpdateRef }) {
	// $FlowIgnore[prop-missing] this is a presence object from tlDraw, which has a cursor property
	const { x: originalX, y: originalY } = previousPresence.cursor
	const { x: newX, y: newY } = updatedPresence.cursor
	const AWARENESS_ANIMATION_STEPS = 10
	const xStep = (newX - originalX) / AWARENESS_ANIMATION_STEPS
	const yStep = (newY - originalY) / AWARENESS_ANIMATION_STEPS

	const mostRecentPresenceUpdate = lastPresenceUpdateRef.current[updatedPresence.id]

	// Recursive function that animates one step per frame
	const animateStep = currentStep => {
		if (
			// It's possible that a new update came in during the tween. This condition makes sure
			// that we do not keep running animations from stale updates.
			updatedPresence.lastActivityTimestamp !== mostRecentPresenceUpdate ||
			currentStep > AWARENESS_ANIMATION_STEPS
		) {
			return
		}

		store.put([
			{
				...updatedPresence,
				cursor: {
					...updatedPresence.cursor,
					x: originalX + xStep * currentStep,
					y: originalY + yStep * currentStep,
				},
			},
		])

		// Schedule the next step for the next animation frame
		requestAnimationFrame(() => animateStep(currentStep + 1))
	}

	// Start the animation with step 1
	requestAnimationFrame(() => animateStep(1))
}

/**
 * Sets up collaboration for the tldraw canvas. Returns the props that need to be passed to the tldraw canvas in order for a user to collaborate with other users.
 * Ensures that the tldraw canvas is in sync with the yjs document and vice versa.
 * @param {boolean} readOnly whether or not the user is allowed to edit the canvas. If true, the user will not be able to edit the canvas.
 * @param {Identity} identity the user's identity used to connect to the correct yjs document. (roomId, studentId, and missionCode)
 * @param {?string} backgroundImageUrl the background image will be displayed on the canvas. This data will not be synced with the yjs document.
 * @returns {Object} the props that need to be passed to the tldraw canvas in order for a user to collaborate with other users.
 */
export function useYjsStore(
	readOnly: boolean,
	identity: Identity,
	backgroundImageUrl: ?string
): TLStoreWithStatus {
	const [store] = useState<TLStore>(() => createTLStore({ shapes: shapes }))
	const { doc, shapes: yRecords } = useDocument(identity)
	const webSocketData = useOneYjsWebsocket(identity)
	const [storeWithStatus, setStoreWithStatus] = useState<TLStoreWithStatus>({ status: 'loading' })
	const myAwareness = useMyAwarenessIdentifiers()
	const documentId = identity.roomId

	const didSubscribeMap = useRef<{ [string]: boolean }>({})

	// A map of presence ids to the most recent presence update timestamp we have seen.
	// Used to prevent animating old presence updates.
	const lastPresenceUpdateRef = useRef({})

	/**
	 * Subscribes and synchronizes the tldraw store to the yjs doc.
	 * - Initializes the canvas with the yjs doc data.
	 * - Sets up listeners to sync the yjs doc with the tldraw store.
	 * - Sets up listeners to sync the tldraw store with the yjs doc
	 * - Sets up listeners to sync the yjs awareness with the tldraw store
	 * @returns {Function} a function that will unsubscribe the store from the yjs doc and yjs awareness
	 */
	const subscribeStoreToDoc = useCallback(
		(ws, store) => {
			const unsubs = []
			// Initialize the store with the yjs doc records—or, if the yjs doc
			// is empty, initialize the yjs doc with the default store records.
			if (yRecords.size === 0) {
				// TLDRAW: Create the initial store records
				transact(() => {
					store.clear()
					store.put([
						DocumentRecordType.create({
							id: 'document:document',
							name: documentId,
						}),
						PageRecordType.create({
							id: 'page:page',
							name: 'Page 1',
							index: 'a1',
						}),
						store.schema.types.shape.create(getFrameLockShape(backgroundImageUrl)),
					])
				})
				// YJS: Sync the store records to the yjs doc
				doc.transact(() => {
					for (const record of store.allRecords()) {
						if (STATIC_IDS.has(record.id)) continue
						yRecords.set(record.id, record)
					}
				})
			} else {
				// TLDRAW: Replace the tldraw store records with the yjs doc records
				transact(() => {
					store.clear()
					store.put(
						[
							...(backgroundImageUrl
								? [store.schema.types.shape.create(getFrameLockShape(backgroundImageUrl))]
								: []),
							...yRecords.values(),
						],
						'initialize'
					)
					store.ensureStoreIsUsable()
				})
			}

			/* -------------------- Document -------------------- */

			// Sync store changes to the yjs doc
			const stopListening = store.listen(
				throttled(function syncStoreChangesToYjsDoc({ changes }) {
					doc.transact(() => {
						Object.keys(changes.added).forEach(id => {
							if (STATIC_IDS.has(id)) return
							const record = changes.added[id]
							yRecords.set(record.id, record)
						})

						Object.keys(changes.updated).forEach(id => {
							if (STATIC_IDS.has(id)) return
							const record = changes.updated[id][1]
							yRecords.set(record.id, record)
						})

						Object.keys(changes.removed).forEach(id => {
							if (STATIC_IDS.has(id)) return
							yRecords.delete(id)
						})
					})
				}),
				{ source: 'user', scope: 'document' } // only sync user's document changes
			)
			unsubs.push(stopListening)
			/**
			 * Handles changes to the yjs doc. Syncs the changes from the yjs doc to the tldraw store.
			 */
			const handleChangeFromYjs = (events, transaction) => {
				if (transaction.local) return
				const toRemove = []
				const toPut: TLRecord[] = []
				events.forEach(event => {
					event.changes.keys.forEach((change, id) => {
						switch (change.action) {
							case 'add':
							case 'update': {
								const record = yRecords.get(id)
								if (record) {
									toPut.push(record)
								}
								break
							}
							case 'delete': {
								toRemove.push(id)
								break
							}
							default:
								return
						}
					})
				})
				// put / remove the records in the store
				store.mergeRemoteChanges(() => {
					if (toRemove.length) store.remove(toRemove)
					if (toPut.length) store.put(toPut)
				})
			}
			yRecords.observeDeep(handleChangeFromYjs)
			unsubs.push(() => yRecords.unobserveDeep(handleChangeFromYjs))

			/* -------------------- Awareness ------------------- */
			if (!readOnly) {
				// Create the instance presence derivation
				const yClientId = ws.awareness.clientID.toString()
				const presenceId = InstancePresenceRecordType.createId(yClientId)
				const presenceDerivation = createPresenceStateDerivation(
					{ value: myAwareness },
					presenceId
				)(store)
				// Set our initial presence from the derivation's current value
				ws.awareness.setLocalStateField('presence', presenceDerivation.value)

				// Create a throttled function to send presence updates to yjs awareness
				const throttledSendPresenceUpdate = throttleSimple(presence => {
					ws.awareness.setLocalStateField('presence', presence)
				}, DEFAULT_AWARENESS_THROTTLE_TIME)

				// When the derivation changes, sync presence to yjs awareness with throttling
				const unsubscribePresence = react('when presence changes', () => {
					const presence = presenceDerivation.value
					throttledSendPresenceUpdate(presence)
				})
				unsubs.push(unsubscribePresence)
				/**
				 * Handles updates to the awareness state. Syncs the awareness state from yjs
				 * to the tldraw store.
				 */
				const handleAwarenessUpdate = (update: {
					added: number[],
					updated: number[],
					removed: number[],
				}) => {
					const states = ws.awareness.getStates()
					const toRemove = []
					const toPut = []
					// Connect records to put / remove
					for (const clientId of update.added) {
						const state = states.get(clientId)
						if (state?.presence && state.presence.id !== presenceId) {
							toPut.push({
								previousPresence: store.get(state.presence.id),
								updatedPresence: state.presence,
							})
						}
					}
					for (const clientId of update.updated) {
						const state = states.get(clientId)
						if (state?.presence && state.presence.id !== presenceId) {
							toPut.push({
								previousPresence: store.get(state.presence.id),
								updatedPresence: state.presence,
							})
						}
					}
					for (const clientId of update.removed) {
						toRemove.push(InstancePresenceRecordType.createId(clientId.toString()))
					}
					// put / remove the records in the store
					store.mergeRemoteChanges(() => {
						if (toRemove.length) store.remove(toRemove)
						if (toPut.length) {
							toPut.forEach(({ previousPresence, updatedPresence }) => {
								lastPresenceUpdateRef.current[updatedPresence.id] =
									updatedPresence.lastActivityTimestamp
								if (!previousPresence) {
									store.put([updatedPresence])
									return
								}

								tweenPresenceCursor({
									store,
									updatedPresence,
									previousPresence,
									lastPresenceUpdateRef,
								})
							})
						}
					})
				}
				ws.awareness.on('update', handleAwarenessUpdate)
				unsubs.push(() => ws.awareness.off('update', handleAwarenessUpdate))
			}
			return () => {
				unsubs.forEach(fn => fn())
				unsubs.length = 0
				setStoreWithStatus({ store, status: 'synced-remote', connectionStatus: 'offline' })
				// since we're disconnecting, we need to remove the document from the map
				didSubscribeMap.current[documentId] = false
			}
		},
		[backgroundImageUrl, documentId, doc, myAwareness, readOnly, yRecords]
	)

	useEffect(() => {
		if (!webSocketData) {
			setStoreWithStatus({ status: 'loading' })
			return
		}
		const { status, synced, ws, error } = webSocketData
		if (status === 'connecting' || status === 'disconnected') {
			setStoreWithStatus({
				store,
				status: 'not-synced',
			})
			return
		}
		if (status === 'error' && error) {
			setStoreWithStatus({ status: 'error', error })
			return
		}
		if (status !== 'connected' || !synced) {
			setStoreWithStatus({ status: 'loading' })
			return
		}
		if (didSubscribeMap.current[documentId]) {
			setStoreWithStatus({ store, status: 'synced-remote', connectionStatus: 'online' })
			return
		}
		// Ok, we're connecting for the first time. Let's get started!
		const unsubscribe = subscribeStoreToDoc(ws, store)
		// We'll use this flag to prevent repeating subscriptions to the ydoc and tldraw store if our connection drops and reconnects.
		didSubscribeMap.current[documentId] = true

		// And we're done!
		setStoreWithStatus({ store, status: 'synced-remote', connectionStatus: 'online' })

		return unsubscribe
	}, [webSocketData, store, documentId, subscribeStoreToDoc])

	return storeWithStatus
}

/**
 * Throttles a function that accepts a history object. The history object contains the changes that have occurred since the last time the function was called.
 * @param {cb} fn
 * @param {?number} throttleTime in milliseconds defaults to 300 ms
 */
function throttled(
	fn: (onHistory: {
		changes: {
			added: { [string]: TLRecord },
			updated: { [string]: TLRecord },
			removed: { [string]: TLRecord },
		},
	}) => void,
	throttleTime: number = DEFAULT_THROTTLE_TIME
) {
	let timeoutId = null
	let changes = { added: {}, updated: {}, removed: {} }
	return onHistory => {
		changes.added = { ...changes.added, ...onHistory.changes.added }
		changes.removed = { ...changes.removed, ...onHistory.changes.removed }
		changes.updated = { ...changes.updated, ...onHistory.changes.updated }
		if (!timeoutId) {
			timeoutId = setTimeout(() => {
				fn({ ...onHistory, changes })
				timeoutId = null
				changes = { added: {}, updated: {}, removed: {} }
			}, throttleTime)
		}
	}
}

/**
 * A simple throttle function. The throttled function will only call the original function at most once per throttleTime.
 * @param {Function} fn The function to throttle
 * @param {number} throttleTime The time to throttle in milliseconds
 * @returns {Function} The throttled function
 */
function throttleSimple(fn, throttleTime) {
	let timeoutId = null
	let lastValue = null
	let hasNewValue = false

	return value => {
		lastValue = value
		hasNewValue = true

		if (!timeoutId) {
			timeoutId = setTimeout(() => {
				if (hasNewValue) {
					fn(lastValue)
				}
				timeoutId = null
				hasNewValue = false
			}, throttleTime)
		}
	}
}
