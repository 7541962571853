import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PRIMARY_PURPLE } from '../../../../../constants/styles'
import StationSelect from './StationSelect'
import stationMap, { orderedStations } from '../../../../../constants/stationMap'
import { switchStation } from '../../../../../store/stores/general'
import { getStationActivity } from '../../../../../utility/selectors'
import styled from 'styled-components'

import type { StationEnum } from '../../../../../types'
import type { ReduxStore } from '../../../../../store/rootReducer'

type Props = {
	isStationActive: { [stationKey: string]: boolean },
	switchStation: StationEnum => any,
}

const StationSelectorStyle = styled.div`
	height: 100%;
	display: flex;
	padding: 0px 26px 16px 26px;
	min-width: 104px;
	flex-direction: column;
	position: relative;
	justify-content: space-around;
	background-color: ${PRIMARY_PURPLE};
	border-left: 2px inset @light-gray;
	z-index: @ui-zIndex;
	grid-row: station-select-start / station-select-end;
	grid-column: station-select-start / station-select-end;
`

class StationSelector extends Component<Props> {
	render() {
		const blinkingLightsClassName =
			'size-2 rounded-full border-gray-a my-0 mx-[5px] animate-station-selector-blinking'

		return (
			<StationSelectorStyle>
				{orderedStations.map(stationKey => {
					return (
						<StationSelect
							stationId={stationKey}
							station={stationMap()[stationKey]}
							onClick={() => this.props.switchStation(stationKey)}
							isActive={this.props.isStationActive[stationKey]}
							key={stationKey}
						/>
					)
				})}
				<div className="h-[10px] absolute bottom-[3px] right-0 w-full flex justify-center pl-[10px]">
					<div className={blinkingLightsClassName + ' bg-[#ffae42]'} />
					<div className={blinkingLightsClassName + ' bg-[#00AEEF] [animation-delay:200ms]'} />
					<div className={blinkingLightsClassName + ' bg-accent-green [animation-delay:400ms]'} />
				</div>
			</StationSelectorStyle>
		)
	}
}

const mapDispatchToProps = {
	switchStation,
}

function mapStateToProps(state: ReduxStore) {
	return {
		isStationActive: getStationActivity(state),
	}
}

export default (connect(mapStateToProps, mapDispatchToProps)(StationSelector): (
	props: $Shape<{}>
) => React$Node)
