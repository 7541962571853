export const ONE_SECOND = 1000 // ms
export const ONE_MINUTE = 60 * ONE_SECOND

export const DRAG_TYPES = {
	ROBOT: 'ROBOT',
}
export const MAX_POINTS = 5000
export const HEALTH_MAX = 100
const SERVER_UPDATE_INTERVAL = 300

// Alphabet so that we can show Multiple Choice options in alphabet order
export const ALPHABET_ARRAY = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
]

// thruster constants
export const THRUSTERS_SEND_UPDATE_AFTER = SERVER_UPDATE_INTERVAL * (2 / 3)
export const THRUSTERS_POINT_LOCATIONS = [
	{
		x: '65%',
		y: '50%',
	},
	{ x: '70%', y: '50%' },
	{ x: '75%', y: '50%' },
	{ x: '80%', y: '50%' },
]

// the minimum euclidean distance between the fullState students direction and the joy stick student direction needed before allowing an update to be sent
export const MIN_DISPLACEMENT_BEFORE_UPDATE = 1 / 8
