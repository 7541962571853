// @flow
import React, { type AbstractComponent } from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { InfiniteMarquee } from './InfiniteMarquee'
import { DecalInformation } from './DecalInformation'
import { DecalExtenderFrame } from './DecalExtenderFrame'
import { fadeIn, fadeOut } from '../../../helperComponents/keyframes'
import { VideoController } from '../../../../VideoController.jsx'
import classnames from 'classnames'
import Loading from '../../../../basics/Loading.jsx'
import { ContentExpander } from '../../../helperComponents/ContentExpander.jsx'
import type { Image, Video, Audio } from '@mission.io/mission-toolkit/actions'
import { isController } from '../../../../../utility/urls'

type InformationFrameProps = {
	className?: string,
	contentClassName?: string,
	hideDecal?: boolean,
	children: React$Node,
	headline?: string | null,
	isExiting: boolean,
	onFinishExit: () => void,
}
/**
 * A frame for displaying information about a literacy event.
 */
export const InformationFrame: AbstractComponent<
	InformationFrameProps,
	?HTMLDivElement
> = React.forwardRef(function _InformationFrame(
	{
		className,
		children,
		headline,
		isExiting,
		onFinishExit,
		hideDecal,
		contentClassName,
	}: InformationFrameProps,
	ref
): React$Node {
	const id = React.useId()
	return (
		<InformationFrameStyle
			data-testid="information-frame"
			ref={ref}
			className={className}
			$exit={isExiting}
			id={id}
			onAnimationEnd={e => {
				if (e.target.id === id) {
					isExiting && onFinishExit()
				}
			}}>
			<AnimatedFade $exit={isExiting}>
				{headline && <InfiniteMarquee title={headline}></InfiniteMarquee>}
				<InformationContent className={contentClassName}>{children}</InformationContent>
				{!hideDecal && <DecalInformation />}
			</AnimatedFade>
		</InformationFrameStyle>
	)
})

export type ExtenderMedia = Image | Video | Audio
type ExtenderFrameProps = {
	style?: {},
	media: ExtenderMedia,
	isExiting: boolean,
	controlModal?: { isOpen: boolean, setIsOpen: Function },
}

/**
 * An extended portion of the frame that can be used to display additional information.
 */
export const ExtenderFrame: AbstractComponent<
	ExtenderFrameProps,
	?HTMLDivElement
> = React.forwardRef(function _ExtenderFrame(
	{ style, media, isExiting, controlModal }: ExtenderFrameProps,
	ref
): React$Node {
	const positioningRef = React.useRef()
	const [isLoadFinished, setIsLoadFinished] = React.useState(false)
	return (
		<div className="relative grow">
			<ExtenderFrameStyle
				ref={ref}
				style={style}
				$exit={isExiting}
				className="absolute z-0 opacity-0 max-w-[calc(100%-48px-1.5rem)] -translate-x-1/2 md:-translate-y-1/2 md:translate-x-0 md:max-h-[calc(100%-48px-1.5rem)] md:max-w-full">
				<div className="flex flex-col items-center md:flex-row ">
					{isLoadFinished && <DecalExtenderFrame contentRef={positioningRef} />}
					<div
						// The left margin moves it over so that the media inside does not get cut off as much. This is not a perfect solution, as the image still gets cuts off differently at different screen sizes.
						className="relative bg-[rgba(0,255,255)]/50 border-[3px] border-[--color-accent-aqua] ml-[1px] rounded-b-2xl md:rounded-r-2xl md:rounded-bl-none p-3 pt-0 md:pl-0 md:pt-3 w-full"
						ref={positioningRef}>
						<ExtendedContent
							media={media}
							onLoadFinished={() => {
								setIsLoadFinished(true)
							}}
							controlModal={controlModal}
						/>
					</div>
				</div>
			</ExtenderFrameStyle>
		</div>
	)
})

/**
 * The content for a smaller frame frame that will roll out from the side of the literacy event frame. For most text literacy events, this will be an image.
 * @param {Image|Video|Audio} props.media - The media to display in the extended content
 * @param {{isOpen: boolean, setIsOpen: Function}} props.controlModal - A function to control the expanded modal for a media item
 * @param {Function} props.onLoadFinished - A function to call when the media has finished loading
 * @returns {React$Node}
 */
function ExtendedContent({
	media,
	controlModal,
	onLoadFinished,
}: {
	media: ExtenderMedia,
	controlModal?: { isOpen: boolean, setIsOpen: Function },
	onLoadFinished: () => void,
}): React$Node {
	const [loaded, _setLoaded] = React.useState(false)
	const setLoaded = (value: boolean) => {
		_setLoaded(value)
		if (onLoadFinished) onLoadFinished()
	}

	const [subscribingVideoRefs, setSubscribingVideoRefs] = React.useState<
		Array<{
			current: ?HTMLVideoElement,
		}>
	>([])

	const subscribe = React.useCallback(videoRef => {
		setSubscribingVideoRefs(prev => [...prev, videoRef])
	}, [])

	const mediaJsx = (inModal?: boolean) =>
		media.type === 'IMAGE' ? (
			<img
				alt="Event Alert"
				className={classnames(
					'overflow-hidden border-2 border-[--color-accent-aqua] bg-primary-200/60',
					inModal
						? 'rounded-xl max-h-[80vh] max-w-[80vw]'
						: 'rounded-b-xl md:rounded-r-xl md:rounded-bl-none'
				)}
				src={media.url}
				onLoad={() => setLoaded(true)}
			/>
		) : media.type === 'VIDEO' ? (
			<div
				className={classnames(
					'max-h-full max-w-full min-h-36 aspect-video bg-black overflow-hidden pointer-events-auto',
					inModal ? 'rounded-xl' : 'rounded-b-xl md:rounded-bl-none md:rounded-tr-xl'
				)}>
				<VideoController
					autoPlay={inModal}
					src={media.url}
					onLoad={() => setLoaded(true)}
					subscribe={subscribe}
				/>
			</div>
		) : (
			<audio onLoadedData={() => setLoaded(true)} className="pl-1 min-w-48 max-w-full" controls>
				<source src={media.url} />
			</audio>
		)
	// The max media height allowed differs between the controller and student view
	const maxHeight = isController()
		? 'md:[&>img]:max-h-[calc(23vh-48px)] [&>img]:max-w-[60vw] [&>img]:max-h-[calc(40vh-7rem)]'
		: '[&>img]:max-h-[calc(35vh-48px)]'
	return (
		<>
			<div
				className={classnames(
					maxHeight,
					'md:[&>img]:border-l-0 [&>img]:border-t-0 relative md:rounded-r-xl md:rounded-bl-none rounded-b-xl w-full'
				)}>
				{!loaded && (
					<div className="absolute top-0 h-full w-full">
						<Loading className="size-8 inset-center" />
					</div>
				)}
				{mediaJsx(false)}
			</div>
			{media.type !== 'AUDIO' && loaded && (
				<ContentExpander
					modalStyles={
						media.type === 'VIDEO'
							? {
									content: {
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										width: '90%',
										height: '90%',
										pointerEvents: 'none',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									},
							  }
							: {}
					}
					className="bg-[#020223] bottom-8 right-8"
					controlModal={controlModal}
					onExpand={() => {
						// Pause the first rendered video when the modal is opened, the first will be the small one in the frame
						subscribingVideoRefs[0]?.current?.pause()
					}}>
					{mediaJsx(true)}
				</ContentExpander>
			)}
		</>
	)
}

const VERTICAL_SLIDE_IN_DURATION = 250
const CONTENT_FADE_IN_DURATION = 250
const EXTENDER_SLIDE_IN_DURATION = 250
export const TOTAL_DELAY: number =
	VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION + EXTENDER_SLIDE_IN_DURATION

const slideDown = keyframes`
from {
	height: 0%;
}
to {
	height: 100%;
}
`
const slideUp = keyframes`
	from {
		height: 100%;	

	}
	to {
		height: 0%;
	}
`

const InformationFrameStyle = styled.div.attrs(() => ({
	className: 'bg-primary-600 h-full w-full rounded-2xl p-2 relative',
}))`
	font-size: var(--font-size-m);
	z-index: 1;
	${({ $exit }) => css`
		animation: ${VERTICAL_SLIDE_IN_DURATION}ms ease-in ${$exit ? EXTENDER_SLIDE_IN_DURATION : 0}ms 1
			forwards ${$exit ? slideUp : slideDown};
	`}
`
const AnimatedFade = styled.div`
	${({ $exit }) => css`
		opacity: ${$exit ? 1 : 0};
		animation: ${CONTENT_FADE_IN_DURATION}ms ease-in ${$exit ? VERTICAL_SLIDE_IN_DURATION : 0}ms 1
			forwards ${$exit ? fadeOut : fadeIn};
	`}
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const InformationContent = styled.div`
	flex: 1;
	overflow-y: hidden;
	background: linear-gradient(#ddddf6, #c4feff);
	border-radius: 1rem;
	border: 4px solid var(--color-accent-aqua);
	text-align: left;
	padding-bottom: 24px;
`
// For small and mobile screens
const slideTopToBottom = keyframes`
	from {
		top: -100%;
	}
	to {
		top: 0%;
	}
`
// For small and mobile screens
const slideBottomToTop = keyframes`
	from {
		top: 0%;
	}
	to {
		top: -100%;
	}
`
// For medium to large screens
const slideLeftToRight = keyframes`
	from {
		left: -100%;
	}
	to {
		left: 0%;
	}
`
// For medium to large screens
const slideRightToLeft = keyframes`
	from {
		left: 0%;
	}
	to {
		left: -100%;
	}
`

const ExtenderFrameStyle = styled.div`
	left: 50%;
	@media (min-width: 768px) {
		top: 50%;
	}
	${({ $exit }) =>
		$exit
			? css`
					opacity: 1;
					top: 0%;
					animation: ${slideBottomToTop} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards 0ms,
						${fadeOut} 1ms linear forwards ${EXTENDER_SLIDE_IN_DURATION}ms;

					// Smaller screens stack and animate vertically instead of horizontally
					@media (min-width: 768px) {
						left: 0%;
						animation: ${slideRightToLeft} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards 0ms,
							${fadeOut} 1ms linear forwards ${EXTENDER_SLIDE_IN_DURATION}ms;
					}
			  `
			: css`
					opacity: 0;
					top: -100%;
					animation: ${fadeIn} 1ms linear forwards
							${VERTICAL_SLIDE_IN_DURATION + 0.5 * CONTENT_FADE_IN_DURATION}ms,
						${slideTopToBottom} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards
							${VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION}ms;

					// Smaller screens stack and animate vertically instead of horizontally
					@media (min-width: 768px) {
						left: -100%;
						animation: ${fadeIn} 1ms linear forwards
								${VERTICAL_SLIDE_IN_DURATION + 0.5 * CONTENT_FADE_IN_DURATION}ms,
							${slideLeftToRight} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards
								${VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION}ms;
					}
			  `}
`
