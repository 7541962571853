import React from 'react'
import Canvas from '../../Canvas'
import type { DisplayTeam } from '@mission.io/mission-toolkit'
import { CreativeCanvasFullScreen } from './CreativeCanvasFullScreen'
import { BiLock } from 'react-icons/bi'
import { Button } from '../../../basics/Buttons'
import { LockPopup } from '../../Canvas.jsx'
import { useTeamForDocumentId } from '../../../../store/selectors/jrPlusState/canvas'

/* A card to display a single team's canvas within a grid of other canvases. Displays the canvas and the team name. Clicking on the card will select the canvas and cause it to be fullscreen.
 * @param {string} props.teamId the team id of the team whose canvas is being displayed
 * @param {string} props.documentId the documentId that represents the canvas created
 * @param {number} props.lockPopupMaxHeight - The max-height of the "Lock/Unlock Students" popup.

 */
export function CanvasCardForWatchingEdits({
	teamId,
	documentId,
	canvasId,
	className,
	lockPopupMaxHeight,
}: {
	teamId: string,
	documentId: string,
	canvasId: string,
	className?: string,
	lockPopupMaxHeight?: number,
}): React$Node {
	const [isFullScreenOpen, setIsFullScreenOpen] = React.useState(false)
	const [lockPopupIsOpen, setLockPopupIsOpen] = React.useState(false)
	const team = useTeamForDocumentId(documentId)
	const cardRef = React.useRef(null)
	return (
		<button
			ref={cardRef}
			className={`${className ?? ''} bg-primary-600 p-2 rounded-lg flex flex-col justify-between`}
			onClick={() => {
				setIsFullScreenOpen(true)
			}}>
			<TeamName team={team} />

			<Canvas
				documentId={documentId}
				isTeacher
				readOnly
				className="[--canvas-border-radius:theme('spacing.3')] [--canvas-border-width:theme('spacing.2')]"
			/>
			<div className="relative">
				<Button
					$small
					className="mt-2"
					onClick={e => {
						e.stopPropagation()
						setLockPopupIsOpen(prev => !prev)
					}}>
					<div className="flex flex-row relative align-center">
						<BiLock size={22} className="mr-2" /> Lock Students
					</div>
				</Button>
				{lockPopupIsOpen && (
					<LockPopup
						{...{
							team,
							className: 'bottom-full',
							onClose: () => setLockPopupIsOpen(false),
							maxHeight:
								lockPopupMaxHeight ??
								(cardRef.current?.offsetHeight ? cardRef.current.offsetHeight * 0.8 : 0),
						}}
					/>
				)}
			</div>
			{isFullScreenOpen && (
				<CreativeCanvasFullScreen
					isOpen
					onClose={e => {
						e.stopPropagation()
						setIsFullScreenOpen(false)
					}}
					teamId={teamId}
					documentId={documentId}
					canvasId={canvasId}
				/>
			)}
		</button>
	)
}

// Show Team name above the canvas
function TeamName({ team }: { team: ?DisplayTeam }): React$Node {
	if (!team) return null
	return (
		<div className="flex-1 items-center flex z-20 pb-2 rounded-lg">
			<img src={team.icon} className="size-6" alt="" />
			<h3 className="mr-8 ml-4 text-sm">{team.displayName}</h3>
		</div>
	)
}
