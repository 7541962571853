// @flow
import React from 'react'
import styled from 'styled-components'
import { Station } from '../../components/basics'
import { connect } from 'react-redux'
import { getStudentId } from '../../store/stores/general'
import Map from './Map'
import CollectedData from './CollectedData'
import LineDrawingProvider from './LineDrawingContext'
import { getCurrentMap, getCurrentMapInfo } from '../../store/selectors/jrPlusState/sensor'
import type { Map as MapType } from '@mission.io/mission-toolkit'
import type { MapObjectInfo } from '../../store/selectors/jrPlusState/sensor'
import type { ReduxStore } from '../../store/rootReducer'

type Props = {
	map: ?MapType,
	objectInfo: ?MapObjectInfo,
	studentId: string,
}

const StyledSensor = styled(Station)`
	max-height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

function Sensor({ map, objectInfo, studentId }: Props) {
	return (
		<StyledSensor>
			<LineDrawingProvider>
				<Map missionMap={map} objectInfo={objectInfo} studentId={studentId} />
				<CollectedData isCulminatingMoment={false} />
			</LineDrawingProvider>
		</StyledSensor>
	)
}

function mapStateToProps(state: ReduxStore) {
	const map = getCurrentMap(state)
	const singleMapInfo = getCurrentMapInfo(state)
	return {
		map,
		objectInfo: singleMapInfo ? singleMapInfo.objectInfo : null,
		studentId: getStudentId(state.general),
	}
}

export default (connect(mapStateToProps)(Sensor): (props: $Shape<{||}>) => React$Node)
