import React from 'react'
import { useSelector } from 'react-redux'
import type { Station, StationEnum } from '../../../../../types'
import { getStation } from '../../../../../store/stores/general'
import { StationIcon, StationName } from '../../../shared'
type Props = {
	station: Station,
	onClick: () => void,
	isActive: boolean,
	stationId: StationEnum,
}

export default function StationSelect({
	station: { displayName, icon: Icon },
	stationId,
	isActive,
	onClick,
}: Props): React$Node {
	const isCurrent = useSelector(state => getStation(state) === stationId)
	return (
		<StationIcon $active={isActive} onClick={onClick} $isCurrent={isCurrent}>
			<Icon />
			<StationName>{displayName}</StationName>
		</StationIcon>
	)
}
