// @flow
import React, { createContext, useState, useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'twilio-video'
import type { Participant, Room, MediaStreamTrack } from 'twilio-video'
import { ParticipantAudio } from '../components/VideoChat/ParticipantAudio'
import { getMissionCode, getMissionCategory } from '../store/stores/staticData'
import { getStudentId, isEnded } from '../store/stores/general'
import { getStudents, getIsCurrentStudentMuted } from '../store/selectors/sharedSelectors'
import { MISSION_CATEGORIES } from '@mission.io/mission-toolkit/constants'
import { clamp } from '../utility/functions'
import { useInterval } from 'use-interval'
import axios from 'axios'
import config from '../config'
import 'styled-components/macro'
import { useMissionServerUrls } from '../websockets/urls'

const MIN_AUDIO_THRESHOLD: number = 15 // If volume drops below this value audio is effectively muted
const AUDIO_REFRESH_RATE: number = 100 // determines rate of audio polling (in milliseconds)
const SPEAKER_REFRESH_RATE = 500 // time between updating speaker list
const STANDARD_SPEAKING_VOLUME = 1500 // used to indicate a speaker is speaking at full volume
const SPEAKING_PAUSE = 3 // when volume drops to zero for 3 ticks of SPEAKER_REFRESH_RATE, speaker loses position in list

export type ParticipantConnection = {
	connected: boolean,
	speakingStrength: number,
	participant: ?Participant,
}

type AllTracks = { [id: string]: ParticipantConnection }
type SpeakerInfo = { id: string, counter: number }
type SetStateFunction<T> = ((T => T) | T) => void // the set state function provided from react's useState

type RefObject<T> = {|
	current: T,
|}

/**
 * Takes a function 'makeInitialValue' and returns a getter for the lazy reference
 * When not defined, 'makeInitialValue' will be used to instantiate the reference
 */
function useLazyRef<T>(makeInitialValue: () => T): () => T {
	const ref = useRef()
	return () => {
		if (!ref.current) {
			ref.current = makeInitialValue()
		}
		return ref.current
	}
}

type AudioSettings = {
	analyser: AnalyserNode,
	audioContext: AudioContext,
	destination: MediaStreamAudioDestinationNode,
	gainNode: GainNode,
	source: RefObject<?MediaStreamAudioSourceNode>,
	dataArray: Uint8Array,
}

export type RoomState = {
	room: ?Room,
	speakerOrder: SpeakerInfo[],
	tracks: { [id: string]: ParticipantConnection },
	setTracks: SetStateFunction<AllTracks>,
	activeTrackName: ?string,
	setActiveTrackName: SetStateFunction<?string>,
	audioSettings: RefObject<AudioSettings>,
	audioEnabled: boolean,
	setAudioEnabled: SetStateFunction<boolean>,
}

export const RoomControlsContext: React$Context<?RoomState> = createContext<?RoomState>()

/**
 * Returns first active local audioTrack
 * @param {?Room} room
 * @return {?MediaStreamTrack} returned stream track
 */
export function getFirstLocalAudioTrack(room: ?Room): ?MediaStreamTrack {
	const audioTracks =
		room && room.localParticipant && Array.from(room.localParticipant.audioTracks.values())
	return audioTracks && audioTracks.length > 0 && audioTracks[0]
}

/**
 * Creates an audiotrack using mediaDevice's deviceId
 * unpublishes existing audio track and publishes new track
 */
export function setAudio(state: ?RoomState, mediaDevice: MediaDeviceInfo): void {
	if (state) {
		_setAudio(state.room, state.audioSettings, state.setActiveTrackName, mediaDevice)
	}
}

/**
 * Sets mediaDevice's audio stream to Twilio using the web audio API
 * If mediaStream is undefined we use the default device
 */
function _setAudio(
	room: ?Room,
	audioSettings: RefObject<AudioSettings>,
	setActiveTrackName: SetStateFunction<?string>,
	mediaDevice?: MediaDeviceInfo
): void {
	if (room && room.localParticipant) {
		const audioOptions = mediaDevice
			? {
					deviceId: { exact: mediaDevice.deviceId },
					name: mediaDevice.label,
			  }
			: true // Use default audio when mediaDevice is not provided
		navigator.mediaDevices &&
			navigator.mediaDevices
				.getUserMedia({
					audio: audioOptions,
				})
				.then(newAudioStream => {
					const audioStreamTracks = newAudioStream.getAudioTracks()
					setActiveTrackName(
						audioStreamTracks.length > 0 ? audioStreamTracks[0].getSettings().deviceId : 'default'
					)
					const modifiedAudioStreamTrack: ?MediaStreamTrack = plugInAudioNodes(
						newAudioStream,
						audioSettings
					)
					if (
						modifiedAudioStreamTrack &&
						Array.from(room.localParticipant.audioTracks.values()).length === 0
					) {
						room.localParticipant.publishTrack(modifiedAudioStreamTrack)
					}
				})
				.catch(e => {
					console.error('Failed to set microphone:', e)
				})
	}
}

/**
 * Takes audio stream and creates branch containing analyser and gain control
 * audioStream --> Analyser --> GainNode --> Destination
 */
function plugInAudioNodes(
	audioStream: MediaStream,
	audioSettings: RefObject<AudioSettings>
): ?MediaStreamTrack {
	let modifiedTrack: ?MediaStreamTrack
	if (audioSettings.current.source.current) {
		// $FlowFixMe mediaStream is a property of MediaStreamAudioSourceNode
		const activeAudioTracks = audioSettings.current.source.current.mediaStream.getAudioTracks()
		activeAudioTracks.forEach(audioTrack => audioTrack.stop())
		audioSettings.current.source.current && audioSettings.current.source.current.disconnect()
		audioSettings.current.gainNode.disconnect()
		audioSettings.current.analyser.disconnect()
	}

	audioSettings.current.source.current = audioSettings.current.audioContext.createMediaStreamSource(
		audioStream
	)

	if (audioSettings.current.source.current) {
		audioSettings.current.source.current.connect(audioSettings.current.analyser)
		audioSettings.current.analyser.connect(audioSettings.current.gainNode)
		audioSettings.current.gainNode.connect(audioSettings.current.destination)
		modifiedTrack = audioSettings.current.destination.stream.getAudioTracks()
	}
	return modifiedTrack && modifiedTrack.length > 0 ? modifiedTrack[0] : null
}

/**
 * Updates studentId's audiotrack data.
 * Used to attach/detach tracks to student ids and mark students as connected/disconnected
 */
function applyChangeTrack(
	setTracks: SetStateFunction<AllTracks>,
	id: string,
	track: $Shape<ParticipantConnection>
): void {
	setTracks((tracks: { [participantId: string]: ParticipantConnection }) => {
		const allTracks = { ...tracks }
		if (allTracks.hasOwnProperty(id)) {
			allTracks[id] = { ...tracks[id], ...track }
		}
		return allTracks
	})
}

/**
 * Audio context cannot be used unless the user has interacted with the screen.
 * When the user hits the join audio chat button, we resume the audiocontext
 */
export function enableAudio(state: ?RoomState): void {
	if (state && state.audioSettings) {
		state.audioSettings.current.audioContext.resume()
		state.setAudioEnabled(true)
	}
}

/**
 * Disconnects from twilio room, discontinues all inputs, and resets all RoomState data
 */
export function disconnectFromRoom(
	setRoom: SetStateFunction<?Room>,
	setTracks: SetStateFunction<AllTracks>
): void {
	const stopTracks: ((?Room) => ?Room) => void = (room: ?Room) => {
		if (room && room.localParticipant) {
			Array.from(room.localParticipant.audioTracks.values()).forEach(at => at.track.stop())
			room.disconnect()
		}
		return undefined
	}
	setRoom(stopTracks)
	setTracks({})
}

export function getTeacherId(missionCode: string): string {
	return 'teacher-'.concat(missionCode)
}

/**
 * Joins Twilio room with audio/video and instantiates listeners for certain events. Listeners will continue until room is disconnected
 */
export async function joinRoom(
	setRoom: SetStateFunction<?Room>,
	setTracks: SetStateFunction<AllTracks>,
	trackIdToParticipantId: RefObject<Map<string, string>>,
	missionCode: string,
	studentIds: string[],
	token: string
) {
	const room: ?Room = await connect(token, {
		name: missionCode,
		video: false,
		audio: false,
	}).catch(e => {
		console.error('failed to join twilio: ', e)
	})
	const allTracks = {}
	studentIds.forEach(id => (allTracks[id] = { connected: false, participant: {} }))
	allTracks[getTeacherId(missionCode)] = { connected: false, participant: {} } // add teacher to list of possible connections
	setRoom(room)
	setTracks(allTracks)
	const participantConnected = participant => {
		applyChangeTrack(setTracks, participant.identity, {
			connected: true,
			speakingStrength: 0,
			participant: participant,
		})
	}
	const participantDisconnected = participant => {
		applyChangeTrack(setTracks, participant.identity, {
			connected: false,
			speakingStrength: 0,
			participant: {},
		})
		participant.tracks.forEach(track => trackRemoved(track.trackSid, participant))
	}
	const trackAdded = (track, participant) => {
		trackIdToParticipantId.current.set(track.sid, participant.identity)
	}
	const trackRemoved = (trackId, participant) => {
		trackIdToParticipantId.current.delete(trackId)
	}

	if (room) {
		room.participants.forEach(participant => {
			participantConnected(participant)
			participant.on('trackSubscribed', track => {
				participantConnected(participant)
				trackAdded(track, participant)
			})
		})

		room.on('participantConnected', participant => {
			participantConnected(participant)

			participant.on('trackSubscribed', track => {
				participantConnected(participant)
				trackAdded(track, participant)
			})

			participant.on('trackUnsubscribed', track => {
				participantConnected(participant)
				trackRemoved(track.sid, participant)
			})
		})
		room.on('participantDisconnected', participant => {
			participantDisconnected(participant)
		})
	}
}

/**
 * Returns typed entries from tracks
 */
function getTrackEntries(tracks: {
	[id: string]: ParticipantConnection,
}): [string, ParticipantConnection][] {
	return Object.keys(tracks).map(key => [key, tracks[key]])
}

/**
 * Provider for RoomControlsContext, handles joining/leaving rooms, acquiring videochat tokens, and muting/unmuting audiotrack
 */
export function RoomControlsProvider(props: { children: React$Node }): React$Node {
	// state for joining/leaving rooms
	const [token, setToken] = useState(undefined)
	// context state
	const [room, setRoom] = useState(undefined)
	const [tracks, setTracks] = useState({})
	const [activeTrackName, setActiveTrackName] = useState()
	const [audioEnabled, setAudioEnabled] = useState(false)
	const trackIdToParticipantId: RefObject<Map<string, string>> = useRef(new Map())
	const [speakerOrder, setSpeakerOrder] = useState([])

	const missionServerUrl = useMissionServerUrls()[0].data?.missionServerUrl

	const studentId = useSelector(state => getStudentId(state.general))
	const students = useSelector(getStudents)
	const { current: studentIds } = useRef(Object.keys(students)) // need a static list of studentIds for the useEffect hook
	const missionCode: ?string = useSelector(getMissionCode)
	const missionEnded = useSelector(isEnded)
	const isMuted = useSelector(getIsCurrentStudentMuted)
	const localAudioTrack = getFirstLocalAudioTrack(room)
	const missionCategory = useSelector(getMissionCategory)

	const isRemote: boolean =
		missionCategory === MISSION_CATEGORIES.WHOLE_CLASS_REMOTE ||
		missionCategory === MISSION_CATEGORIES.SQUADRON_REMOTE ||
		missionCategory === MISSION_CATEGORIES.SQUADRON_HOMEWORK

	// audio settings
	const getAudioContext: () => AudioContext = useLazyRef<AudioContext>(
		() => new (window.AudioContext || window.webkitAudioContext)()
	)
	const getDestination: () => MediaStreamAudioDestinationNode = useLazyRef<MediaStreamAudioDestinationNode>(
		() => getAudioContext().createMediaStreamDestination()
	)
	const getAnalyser: () => AnalyserNode = useLazyRef<AnalyserNode>(() =>
		getAudioContext().createAnalyser()
	)
	const source: RefObject<?MediaStreamAudioSourceNode> = useRef<?MediaStreamAudioSourceNode>()
	const getGainNode: () => GainNode = useLazyRef<GainNode>(() => getAudioContext().createGain())
	const getDataArray: () => Uint8Array = useLazyRef<Uint8Array>(
		() => new Uint8Array(getAnalyser().frequencyBinCount)
	)
	const audioSettings = useRef({
		analyser: getAnalyser(),
		audioContext: getAudioContext(),
		destination: getDestination(),
		gainNode: getGainNode(),
		dataArray: getDataArray(),
		source,
	})

	// Poll audio volume every AUDIO_REFRESH_RATE
	// if volume does not exceed MIN_AUDIO_THRESHOLD set gain to 0
	const analyseFunction = () => {
		if (source.current) {
			audioSettings.current.analyser.getByteFrequencyData(audioSettings.current.dataArray)
			const sumSq = audioSettings.current.dataArray.reduce(
				(sumSq, sample) => sumSq + sample * sample,
				0
			)
			const volumeOutput = Math.sqrt(sumSq / audioSettings.current.dataArray.length)
			if (volumeOutput < MIN_AUDIO_THRESHOLD && audioSettings.current.gainNode.gain.value !== 0) {
				audioSettings.current.gainNode.gain.setValueAtTime(
					0,
					audioSettings.current.audioContext.currentTime
				)
			} else if (
				volumeOutput >= MIN_AUDIO_THRESHOLD &&
				audioSettings.current.gainNode.gain.value !== 1
			) {
				audioSettings.current.gainNode.gain.setValueAtTime(
					1,
					audioSettings.current.audioContext.currentTime
				)
			}
		}
	}

	const roomControls = useMemo(() => {
		return {
			room: room,
			speakerOrder: speakerOrder,
			tracks: tracks,
			setTracks: setTracks,
			activeTrackName,
			setActiveTrackName,
			audioSettings,
			audioEnabled,
			setAudioEnabled,
		}
	}, [
		room,
		speakerOrder,
		tracks,
		setTracks,
		activeTrackName,
		setActiveTrackName,
		audioSettings,
		audioEnabled,
		setAudioEnabled,
	])

	/* finds all active speakers ordered by beginning speaking time
	 * When speaker becomes inactive, decrement a counter until they hit zero
	 * If they speak in that time, restore the counter to SPEAKING_PAUSE
	 */
	useInterval(
		() => {
			if (room) {
				room
					.getStats()
					.then(stats => {
						const updatedSpeakerStrengths = []
						const curSpeakerOrder = [...speakerOrder]
						stats &&
							stats[0].remoteAudioTrackStats.forEach(remoteAudioStat => {
								// Chromium double multiplies all audio levels by 32767: https://github.com/twilio/twilio-video.js/issues/1228
								const audioLevel =
									remoteAudioStat.audioLevel > 32766
										? remoteAudioStat.audioLevel / 32767
										: remoteAudioStat.audioLevel
								// Minimum unmuted audio level is 1, so move it down to 0 for easier mathematics
								const speakingStrength = clamp((audioLevel - 1) / STANDARD_SPEAKING_VOLUME, 0, 1)

								const studentTrackId = remoteAudioStat.trackSid
								const speakerId = trackIdToParticipantId.current.has(studentTrackId)
									? trackIdToParticipantId.current.get(studentTrackId)
									: undefined

								if (speakerId) {
									// Add item to update speakerStrength after updating speaker order
									updatedSpeakerStrengths.push({
										id: speakerId,
										speakingStrength: speakingStrength,
									})
									const speakerIndex = curSpeakerOrder.findIndex(
										speaker => speaker.id === speakerId
									)
									if (speakingStrength <= 0 && speakerIndex > -1) {
										// Speaker isn't speaking, decrement counter by 1
										curSpeakerOrder[speakerIndex] = {
											id: speakerId,
											counter: curSpeakerOrder[speakerIndex].counter - 1,
										}
										if (curSpeakerOrder[speakerIndex].counter <= 0) {
											// Remove speaker from speakerOrder
											curSpeakerOrder.splice(speakerIndex, 1)
										}
									} else if (speakingStrength > 0) {
										if (speakerIndex === -1) {
											// Add new speaker to speakerOrder
											curSpeakerOrder.push({ id: speakerId, counter: SPEAKING_PAUSE })
										} else {
											// Speaker is active, set counter back to max
											curSpeakerOrder[speakerIndex] = { id: speakerId, counter: SPEAKING_PAUSE }
										}
									}
								}
							})
						setSpeakerOrder(curSpeakerOrder)
						// batch update speakingStrength
						updatedSpeakerStrengths.forEach(updated => {
							applyChangeTrack(setTracks, updated.id, {
								speakingStrength: updated.speakingStrength,
							})
						})
					})
					.catch(e => {
						console.error('Failed to get stats', e.message)
					})
			}
		},
		room ? SPEAKER_REFRESH_RATE : null
	)

	const isMounted = useRef<boolean>(true)
	// make component as unmounted when it unmounts
	useEffect(
		() => () => {
			isMounted.current = false
		},
		[]
	)

	// get token for mission
	useEffect(() => {
		// if we do not want to use twilio, don't get a token
		if (!config.useTwilio) {
			return
		}
		if (
			isRemote &&
			audioEnabled &&
			missionServerUrl &&
			!token &&
			!missionEnded &&
			missionCode &&
			studentId
		) {
			axios
				.get(`${missionServerUrl}/api/videochat/student/token/${missionCode}/${studentId}`)
				.then(response => {
					if (isMounted.current) {
						setToken(response.data.token)
					}
				})
				.catch(error => {
					console.error(`Failed to acquire videochat token ${error}`)
				})
		}
		return
	}, [isRemote, audioEnabled, token, missionCode, studentId, missionEnded, missionServerUrl])

	// join the room when the video chat token is calculated and disconnect when the selected mission becomes undetermined
	useEffect(() => {
		if (token && missionCode) {
			joinRoom(setRoom, setTracks, trackIdToParticipantId, missionCode, studentIds, token)
			// initiate audio analyser function
			const analyseFunctionInterval = window.setInterval(analyseFunction, AUDIO_REFRESH_RATE)
			// disconnect from room when selectedMissionId or missionCode changes
			return () => {
				disconnectFromRoom(setRoom, setTracks)
				setToken(undefined)
				// end audio analyser
				window.clearInterval(analyseFunctionInterval)
			}
		}
	}, [token, missionCode, studentIds])

	// mute / unmute self according to student isMuted status
	useEffect(() => {
		if (isMuted && localAudioTrack && localAudioTrack.track.isEnabled) {
			localAudioTrack.track.disable()
		} else if (!isMuted && localAudioTrack && !localAudioTrack.track.isEnabled) {
			localAudioTrack.track.enable()
		}
	}, [isMuted, localAudioTrack])

	// If we connect and there is no local track, try finding a default
	useEffect(() => {
		if (room && !getFirstLocalAudioTrack(room) && audioEnabled && navigator.mediaDevices) {
			if (isMounted && !getFirstLocalAudioTrack(room)) {
				_setAudio(room, audioSettings, setActiveTrackName)
			}
		}
	}, [room, audioEnabled, setActiveTrackName, audioSettings])

	return (
		<RoomControlsContext.Provider value={roomControls}>
			{getTrackEntries(tracks)
				.filter(([participantId, participant]) => participant.connected)
				.map(([participantId, participant]) => (
					<ParticipantAudio key={participantId} id={participantId} />
				))}
			{props.children}
		</RoomControlsContext.Provider>
	)
}
