import React from 'react'
import { useDragLayer } from 'react-dnd'
import type { Point } from '../types'

type Line<T> = [T, Point]

type Props<T> = {
	start: ?T,
	renderLine: (Line<T>) => React$Node,
}

/**
 * Display a line based on the current drag state. This line will go from the
 * passed in start, and the currentOffset (current drag location)
 */
export default function LineDragLayer<T>(props: Props<T>): React$Node {
	const { currentOffset } = useDragLayer(monitor => {
		return {
			currentOffset: monitor.getClientOffset(),
		}
	})

	const { start, renderLine } = props

	if (!start || !currentOffset) {
		return null
	}

	return <svg>{renderLine([start, [currentOffset.x, currentOffset.y]])}</svg>
}
