import { useEffect, useState } from 'react'
import uuid from 'uuid/v4'

export const WEBSOCKET_STATUS = {
	CONNECTED: 'CONNECTED', // the websocket has an open connection to the mission-server
	CONNECTING: 'CONNECTING', // a websocket is created and is currently trying to connect to the mission server
	RETRY_AT: 'RETRY_AT', // failed to connect but will retry at the given time
	DISCONNECTED: 'DISCONNECTED', // the websocket has disconnected from the mission-server (or was never connected in the first place) and will not try to reconnect
	GAVE_UP: 'GAVE_UP', // the websocket gave up trying to reconnect to the mission-server
}

export type WebsocketStatusObject =
	| {
			status: 'CONNECTED',
			missionCode: string,
			connectedTimesBefore: number, // the number of times a websocket successfully opened and connected to the mission-server
	  }
	| {
			status: 'DISCONNECTED',
			missionCode?: string,
			connectedTimesBefore: number,
	  }
	| {|
			status: 'RETRY_AT',
			missionCode: string,
			attempt: number,
			retryAt: Date,
			connectedTimesBefore: number,
	  |}
	| {
			status: 'CONNECTING',
			missionCode: string,
			attempt: number,
			connectedTimesBefore: number,
	  }
	| {
			status: 'GAVE_UP',
			missionCode: string,
			afterAttempts: number,
			connectedTimesBefore: number,
	  }

let currentWebsocketStatus: WebsocketStatusObject = {
	status: WEBSOCKET_STATUS.DISCONNECTED,
	connectedTimesBefore: 0,
}

/**
 * setWebsocketStatus - a function used to set the websocket status and update all react components dependent on it
 *
 * @param {WebsocketStatusObject} newWebsocketStatus - the updated status of the websocket
 *
 */
export function setWebsocketStatus(newWebsocketStatus: WebsocketStatusObject) {
	currentWebsocketStatus = newWebsocketStatus
	listeners.forEach(callback => callback(newWebsocketStatus))
}

type StatusListenerCallback = (newWebsocketStatus: WebsocketStatusObject) => any
const listeners: Map<
	// listenerId
	string,
	StatusListenerCallback
> = new Map()

/**
 * getWebsocketStatus - get the current websocket status, use `useWebsocketStatus` if you are in a react component.
 *
 * @return {WebsocketStatusObject} - the current websocket status
 */
export function getWebsocketStatus(): WebsocketStatusObject {
	return currentWebsocketStatus
}

/**
 * registerWebsocketStatusListener - register a function to be called when the status of the websocket it updated
 *
 * @param {(newWebsocketStatus: WebsocketStatusObject) => any} listener - the callback to call when the websocket status is updated
 *
 * @return {string} the id the listener was registered as. Call `removeWebsocketStatusListener` to stop listening for websocket changes.
 */
function registerWebsocketStatusListener(
	listener: (newWebsocketStatus: WebsocketStatusObject) => any
): string {
	const id = uuid()
	listeners.set(id, listener)
	return id
}

/**
 * removeWebsocketStatusListener - stop the listener with the listenerId from receiving updates about the websocket status
 *
 * @param {string} listenerId - the id of the lister to remove
 *
 */
function removeWebsocketStatusListener(listenerId: string) {
	listeners.delete(listenerId)
}

/**
 * useWebsocketStatus - react hook which returns the current websocket status (this updates whenever the websocket status updates)
 *
 * @return {WebsocketStatusObject} the websocket status
 */
export function useWebsocketStatus(): WebsocketStatusObject {
	const [websocketStatus, setWebsocketStatus] = useState(currentWebsocketStatus)
	useEffect(() => {
		const listenerId = registerWebsocketStatusListener(setWebsocketStatus)
		return () => {
			removeWebsocketStatusListener(listenerId)
		}
	}, [])
	return websocketStatus
}

export const TEST_ONLY = {
	/**
	 * resetWebsocketStatusGlobals - removes all websocket status listeners, should only be used in tests as this would cause
	 * react to no longer know about updates to the websocket status.
	 *
	 */
	resetWebsocketStatusGlobals() {
		listeners.clear()
	},
}
