import React from 'react'
import styled from 'styled-components/macro'
import type { FullStateLiteracyEventStudentTaskStateSpeaking } from '@mission.io/mission-toolkit'
import type { LiteracyEventSpeakingTask } from '@mission.io/mission-toolkit/actions'
import Markdown from '../../Markdown'
import { FaHourglass } from 'react-icons/fa'
import { Button } from '../../basics'
import { useMarkdownOverridesForSpeakingTask } from '../hooks/markdownOverrides'
import { DivWithStyledScroll } from './DivWithStyledScroll'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { TimeLeftNumbersOnly } from '../../DeltaClock/TimeLeft'

/**
 * A component that displays a speaking prompt for a literacy event.
 * @param {{}} props
 * @param {LiteracyEventTask} props.task - The speaking task for the literacy event
 * @param {() => void} props.onSubmit - A callback to be called when the student submits the speaking task
 * @returns {React$Node}
 */
export function SpeakingPromptOption({
	task,
	onSubmit,
	submitButtonText = 'Done',
}: {
	task: {
		instanceId: string,
		studentState: FullStateLiteracyEventStudentTaskStateSpeaking,
		task: LiteracyEventSpeakingTask<string>,
		type: 'SPEAKING',
	},
	submitButtonText?: string,
	onSubmit: () => void,
}): React$Node {
	const taskText = task.task.prompt
	const canSubmit =
		task.studentState.status.type ===
			LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.STATUS.WAITING_FOR_FINISH_MESSAGE ||
		// this can occur if the task was not started properly, allow submitting as this indicates a softlock
		task.studentState.status.type ===
			LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.STATUS.NOT_YET_STARTED ||
		// fallback for if the student refreshes their page before the dismiss message is sent
		task.studentState.status.type ===
			LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.STATUS.WAITING_FOR_DISMISS_MESSAGE
	const { Wrapper, wrapperProps, markdownOverrides } = useMarkdownOverridesForSpeakingTask(
		task.studentState.talkTo
	)
	return (
		<>
			<Container className="max-w-xl">
				<Wrapper {...wrapperProps}>
					<Markdown {...markdownOverrides} disabledComponents={['a', 'img']}>
						{taskText}
					</Markdown>
				</Wrapper>
			</Container>
			<div className="absolute left-full h-fit top-1/2 -translate-y-1/2 pl-3">
				{task.studentState.status.type ===
					LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.STATUS.WAITING_FOR_TIMER_TO_FINISH && (
					<div className="absolute text-white top-0 -translate-y-full p-3 left-0 font-[Orbitron,sans-serif] flex items-center gap-3">
						<FaHourglass />
						<TimeLeftNumbersOnly timerId={task.studentState.status.timerId} />
					</div>
				)}
				<Button
					className="relative"
					disabled={!canSubmit}
					onClick={e => {
						e.stopPropagation()
						onSubmit()
					}}>
					{submitButtonText}
				</Button>
			</div>
		</>
	)
}

const Container = styled(DivWithStyledScroll)`
	padding: var(--spacing);
	width: fit-content;
	height: 100%;
	margin: auto;
	text-align: center;
`

export default SpeakingPromptOption
