import React, { useState, useEffect } from 'react'
import { Alerts } from './Alerts'
import Room from './Room'
import BaseRobot from './Robot'
import { mapObject } from '../../../utility/functions'
import { getWeight, getDistance } from '../helpers'
import { SHIP_CONTAINER_ID } from '../constants'
import { useSpring, animated } from '@react-spring/web'
import { getRooms } from '../../../store/stores/staticData'
import { withSize } from 'react-sizeme'
import { ShipDimensionContext } from '../context'
import BackgroundShadow from './BackgroundShadow'

import {
	getRobotPreciseLocation,
	getTravellingPathForDecks,
	getActiveAlerts,
	isRobotTravelling,
	getCurrentRoomIdOfRobot,
	getTravelingTimer,
} from '../../../store/selectors/jrPlusState/decks'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import type { Coordinates as Point } from '../../../types'
import type { Room as RoomType } from '../../../types'
import { SHIP_WIDTH, SHIP_HEIGHT } from '../constants'
import { useAdvanceTraining } from '../../../store/selectors/jrPlusState/training'
import { sendMessage } from '../../../websockets/websocket'

const Robot = animated(BaseRobot)

/**
 * Do not apply padding or margin to this component. Logic used to determine the locations of alerts in alignment with the rooms
 * depends on the exact dimensions of the ship.
 */
const ShipWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`
/**
 * Do not apply padding or margin to this component. Logic used to determine the locations of alerts in alignment with the rooms
 * depends on the exact dimensions of the ship.
 */
const ShipSVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: visible;
`

type Props = {
	size: {
		width: number,
		height: number,
	},
}

function ShipOverview({ size: { width, height } }: Props) {
	// needs when moving
	const travellingPath = useSelector(getTravellingPathForDecks)

	const ROOMS = useSelector(getRooms)
	const alerts = useSelector(getActiveAlerts)
	const isRobotReallyTravelling = useSelector(isRobotTravelling)
	const robotRoomId = useSelector(getCurrentRoomIdOfRobot)
	const travelingTimer = useSelector(getTravelingTimer)

	// needed when stationary
	const preciseLocation = useSelector(getRobotPreciseLocation)

	const advanceTraining = useAdvanceTraining()

	const [shouldAnimationReset, setShouldAnimationReset] = useState(false)
	const [robotSelected, setRobotSelected] = useState(false)
	const [canSelectRoom, setCanSelectRoom] = useState(false)

	useEffect(() => {
		if (robotSelected && !isRobotReallyTravelling) setCanSelectRoom(true)
	}, [robotSelected, isRobotReallyTravelling, setCanSelectRoom])

	const selectRoom = (roomId: string) => {
		if (canSelectRoom && roomId !== robotRoomId) {
			sendMessage('SELECT_ROOM_ON_DECK_MSG', { roomId: roomId })
			setCanSelectRoom(false)
			if (advanceTraining) {
				advanceTraining()
			}
		}
	}

	const selectRobot = () => {
		setRobotSelected(true)
		if (!isRobotReallyTravelling) {
			setCanSelectRoom(true)
		}
		if (advanceTraining) {
			advanceTraining()
		}
	}

	const springProps = useSpring({
		config: travelingTimer ? { duration: travelingTimer.delayLeft } : {},
		from: { progress: travelingTimer ? travelingTimer.currentProgress : 0 },
		to: { progress: 1 },
		reset: shouldAnimationReset,
	})

	useEffect(
		() => {
			setShouldAnimationReset(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		travelingTimer
			? [
					travelingTimer,
					travelingTimer.delayLeft,
					travelingTimer.originalDelay,
					travelingTimer.isPaused,
					travelingTimer.previousProgress,
			  ]
			: [travelingTimer, null, null, null, null]
	)

	useEffect(() => {
		if (!travelingTimer) {
			setRobotSelected(false)
			setCanSelectRoom(false)
		}
	}, [travelingTimer])

	useEffect(() => {
		if (shouldAnimationReset) {
			setShouldAnimationReset(false)
		}
	}, [shouldAnimationReset])

	return (
		<ShipWrapper>
			<ShipDimensionContext.Provider
				value={{
					svgWidth: SHIP_WIDTH,
					svgHeight: SHIP_HEIGHT,
					screenWidth: width,
					screenHeight: height,
				}}>
				<ShipSVG
					id={SHIP_CONTAINER_ID}
					xmlns="http://www.w3.org/2000/svg"
					viewBox={`0 0 ${SHIP_WIDTH} ${SHIP_HEIGHT}`}>
					<defs></defs>
					<BackgroundShadow />
					<path
						css="fill: var(--color-on-base);"
						d="M340.49,517.5l10,85,24,83,46,75,54,66,70,55,78,42,91,24,92,12S1257,907,1326,893s96.68-15.43,138.5-34.5c62.5-28.5,87-196,91-232s4-272-19-356-113-121-113-121l-624-73-91,12-82,22-79,41-69,53-59,69-40,78-26,78ZM1241,755l57-68,10-23h14l2.5,5.5,16,1,31-27s6-32,6-34,2-149,2-149-4-58-7-68l-31-25h-17l-2,4h-15l-9-21-53-71,32-10,68-33,49,2,27,18,8,17v22a340.76,340.76,0,0,0-8,52c-2,28-7,104-7,104v130l3,63s5,56,6,62S1432,761,1432,761l-4,13-32,18h-49Z"
						transform="translate(-340.49 -76.5)"
						name="ship"
					/>
					{mapObject(ROOMS, (room: RoomType, roomId) => (
						<Room
							{...room}
							roomId={roomId}
							key={roomId}
							hasAlert={alerts.some(
								alert => alert.roomId === roomId && !alert.fixed && !alert.failed
							)}
							onClick={() => selectRoom(roomId)}
							selectable={canSelectRoom && robotRoomId !== roomId}
						/>
					))}
					<Robot
						location={
							isRobotReallyTravelling
								? springProps.progress.to(progress => {
										return getTravelingLocation(progress, travellingPath)
								  })
								: { x: preciseLocation.x, y: preciseLocation.y }
						}
						selectRobot={() => selectRobot()}
						isMoving={isRobotReallyTravelling}
						selected={robotSelected}
					/>
				</ShipSVG>
				<Alerts />
			</ShipDimensionContext.Provider>
		</ShipWrapper>
	)
}

function getTravelingLocation(progress: number, path: ?(Point[])): { x: number, y: number } {
	if (!path || path.length < 2) {
		return { x: 0, y: 0 }
	}
	let currentDistance = getWeight(path) * progress
	let previous = path[0]
	for (let i = 1; i < path.length; i++) {
		let current = path[i]
		let distance = getDistance(previous, current)
		if (distance > currentDistance) {
			let proportion = currentDistance / distance
			return {
				x: (current.x - previous.x) * proportion + previous.x,
				y: (current.y - previous.y) * proportion + previous.y,
			}
		}
		currentDistance -= distance
		previous = current
	}
	let end = path[path.length - 1]
	return {
		x: end.x,
		y: end.y,
	}
}

export default (withSize({ monitorWidth: true, monitorHeight: true })(ShipOverview): (
	props: $Shape<{||}>
) => React$Node)
