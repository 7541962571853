type RequestHandledCallback = (success: boolean) => void

export const requestHandledCallbackContainer: { [actionId: string]: RequestHandledCallback } = {}

/**
 * onRequestHandled - add a callback which will occur once a given request is handled by the server
 *
 * @param {string} requestId - the id of the request to add the callback for
 * @param {ActionHandledCallback} callback - a callback to call once the server responds saying that it handled the request
 *
 * @return {void}
 */
export function onRequestHandled(requestId: string, callback: RequestHandledCallback): void {
	requestHandledCallbackContainer[requestId] = callback
}

/**
 * removeOnRequestHandledHandler - remove the actionHandled handler tied to a requestId
 *
 * @param {string} requestId - the id of the request to remove handlers for
 *
 * @return {void}
 */
export function removeOnRequestHandledHandler(requestId: string): void {
	delete requestHandledCallbackContainer[requestId]
}
