import React, { lazy, Suspense, useEffect, useCallback } from 'react'
import fscreen from 'fscreen'
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import config from './config'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import {
	isTraining as getIsTraining,
	isLoggingIn as getIsLoggingIn,
	getMissionType,
	isMissionComplete,
} from './store/stores/general'
import { isMobileOnly } from 'react-device-detect'
import GlobalStyle from './globalStyle'
import { theme as studentTheme } from './constants/styles'
import HackerMode from './HackerMode'
import LoginDisplay from './components/LoginDisplay'
import { FramedStationContent } from './components/Frame'
import GoFullHeader from './components/GoFullHeader'
import { RoomControlsProvider } from './videoChat/RoomControls'
import { getMissionCode, isRemote as isRemoteSelector } from './store/stores/staticData'
import { PendingStudentsModal } from './components/Controller/Dialogs/PendingStudents'

import './main.css'
import { SetupScreenExtension } from './components/Controller/Dialogs/SetupScreenExtension'
import { MAIN_ROOT_ID, MODAL_APP_ELEMENT_FOR_OUTER_FRAME } from './components/basics/ReactModal'
import { CreativeCanvasDocumentProvider } from './components/CreativeCanvas/connect/CreativeCanvasDocumentContext.jsx'
import classnames from 'classnames'
import { sendMessage } from './websockets/websocket'
import ConnectionStatus from './components/ConnectionStatus'
import Loading from './components/basics/Loading.jsx'
import { useWebsocketNetworkCheckPings } from './websockets/networkHealth'

const TeacherGuide = lazy(() => import('./components/Controller/TeacherGuide/TeacherGuide'))

const KeyCodes = {
	D: 68,
	H: 72,
}

type Props = {
	isController?: boolean,
}

const ALLOW_HACKS = config.isDev
function Main({ isController }: Props): React$Node {
	const [isFull, setIsFull] = React.useState(false)
	const dKeyDown = React.useRef(false)
	const [hackerMode, setHackerMode] = React.useState(false)

	useWebsocketNetworkCheckPings(
		// the mission-server will not respond while generating analytics, so stop monitoring during that time
		!useSelector(isMissionComplete)
	)

	const node = React.useRef(null)
	const goFull = useCallback(() => {
		if (node.current) {
			fscreen.requestFullscreen(node.current)
		}
	}, [])
	const toggleHackerMode = () => {
		setHackerMode(_hackerMode => {
			if (_hackerMode) {
				// $FlowFixMe we know that classList will be there
				document.body.classList.remove('hacker-mode')
				return false
			} else {
				// $FlowFixMe we know that classList will be there
				document.body.classList.add('hacker-mode')
				return true
			}
		})
	}
	const isTraining = useSelector(getIsTraining)
	const isLoggingIn = useSelector(getIsLoggingIn)
	const missionType = useSelector(getMissionType)
	const isRemote = useSelector(isRemoteSelector)
	const dispatch = useDispatch()

	// on mount and unmount of the component
	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent) => {
			if (event.ctrlKey && event.keyCode === KeyCodes.D) {
				event.preventDefault()
				dKeyDown.current = true
			} else if (event.ctrlKey && event.keyCode === KeyCodes.H && dKeyDown.current) {
				event.preventDefault()
				toggleHackerMode()
			}
		}
		const fullScreenHandler = (event: KeyboardEvent) => {
			setIsFull(state => !state)
		}
		const keyUpHandler = (event: KeyboardEvent) => {
			if (event.keyCode === KeyCodes.D) {
				dKeyDown.current = false
			}
		}
		const handleContextMenu = (event: MouseEvent) => {
			event.preventDefault()
			event.stopPropagation()
			return false
		}
		const handleFocusChange = (event: FocusEvent) => {
			sendMessage('WINDOW_FOCUS_CHANGE', { isFocused: event.type === 'focus' })
		}
		if (ALLOW_HACKS) {
			document.addEventListener('keydown', keyDownHandler)
			document.addEventListener('keyup', keyUpHandler)
		}
		document.addEventListener('contextmenu', handleContextMenu)
		fscreen.addEventListener('fullscreenchange', fullScreenHandler)
		window.addEventListener('focus', handleFocusChange)
		window.addEventListener('blur', handleFocusChange)
		return () => {
			if (ALLOW_HACKS) {
				document.removeEventListener('keydown', keyDownHandler)
				document.removeEventListener('keyup', keyUpHandler)
			}
			document.removeEventListener('contextmenu', handleContextMenu)
			fscreen.removeEventListener('fullscreenchange', fullScreenHandler)
			window.removeEventListener('focus', handleFocusChange)
			window.removeEventListener('blur', handleFocusChange)
		}
	}, [dispatch])

	if (!missionType) {
		// Waiting for first update from the server
		return <LoadingPage message="Connecting To Your Mission" />
	} else {
		const canGoFullScreen = !isFull && !isTraining
		const LoadedAppContent = hackerMode ? (
			<HackerMode />
		) : (
			<div ref={node} id={MAIN_ROOT_ID} className="h-full">
				{isMobileOnly && isController && (
					<div
						className={classnames(
							'hidden landscape:flex inset-center text-white z-[1010] h-full w-full bg-primary-800/90 rounded px-10 items-center'
						)}>
						This experience is designed to be viewed in portrait. Please rotate your device to view
						the site.
					</div>
				)}
				<div className="h-full" id={MODAL_APP_ELEMENT_FOR_OUTER_FRAME}>
					{isController && (
						<>
							<PendingStudentsModal />
							<SetupScreenExtension />
						</>
					)}
					{isLoggingIn ? (
						<LoginDisplay
							isController={isController}
							goFullScreen={canGoFullScreen ? goFull : null}
						/>
					) : (
						<>
							{canGoFullScreen && <GoFullHeader goFull={goFull} />}
							{isController ? (
								<Suspense fallback={<LoadingPage message="Launching Teacher Station" />}>
									<TeacherGuide className="flex-1" missionType={missionType} />
								</Suspense>
							) : (
								<FramedStationContent missionType={missionType} />
							)}
						</>
					)}
				</div>
			</div>
		)

		return (
			<>
				<ConnectionStatus />
				{isRemote ? (
					<RoomControlsProvider>{LoadedAppContent}</RoomControlsProvider>
				) : (
					LoadedAppContent
				)}
			</>
		)
	}
}

const WithSetup = ({
	children,
	isController,
}: {
	children?: React$Node,
	isController?: boolean,
}): React$Node => {
	const missionCode = useSelector(getMissionCode)

	useEffect(() => {
		if (missionCode) {
			document.title = `${config.companyName} - ${missionCode}`
		}
	}, [missionCode])

	// Prevent zooming on touch devices
	React.useEffect(() => {
		const preventGesture = (event: TouchEvent) => event.preventDefault()
		// $FlowFixMe[incompatible-call]
		document.addEventListener('gesturestart', preventGesture)
		// $FlowFixMe[incompatible-call]
		document.addEventListener('gesturechange', preventGesture)
		return () => {
			// $FlowFixMe[incompatible-call]
			document.removeEventListener('gesturestart', preventGesture)
			// $FlowFixMe[incompatible-call]
			document.removeEventListener('gesturechange', preventGesture)
		}
	}, [])

	return (
		<CreativeCanvasDocumentProvider>
			<DndProvider options={HTML5toTouch}>
				<ThemeProvider theme={studentTheme}>
					<GlobalStyle />
					<Main isController={isController}>{children}</Main>
				</ThemeProvider>
			</DndProvider>
		</CreativeCanvasDocumentProvider>
	)
}

/**
 * LoadingPage - a component that takes up the whole page to show a loading status
 *
 * @param {Object} props - the react props
 * @param {string} props.message - a message shown to the user which describes what is being loaded
 *
 * @return React$Node
 */
function LoadingPage({ message }: { message: string }): React$Node {
	return (
		<div
			className={classnames(
				'h-full w-full bg-gradient-to-r from-[#0d3a58] to-[#3d357d] flex flex-col items-center justify-center'
			)}>
			<ConnectionStatus />
			<div>
				<Loading className="max-w-40" />
				<div className="text-white font-bold text-lg">{message}</div>
			</div>
		</div>
	)
}

export default WithSetup
