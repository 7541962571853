import React from 'react'
import styled from 'styled-components/macro'

/**
 * A decorative svg design that appears on the left side of the extender frame when in the horizontal view for a literacy event.
 * @returns {React$Node}
 */
export function DecalExtenderFrame({
	contentRef,
}: {
	contentRef: { current?: HTMLDivElement | null },
}): React$Node {
	// In small screens svg lines will be drawn vertically instead of horizontally.
	const smallScreen = window.innerWidth < 768
	const containerWidth = contentRef.current?.clientWidth || 200
	const containerHeight = contentRef.current?.clientHeight || 200
	const topLineStart = Math.ceil((smallScreen ? containerHeight : containerWidth) * 0.8)
	const bottomLineStart = Math.ceil((smallScreen ? containerHeight : containerWidth) * 0.4)

	return (
		<FillerDiv>
			<AbsoluteDiv>
				<svg viewBox="0 0 10 10">
					<path
						d={smallScreen ? 'M0 0 L10 0 L10 10 Z' : 'M0 0 L10 10 L0 10 L0 0 Z'}
						fill="#25346C"
					/>
					<path
						d={
							smallScreen
								? `M13 10 13 ${topLineStart} L25 ${topLineStart + 10}`
								: `M10 13 ${topLineStart} 13 L${topLineStart + 10} 25`
						}
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
				</svg>
				<div className="grow md:w-2.5 md:h-fit h-2.5 bg-[#25346c] z-[1]"></div>
				<svg viewBox="0 0 10 10">
					<path d={'M0 0 L10 0 L0 10 L0 0 Z'} fill="#25346C" />
					<path
						d={
							smallScreen
								? `M-3 10 L-3 ${bottomLineStart} L-15 ${bottomLineStart + 10}`
								: `M10 -3 L${bottomLineStart} -3 L${bottomLineStart + 10} -15`
						}
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
					<circle
						cx={smallScreen ? -6 : bottomLineStart + 18}
						cy={smallScreen ? bottomLineStart + 18 : -6}
						r="5"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
					<circle
						cx={smallScreen ? -6 : bottomLineStart + 36}
						cy={smallScreen ? bottomLineStart + 36 : -6}
						r="5"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
				</svg>
			</AbsoluteDiv>
		</FillerDiv>
	)
}

const FillerDiv = styled.div`
	align-self: stretch;
	position: relative;
	height: 10px;
	svg {
		height: 10px;
		overflow: visible;
	}
	@media (min-width: 768px) {
		width: 10px;
		height: auto;
		svg {
			width: 10px;
			height: auto;
		}
	}
`

const AbsoluteDiv = styled.div`
	position: absolute;
	display: flex;
	@media (min-width: 768px) {
		top: -24px;
		left: 0;
		flex-direction: column;
		height: calc(100% + 48px);
		width: fit-content;
	}
	left: -24px;
	flex-direction: row;
	width: calc(100% + 48px);
	height: fit-content;
`
