import React from 'react'
import { useSelector } from 'react-redux'
import {
	getReviewCulminatingMomentAnswers,
	getStudentAnswersToQuestions,
} from '../../store/selectors/sharedSelectors'
import { getMissionType } from '../../store/stores/general'
import styled from 'styled-components/macro'
import { ReactModal, CloseButton } from '../basics'
import { DARKEST_SHADE } from '../../constants/styles'
import type { FormattedResponse } from '../../store/selectors/sharedSelectors'
import { QuestionFlipper } from './QuestionReview'

const customStyles = () => {
	return {
		content: {
			outline: 'none',
			overflowY: 'auto',
			background: '#5B5889',
			borderRadius: '16px',
			boxShadow: `0 0 1vh ${DARKEST_SHADE}`,
			border: 'none',
			padding: '24px',
			width: '75vw',
			fontSize: '16px',
			color: 'white',
		},
		overlay: {
			backgroundColor: 'fade(#00000D, 70%)',
		},
	}
}
const UnstyledHeader = styled.h2.attrs({ className: 'font-game-body' })`
	margin: 0;
	font-weight: normal;
	letter-spacing: normal;
	text-transform: initial;
`

/**
 * Modal to display when a category is selected
 * @returns {React$Node}
 */
export default function CategoryModal({
	onClose,
	open,
	children,
}: {
	onClose: () => void,
	open: boolean,
	children: React$Node,
}): React$Node {
	return (
		open && (
			<ReactModal
				frameContent
				isOpen={true}
				style={customStyles()}
				onClose={onClose}
				onRequestClose={onClose}>
				<CloseButton css="width: 22px; height: 22px;" onClick={onClose} data-testid="close-modal" />
				{children}
			</ReactModal>
		)
	)
}

/**
 * Content for a modal a basic category modal
 * @returns {React$Node}
 */
export function BasicModalContent({
	suggestions,
	title,
}: {
	suggestions: { 'K-3': string[], '4+': string[] },
	title: string,
}): React$Node {
	const controlSet = useSelector(getMissionType) || '4+'
	return (
		<div>
			<UnstyledHeader>{title}</UnstyledHeader>

			<BackgroundHighlight style={{ padding: '16px', textAlign: 'left', marginTop: '24px' }}>
				{suggestions[controlSet]?.length > 0 ? (
					<ul css="margin: 0;">
						{suggestions[controlSet].map(item => (
							<li css="&:not(:last-child) {margin-bottom: 8px;}" key={item}>
								{item}
							</li>
						))}
					</ul>
				) : (
					<div css="text-align: center; width: 100%;">No suggestions at this time.</div>
				)}
			</BackgroundHighlight>
		</div>
	)
}

const BackgroundHighlight = styled.div`
	background: #817abc;
	border-radius: 4px;
	padding: 8px;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: #d4d1ed;
		border-radius: 20px;
	}
`

/**
 * Count all the questions that a student answered correctly.
 */
function getNumberOfQuestionsAnsweredCorrectly(
	allStudentResponses: Array<FormattedResponse[]>
): number {
	let totalCorrectAnswers = 0

	allStudentResponses.forEach(responses => {
		// only count first attempt
		const response = responses[0]
		if (response?.score && response?.score?.score === response?.score?.perfectScore) {
			totalCorrectAnswers++
		}
	})
	return totalCorrectAnswers
}

/**
 * A wrapper for both application and question modal content
 * @param {{score: number, outOf: number, children: React$Node}} param0
 * @returns
 */
const QuestionModalContentWrapper = ({
	score,
	outOf,
	type,
	children,
}: {
	score: number,
	outOf: number,
	type: 'Application' | 'Knowledge',
	children: React$Node,
}) => {
	return (
		<div css="padding: 8px;">
			<div css="display: flex;">
				<div css="flex: 1; text-align: left;">
					<BackgroundHighlight as="span">
						{score}/{outOf} Correct
					</BackgroundHighlight>
				</div>
				<UnstyledHeader>{type} Score</UnstyledHeader>
				<div css="flex: 1" />
			</div>
			<BackgroundHighlight
				style={{ padding: '16px', marginTop: '24px', overflow: 'auto', maxHeight: '40vh' }}>
				{children}
			</BackgroundHighlight>
		</div>
	)
}

/**
 * Content for the modal that students will use to review their question responses
 * @returns {React$Node}
 */
export function QuestionReviewModalContent(): React$Node {
	const studentAnswers = useSelector(getStudentAnswersToQuestions)

	const totalQuestions = studentAnswers.length
	const totalCorrectAnswers = getNumberOfQuestionsAnsweredCorrectly(studentAnswers)

	return (
		<QuestionModalContentWrapper
			score={totalCorrectAnswers}
			outOf={totalQuestions}
			type="Knowledge">
			{studentAnswers.length === 0 ? (
				'No questions available'
			) : (
				<div css="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px;">
					{studentAnswers.map((questionResponses, questionIndex) => (
						<QuestionFlipper
							responses={questionResponses}
							questionIndex={questionIndex}
							key={questionIndex}
						/>
					))}
				</div>
			)}
		</QuestionModalContentWrapper>
	)
}

/**
 * Content for the modal that students will use to review their culminating moment responses
 * @returns {React$Node}
 */
export function CMReviewModalContent(): React$Node {
	const questionsAndAnswers = useSelector(getReviewCulminatingMomentAnswers)
	const totalQuestions = questionsAndAnswers.length
	const totalCorrectAnswers = questionsAndAnswers.reduce(
		(total, responses) => total + (responses[0]?.score?.score ?? 0),
		0
	)

	return (
		<QuestionModalContentWrapper
			score={totalCorrectAnswers}
			outOf={totalQuestions}
			type="Application">
			{questionsAndAnswers.length === 0 ? (
				'No data available'
			) : (
				<div css="margin: 0; list-style-position: inside;">
					{questionsAndAnswers.map((responses, questionIndex) => (
						<QuestionFlipper
							responses={responses}
							questionIndex={questionIndex}
							key={questionIndex}
						/>
					))}
				</div>
			)}
		</QuestionModalContentWrapper>
	)
}
