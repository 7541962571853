// @flow
import React, { type AbstractComponent } from 'react'
import classnames from 'classnames'
import type { StudentSpecificContextualDataEntry } from '@mission.io/mission-toolkit'

import TextToSpeech from '../TextToSpeech/index.jsx'

import { SvgIcon } from '../../juniorPlusStations/Sensor/Map/Map.jsx'

import {} from '../LiteracyEvent/helperComponents/ContentExpander.jsx'
import { Voting } from './Voting.jsx'
import { useOpenExpandedData, type ContextualDataLookup } from './ExpandedDataProvider.jsx'
import { sendMessage } from '../../websockets/websocket'

export const GRID_AREA_KEY_WORDS = {
	vote: 'vote',
	importance: 'importance',
}

export type MediaModalOverrides = {
	overlayStyle: {| zIndex: number |},
	frameContent: boolean,
}

type Props = {
	className?: string,
	style?: { [string]: mixed },
	allowVote?: boolean,
	contextualData: StudentSpecificContextualDataEntry,
	onHover?: () => void,
	onBlur?: () => void,
	smallScreenSize?: string,
	lookup: ContextualDataLookup,

	mediaModalOverrides?: ?MediaModalOverrides,
}

// The ContextualDataCard component displays a single piece of contextual data. It includes the data, the current class and student vote, and a way to view any media.
// It can trigger the expanded data view when clicked. IMPORTANT: If this component is not rendered withing the ExpandedDataProvider context, the expanded data view will not appear.
export const ContextualDataCard = (React.forwardRef(function ContextualDataCard(
	{
		className,
		contextualData,
		lookup,
		onHover,
		onBlur,
		smallScreenSize,
		mediaModalOverrides,
		allowVote = true,
		...props
	}: Props,
	ref
) {
	const canExpand = contextualData.text.length > 0 || contextualData.media.length > 0

	const onVote = (vote: number, location: { x: number, y: number }) => {
		sendMessage(
			'CONTEXTUAL_DATA_VOTE',
			{
				dataId: contextualData.id,
				vote,
				dataSource: contextualData.dataSource,
			},
			{ location }
		)
	}
	const id = React.useId()
	const openExpandedData = useOpenExpandedData()

	return (
		<div className="w-full min-w-200px" id={id}>
			<button
				{...props}
				// add a custom label for the button here because this button is a card and has lots of content (title, subtitle, icon)
				aria-label={contextualData.title}
				className={classnames(
					className,
					'shadow-primary-800 shadow-md cursor-pointer text-left transition-all bg-[#5c6992] w-full min-w-200px rounded p-4',
					'hover:scale-[1.025] hover:shadow-primary-900 hover:shadow-lg'
				)}
				ref={ref}
				onClick={event => {
					canExpand && openExpandedData?.({ lookup, allowVote })
					onBlur?.() // Remove line being drawn to the map when card is hovered over
				}}
				onMouseEnter={() => onHover?.()}
				onTouchStart={() => onHover?.()}
				onMouseLeave={() => onBlur?.()}>
				<div className="flex gap-4">
					{contextualData.icon && (
						<SvgIcon
							{...{
								url: contextualData.icon,
								className: 'size-8 m-auto',
								width: 32,
								height: 32,
							}}
						/>
					)}
					<div className="flex flex-1 justify-between">
						<div>
							<h4 className="flex-1">
								<TextToSpeech>{contextualData.title}</TextToSpeech>
							</h4>
							{contextualData.subtitle && (
								<p className="text-white pt-2 -mt-1">
									<TextToSpeech>{contextualData.subtitle}</TextToSpeech>
								</p>
							)}
						</div>

						<div className="flex gap-5 items-end">
							{allowVote && (
								<Voting
									className="w-fit self-start"
									score={contextualData.classVote}
									vote={contextualData.studentVote}
									onSelect={onVote}
								/>
							)}
						</div>
					</div>
				</div>
			</button>
		</div>
	)
}): AbstractComponent<Props, HTMLButtonElement>)
