import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getIsJuniorPlus } from '../../store/stores/general'
import styled from 'styled-components'
import 'styled-components/macro'
import { ACCENT_GREEN, DARKEST_SHADE } from '../../constants/styles'
import {
	JUNIOR_SURVEY_QUESTIONS,
	JUNIOR_PLUS_SURVEY_QUESTIONS,
	type SurveyQuestion,
	SURVEY_QUESTION_TYPES,
	RATING_OPTIONS,
} from './constants'
import classnames from 'classnames'
import { Button } from '../basics/Buttons.jsx'
import { sendMessage } from '../../websockets/websocket'

/**
 * A survey that is shown to the student after they complete a mission.
 * @returns {React$Node}
 */
export default function Survey(): React$Node {
	const isJrPlus = useSelector(getIsJuniorPlus)
	const questions = isJrPlus ? JUNIOR_PLUS_SURVEY_QUESTIONS : JUNIOR_SURVEY_QUESTIONS
	return <SurveyWithQuestions questions={questions} />
}

/**
 * Generic Survey that can be used for both mission types. Accepts one prop, "questions"
 * which can be customized in './constants.js' for each mission control set.
 * Each question is one page in the survey and the only question type supported right now is "rating"
 */
function SurveyWithQuestions({ questions }: { questions: SurveyQuestion[] }): React$Node {
	const [answerData, setAnswerData] = useState({
		missionRating: -1,
	})

	const [currentPage, setCurrentPage] = useState(0)
	const isLastQuestion = currentPage === questions.length - 1

	const question = questions[currentPage]
	const questionAnswer = answerData[question.id]

	const submit = () => {
		sendMessage('STUDENT_SURVEY', { results: answerData })
	}

	return (
		<div className="w-full h-full p-24 bg-primary-600 grid grid-cols-4 gap-8">
			{questions.length > 1 && (
				<div className="col-span-4">FINISH SURVEY QUESTIONS TO CONTINUE </div>
			)}
			<div className="rounded bg-primary p-4 text-center text-white text-4xl col-span-4 h-fit w-full">
				{question.phrase}
			</div>
			{question.type === SURVEY_QUESTION_TYPES.RATING ? (
				<>
					{RATING_OPTIONS.map(({ text, icon, iconAlt }, index) => {
						return (
							<div className="col-span-1 relative ">
								<button
									key={String(question.id + '-' + index)}
									className={classnames(
										questionAnswer === index
											? 'before:rounded-md before:shadow-[inset_0px_0px_0px_theme("spacing.2")_theme("colors.accent-green")] before:absolute before:content-[""] before:w-full before:h-full'
											: //   ''
											  '',
										'bg-primary flex flex-col  h-full w-full rounded-md '
									)}
									onClick={() =>
										setAnswerData(data => {
											const newData = { ...data }
											newData[question.id] = index
											return newData
										})
									}>
									<img className="p-6 my-6 h-auto mx-auto" alt={iconAlt} src={icon} />
									<div className="bg-primary-green text-white w-full h-full flex items-center justify-center text-xl p-2 rounded-b-md">
										{text}
									</div>
								</button>
							</div>
						)
					})}
				</>
			) : null}
			<div className="col-span-4 flex justify-center items-center">
				<>
					{questions.length > 1 && (
						<PhaseCircleDiv>
							{questions.map((content, index) => (
								<PhaseCircle glow={index <= currentPage} key={index} />
							))}
						</PhaseCircleDiv>
					)}
				</>
				<Button
					className="!text-lg"
					onClick={() =>
						!isLastQuestion ? setCurrentPage(phase => (phase + 1) % questions.length) : submit()
					}>
					{isLastQuestion ? 'SUBMIT' : 'NEXT'}
				</Button>
			</div>
		</div>
	)
}

const PhaseCircleDiv = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 30%;
`

const PhaseCircle = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${({ glow }) => (glow ? ACCENT_GREEN : DARKEST_SHADE)};
`
