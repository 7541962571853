import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getStudentId } from '../../store/stores/general'
import styled from 'styled-components/macro'
import { getFormattedDate, reduceObject } from '../../utility/functions'
import { getAnalyticsCategoryScore, useCategories } from './categories'
import { getAnalyticsStore } from '../../store/selectors/sharedSelectors'
import { Title } from './sharedComponents'
import type { StudentAnalytics } from '@mission.io/mission-toolkit'
import { ColorCoin } from './ProficiencyLegend'
import { IncompleteAnalyticsPopover, NA_TEXT } from './MissionReviewScreen.jsx'

const UNEXPANDED_MISSION_COUNT = 3

/**
 * Returns past analytics for the given studentId
 * @param {string} studentId
 * @returns {{date: Date, missionName: string, student: StudentAnalytics, weights: { [string]: number }, studentScore: ?number}}
 */
function usePastAnalyticsForStudent(
	studentId: ?string
): Array<{
	date: Date,
	missionName: string,
	student: StudentAnalytics,
	weights: { [string]: { [string]: number } },
	studentScore: ?number,
}> {
	const store = useSelector(getAnalyticsStore)
	if (!store?.pastAnalytics || !studentId) {
		return []
	}
	return store.pastAnalytics
		?.filter(({ analytics }) => analytics?.studentAnalytics?.[studentId])
		.map(({ createdTime, missionName, analytics }) => {
			// $FlowFixMe[incompatible-type] we just filtered to make sure the student exists
			const student: StudentAnalytics = analytics.studentAnalytics?.[studentId]
			const studentScore = student?.studentPoints
				? reduceObject(student.studentPoints, (total, pointValue) => total + pointValue, 0)
				: null
			return {
				date: new Date(createdTime),
				missionName: missionName,
				student: student,
				weights: analytics.weights || {},
				studentScore,
			}
		})
		.sort((a, b) => {
			return b.date - a.date
		})
}

/**
 * Displays a students scores for the past missions in that school year.
 * @returns {React$Node}
 */
export default function PastMissions(): React$Node {
	const studentId = useSelector(store => getStudentId(store.general))
	const categories = useCategories()
	const pastAnalytics = usePastAnalyticsForStudent(studentId)
	const [showAll, setShowAll] = useState(false)
	const analyticsStore = useSelector(getAnalyticsStore)

	return (
		<div>
			<StyledTitle>Past Missions</StyledTitle>
			{!pastAnalytics?.length ? (
				<span>Not Available</span>
			) : (
				<>
					{(pastAnalytics.length < UNEXPANDED_MISSION_COUNT || showAll
						? pastAnalytics
						: pastAnalytics.slice(0, UNEXPANDED_MISSION_COUNT)
					).map((pastData, index) => (
						<div key={index}>
							<div css="display: flex;">
								<Label>Date: {getFormattedDate(pastData.date)}</Label>
								<Label>Mission: {pastData.missionName}</Label>
							</div>
							<ScoreContainer>
								<div>
									Mission Score
									<br />
									{pastData.studentScore}
								</div>
								{categories.map(category => {
									const score = getAnalyticsCategoryScore(
										category.accessor(pastData.student),
										pastData.weights
									)
									return (
										<div key={category.key} className="relative">
											{!score && analyticsStore?.hasIncompleteAnalytics && (
												<IncompleteAnalyticsPopover />
											)}

											{category.title}
											<br />
											<ColorCoin level={score} css="margin: auto; width: 30px;">
												{score ?? NA_TEXT}
											</ColorCoin>
										</div>
									)
								})}
							</ScoreContainer>
						</div>
					))}
					{pastAnalytics.length > UNEXPANDED_MISSION_COUNT ? (
						<PushRight>
							<button
								onClick={() => setShowAll(!showAll)}
								className="text-neutral hover:brightness-90">
								{showAll ? 'Show Less Missions' : 'Show More Missions...'}
							</button>
						</PushRight>
					) : null}
				</>
			)}
		</div>
	)
}

const Label = styled.div`
	padding: 4px;
	border-radius: 4px;
	margin: 8px 0;
	&:nth-child(2n + 1) {
		background: #b1519c;
	}
	&:nth-child(2n) {
		background: #4f9d99;
	}
	color: white;
	&:not(:last-child) {
		margin-right: 8px;
	}
`
const ScoreContainer = styled.div`
	margin-bottom: 8px;
	border-radius: 4px;
	display: flex;
	background: #3f3b6e;
	justify-content: space-between;
	> * {
		flex: 1;
		padding: 8px;
		&:not(:first-child) {
			background: #5b5889;
		}
		&:not(:last-child) {
			border-right: 6px solid #3f3b6e;
		}
		text-align: center;
		color: white;
		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}
`

const PushRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`

const StyledTitle = styled(Title)`
	margin: ${({ theme }) => `${theme.spacing2x} 0`};
	text-align: left;
`
