import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import LogRocket from 'logrocket'
import thunk from 'redux-thunk'
import * as middlewares from './middlewares'
import rootReducer from './rootReducer'

import type { Store as _Store, Dispatch, Middleware } from 'redux'
import type { ReduxStore } from './rootReducer'
import type { Action } from './actionTypes'
import { connectStoreToWebsocket } from '../websockets/websocket'

const enhancers = []
const initialState = {}
const middleware: Array<Middleware<ReduxStore, Action, Dispatch<Action>>> = [
	thunk,
	middlewares.general, // general middleware needs to come after websocket middleware so it can handle redux actions parsed from websocket messages
]

if (process.env.NODE_ENV !== 'development') {
	middleware.push(LogRocket.reduxMiddleware()) // Logrocket middleware needs to come last
}

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers)

export type Store = _Store<ReduxStore, Action, Dispatch<Action>>

export function createReduxStore(initialState: {}): Store {
	return createStore(rootReducer, ((initialState: any): ReduxStore), composedEnhancers)
}
const store: Store = createReduxStore(initialState)

connectStoreToWebsocket(store) // used to avoid circular dependencies

export default store
