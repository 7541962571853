import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useOnClickOutside } from '../../../utility/hooks'
import { useCanvasMaterial, type CanvasMedia } from '../connect/hooks/useCanvasMaterial'
import { createCustomImageFromAsset } from '../customShapes/CustomImageShapeUtil'
import {
	useEditor,
	type Editor,
	track,
	useAssetUrls,
	GeoShapeGeoStyle,
	useEvents,
	DefaultColorStyle as ColorStyle,
	getDefaultColorTheme,
} from '@tldraw/tldraw'
import { PencilIcon, ImageIcon, SelectIcon, TextIcon, ShapeIcon } from '../../../images/canvas'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as EraserIcon } from '../../../images/Eraser.svg'

import classnames from 'classnames'

type ToolType = 'select' | 'eraser' | 'draw' | 'text' | 'geo' | 'image'

/**
 * A container to display all the tools in the toolbox.
 * @param {React$Node} props.children the tools to display in the toolbox
 * @param {string} props.className any additional classes to add to the toolbox
 */
export const ToolboxContainer = ({
	children,
	className,
}: {
	className?: string,
	children?: React$Node,
}): React$Node => {
	return (
		<div
			className={classnames(
				className,
				'absolute top-0 h-full w-[var(--toolbox-width)] ml-[calc(-1*var(--toolbox-width))] flex flex-col justify-center items-center gap-[var(--toolbox-spacer)] p-2'
			)}>
			{children}
		</div>
	)
}

/**
 * A button to select a tool in the toolbox.
 * @param {ToolType} props.type type of tool as accepted by tldraw
 * @param {?React$Node} props.children content for the button.
 *
 * @returns {React$Node}
 */
function _SelectButton({
	type,
	children,
	setActiveTool,
}: {
	type: ToolType,
	children: React$Node,
	setActiveTool: () => void,
}): React$Node {
	const editor = useEditor()
	const activeTool = editor.currentToolId
	const [showExtension, setShowExtension] = useState(false)
	const isActive = (toolContent[type].isActive ?? defaultIsActive)(activeTool, {
		type,
		expanded: showExtension,
	})

	const content = toolContent[type]
	const ExtensionComponent = content.RenderExtension

	useEffect(() => {
		if (isActive) setActiveTool()
	}, [isActive, setActiveTool])

	return (
		<RoundToolWithOptionalPopover
			className="max-h-[12%]"
			onClickOutside={() => {
				if (showExtension) {
					setShowExtension(false)
				}
			}}
			buttonIcon={toolContent[type].icon}
			onClick={() => {
				;(content.onClick ?? defaultOnClick)(editor, { type })
				setShowExtension(true)
			}}
			isActive={isActive}
			popover={
				ExtensionComponent ? (
					<ExtensionWithTlDrawTrack
						show={showExtension}
						isToolActive={isActive}
						extensionClassName={classnames(
							'[--item-width:2.8em] [--total-cols:5]',
							content.extensionClassName
						)}>
						<ExtensionComponent editor={editor} close={() => setShowExtension(false)} />
					</ExtensionWithTlDrawTrack>
				) : null
			}
		/>
	)
}
export const SelectButton: ({ type: ToolType, children: React$Node }) => React$Node = track(
	_SelectButton
)

/**
 * Default behavior when a tool is clicked. Make the tool active on the app
 * @param {TldrawApp} app
 * @param {ToolType} type
 */
const defaultOnClick = (editor: Editor, { type }: { type: ToolType }) => {
	if (type === 'image') {
		throw Error('image tools are custom tools and cannot use the default on click event.')
	}
	editor.setSelectedTool(type)
}
/**
 * Default callback to determine if a tool is active on the app
 * @param {ToolType} snapshot the snapshot of the app returned from TLDrawApp.useStore
 * @param {ToolType} type the type of the tool in question
 * @returns {boolean}
 */
const defaultIsActive = (activeTool, { type }: { type: ToolType, expanded: boolean }) => {
	return activeTool === type
}

type SharedToolContent = {|
	icon: ({ className?: string }) => React$Node,
	onClick?: (Editor, { type: ToolType }) => void,
	isActive?: (ToolType, { type: ToolType, expanded: boolean }) => boolean,
|}

type ExtendableToolContent = {|
	extensionClassName?: string,
	RenderExtension: ({
		editor: Editor,
		close: () => void,
	}) => React$Node,
|}

/**
 * Content used to uniquely define each specific tool. Some tools have additional content that is displayed when the tool is clicked.
 */
const toolContent: {
	[ToolType]:
		| {|
				type: 'draw' | 'geo' | 'image',
				...ExtendableToolContent,
				...SharedToolContent,
		  |}
		| {|
				type: 'select' | 'eraser' | 'text',
				...SharedToolContent,
		  |},
} = {
	draw: {
		type: 'draw',
		icon: PencilIcon,
		extensionClassName: `[--item-width:1.4em]`,
		// Renders a list of colors that can be chosen to draw with.
		RenderExtension: track(() => {
			const editor = useEditor()
			const theme = getDefaultColorTheme(editor)
			const handleColorChange = useCallback(
				(value: string) => {
					editor.setStyle(ColorStyle, value)
				},
				[editor]
			)
			const current = editor.sharedStyles.getAsKnownValue(ColorStyle)
			return ColorStyle.values.map(value => {
				const active = current === value
				return (
					<ExtensionOption
						key={value}
						onClick={() => handleColorChange(value)}
						style={{
							backgroundColor: theme[value].solid,
							borderRadius: '50%',
							border: active ? `2px solid var(--color-accent-aqua)` : 'none',
						}}
					/>
				)
			})
		}),
	},
	select: { type: 'select', icon: SelectIcon },
	eraser: { type: 'eraser', icon: EraserIcon },
	text: { type: 'text', icon: TextIcon },
	image: {
		type: 'image',
		icon: ImageIcon,
		onClick: editor => {
			// app.selectTool('select')
		},
		isActive: (activeTool, { expanded }) => {
			return expanded
		},
		extensionClassName: `[--total-cols:5]`,
		// Renders a list of images that are specifically defined in the tldraw app assets. When the image icon is selected this list appears. When an image is clicked it is added to the center of the canvas.
		RenderExtension: track(({ editor, close }: { editor: any, close: () => void }): React$Node => {
			const images = useCanvasMaterial().images
			// Adds an image shape of the selected asset to the center of the canvas
			const selectImage = async (media: CanvasMedia) => {
				const assetId = 'asset:' + media._id
				let asset = editor.getAssetById(assetId)
				let shouldAlsoCreateAsset = false
				if (!asset) {
					shouldAlsoCreateAsset = true
					asset = await editor.externalContentManager.createAssetFromUrl(editor, media.url, {
						text: media.text,
						type: media.type,
						id: assetId,
					})
				}
				editor.batch(() => {
					if (shouldAlsoCreateAsset) {
						editor.createAssets([asset])
					}
					createCustomImageFromAsset(editor, asset, editor.viewportPageBounds.center)
					editor.setSelectedTool('select')
				})
				close()
			}
			return images.map(image => (
				<ExtensionOption key={image._id} style={{ backgroundColor: 'white', padding: '2px' }}>
					<img
						alt={image.text}
						onDragEnd={e => {
							e.stopPropagation()
							e.preventDefault()
							selectImage(image)
						}}
						onClick={e => {
							e.stopPropagation()
							e.preventDefault()
							selectImage(image)
						}}
						src={image.url}
						css={`
							width: 100%;
							height: 100%;
							object-fit: contain;
							border-radius: 4px;
						`}
					/>
				</ExtensionOption>
			))
		}),
	},
	geo: {
		type: 'geo',
		icon: ShapeIcon,
		extensionClassName: '[--total-cols:6]',
		// Renders a list of shapes that can be chosen to apply to the canvas.
		RenderExtension: track((): React$Node => {
			const editor = useEditor()
			const trackEvent = useEvents()
			const geoState = editor.sharedStyles.getAsKnownValue(GeoShapeGeoStyle)

			const shapes = [...GeoShapeGeoStyle.values].map(id => ({
				id,
				icon: 'geo-' + id,
				label: `tool.${id}`,
				onSelect(source) {
					editor.batch(() => {
						editor.updateInstanceState(
							{
								stylesForNextShape: {
									...editor.instanceState.stylesForNextShape,
									[GeoShapeGeoStyle.id]: id,
								},
							},
							true
						)
						editor.setSelectedTool('geo')
						trackEvent('select-tool', { source, id: `geo-${id}` })
					})
				},
			}))
			const assetUrls = useAssetUrls()
			return shapes.map(shape => {
				const active = geoState === shape.id
				return (
					<ExtensionOption
						key={shape.id}
						onClick={shape.onSelect}
						style={{ backgroundColor: active ? 'aqua' : 'white' }}>
						<img src={assetUrls.icons[shape.icon]} alt={shape.label} />
					</ExtensionOption>
				)
			})
		}),
	},
}

const noop = () => {}

/**
 * A view for an individual tool that can be selected. If `popover` is present, then when the tool is selected a popover should be revealed.
 * The popover component should utilize the Extension and Extension Option components also located in this file to display the popover content.
 * @param {React$Node} props.popover the content that will be displayed as a popover when the tool is clicked
 * @param {Function} props.buttonIcon the icon to display in the button. Can be passed like so `icon={PencilIcon}`
 * @param {Function} props.onClick the function to run when the tool is clicked
 * @param {boolean} props.isActive whether or not the tool is currently active
 * @param {string} props.className any additional classes to add to the tool
 * @param {Function} props.onClickOutside the function to run when the user clicks outside the popover
 * @returns {React$Node}
 */
export const RoundToolWithOptionalPopover = ({
	popover,
	buttonIcon: ButtonIcon,
	onClickOutside = noop,
	isActive,
	className,
	onClick,
}: {
	buttonIcon: (props: { className?: string }) => React$Node,
	onClick: () => void,
	isActive?: boolean,
	popover?: React$Node,
	onClickOutside?: () => void,
	className?: string,
}): React$Node => {
	const ref = useOnClickOutside(onClickOutside)
	return (
		<div
			className={classnames(className, 'relative w-auto aspect-square mx-auto rounded-full')}
			ref={ref}>
			<button
				onClick={onClick}
				className={classnames(
					`h-full w-full flex p-2 items-center justify-center rounded-full cursor-pointer
				border-2 [background-image:linear-gradient(var(--color-primary-700)_0%,var(--color-primary-700)_26%,var(--color-primary-800)_99%)]`,
					isActive ? '!border-accent-aqua !border-4' : 'border-primary-900'
				)}>
				<ButtonIcon className="fill-accent-aqua m-auto size-full" />
			</button>
			{popover}
		</div>
	)
}

/**
 * A clickable child component of the Extension component. This component is a single option in the extension menu.
 * IE a color option for the draw tool or a shape option for the geo tool.
 * @param {React$Node} props.children the content of the option
 * @param {string} props.className any additional classes to add to the extension option
 * @param {Function} props.onClick the function to run when the option is clicked
 */
export const ExtensionOption = ({
	children,
	className,
	...rest
}: {|
	children?: React$Node,
	onClick?: MouseEvent => void,
	style?: {},
	className?: string,
|}): React$Node => (
	<button
		className={classnames(className, 'size-[var(--item-width)] cursor-pointer rounded')}
		{...rest}>
		{children}
	</button>
)

/**
 * A component that displays additional styles or content for a tool when the tool is clicked.
 * The div will be displayed to the right of the tool button and will disappear on the next tap or click of the canvas
 * @param {boolean} props.show whether or not to show the extension
 * @param {boolean} props.isToolActive whether or not the tool is active
 * @param {string} props.extensionClassName any additional classes to add to the extension
 *
 */
export function Extension({
	show,
	isToolActive,
	extensionClassName,
	children,
}: {
	show: boolean,
	isToolActive: boolean,
	extensionClassName?: string,
	children: React$Node,
}): React$Node {
	return (
		<div
			css="position: absolute; left: calc(100% - 1px); top: 1px; height: 100%; width: 100%; display: flex; align-items: center;"
			className="pointer-events-none">
			<ArrowRight $highlight={isToolActive} className="pointer-events-auto" />
			{show && (
				<ExtensionStyled className={classnames('pointer-events-auto', extensionClassName)}>
					{children}
				</ExtensionStyled>
			)}
		</div>
	)
}

const ExtensionWithTlDrawTrack = track(Extension)
const ArrowRight = styled.div`
	width: 0;
	height: 0;
	z-index; 1;
	margin-top: -2px;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	${({ $highlight }) => `
		border-left: 8px solid ${$highlight ? 'aqua' : 'var(--color-primary-900)'};
	`}
	
`

const ExtensionStyled = styled.div`
	--grid-gap: 4px;

	display: flex;
	padding: 8px;
	justify-content: space-around;
	border-radius: 4px;
	background-color: var(--color-primary-darker);
	height: fit-content;
	flex-wrap: wrap;
	grid-gap: var(--grid-gap);
	align-content: flex-start;
	justify-content: flex-start;

	min-width: calc(
		var(--item-width) * var(--total-cols) + var(--grid-gap) * (var(--total-cols) - 1) + 16px
	);
`
