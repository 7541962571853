import robot1 from '../../../images/robot.png'
import robot1Small from '../../../images/robotSmall.png'
import robot2 from '../../../images/robot2.png'
import robot2Small from '../../../images/robot2Small.png'
import robot3 from '../../../images/robot3.png'
import robot3Small from '../../../images/robot3Small.png'

export const ROBOTS: {
	'1': {
		dragSrc: string,
		src: string,
		dimensions: [number, number],
	},
	'2': {
		dragSrc: string,
		src: string,
		dimensions: [number, number],
	},
	'3': {
		dragSrc: string,
		src: string,
		dimensions: [number, number],
	},
} = {
	'1': {
		dragSrc: robot1Small,
		src: robot1,
		dimensions: [86, 143],
	},
	'2': {
		dragSrc: robot2Small,
		src: robot2,
		dimensions: [86, 116],
	},
	'3': {
		dragSrc: robot3Small,
		src: robot3,
		dimensions: [86, 114],
	},
}

export type WorkingRobotLookup = {
	[$Keys<typeof ROBOTS>]: {
		systemId: string,
	},
}
