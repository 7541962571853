import React from 'react'
import Connection from '../Components/Connection'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'
import { EMPTY_SPACE, ACCENT_BLUE, WARNING, DARK_PRIMARY } from '../../../../../../constants/styles'
import styled from 'styled-components'

import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'

const StyledConnectionPanel = styled.div`
	width: 100%;
	display: relative;

	.ConnectionArea {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: ${EMPTY_SPACE};
		border-radius: 8px;
		padding: 8px;

		svg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;

			.line {
				stroke: ${ACCENT_BLUE};
				opacity: 0.5;

				&:hover {
					stroke: ${WARNING};
				}
			}
		}

		.node {
			width: 2em;
			height: 2em;
			padding: 0.25em;
			margin: 4px;
			text-align: center;
			border-radius: 100%;
			color: ${ACCENT_BLUE};
			border: 2px solid ${ACCENT_BLUE};
			background-color: ${DARK_PRIMARY};
			&:nth-child(even) {
				justify-self: end;
			}
		}
	}

	.connection-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(${props => Math.ceil(props.count / 2)}, 1fr);
		flex: 1 0 auto;
		z-index: 1;
		pointer-events: none;

		.node {
			pointer-events: all;
			cursor: pointer;

			&:hover {
				background-color: #fff4;
			}
		}
	}
`

export default function ConnectionPanel({ panel }: { panel: PopulatedPanel }): React$Node {
	let connectionComponent = null
	for (let component of panel.components) {
		if (component.type === COMPONENT_TYPES.CONNECTION) {
			connectionComponent = component
			break
		}
	}
	return (
		<StyledConnectionPanel count={connectionComponent ? connectionComponent.nodes.length : 0}>
			{connectionComponent ? (
				<Connection component={connectionComponent} panelId={panel.id} />
			) : (
				<div>ERROR: Could not find component</div>
			)}
		</StyledConnectionPanel>
	)
}
