import type { Store } from '../store'
import config from '../config'
import { isWantingToRunMissionAsControl } from '../utility/urls'
import {
	getMissionId,
	getShouldRedirectToMissionSurveyOnMissionEnd,
	setLoginError,
} from '../store/stores/general'
import { redirectToDashboard } from '../utility/functions'

// In the ws spec, the range [4000,4999] is reserved for custom errors. These custom close codes
// are in that range, and are the close codes we send when closing the connection with a specific reason.
export const WEBSOCKET_CLOSE_CODES = {
	// The incoming request is invalid (not enough information provided, etc.)
	BAD_REQUEST: 4400,
	// For teacher connections, the client needs to login before continuing. For students, a studentId must be provided.
	REQUIRE_LOGIN: 4401,
	// The client is not authorized to perform the action
	UNAUTHORIZED: 4403,
	// The requested resource was not found
	NOT_FOUND: 4404,
	// ping failure (this is issued by the client)
	PING_FAILURE: 4405,
	// The websocket was closed because the Mission ended
	MISSION_ENDED: 4141,
}

/**
 * onWebsocketClose - handles when a websocket closes, also determines if the websocket should reconnect
 *
 * @param {CloseEvent} closeMessage - the close message given to the websocket
 * @param {Store} store - the current redux store
 *
 * @return {{ retry: boolean, resetAttempts?: boolean}} shouldTryToReconnect - if `.retry` true if the websocket should retry connecting false otherwise, if `.resetAttempts` is true and `.retry` is true, then it will retry and set the attempts back to 0 (ie. try to reconnect instantly)
 *
 */
export function onWebsocketClose(
	closeMessage: CloseEvent,
	store: Store
): {| retry: false |} | {| retry: true, resetAttempts?: boolean |} {
	const wsCloseCode = closeMessage.code

	if (wsCloseCode === undefined) {
		return { retry: true }
	}

	const loginRedirectUrl = `${config.loginUrl}?sendto=${window.location.href}`

	if (wsCloseCode === WEBSOCKET_CLOSE_CODES.UNAUTHORIZED) {
		if (isWantingToRunMissionAsControl()) {
			// $FlowFixMe[incompatible-call] the setLoginError action is valid
			store.dispatch(setLoginError({ type: 'USER_NOT_ALLOWED', redirectUrl: loginRedirectUrl }))
		}
		return { retry: false }
	} else if (wsCloseCode === WEBSOCKET_CLOSE_CODES.REQUIRE_LOGIN) {
		if (isWantingToRunMissionAsControl()) {
			window.location.href = loginRedirectUrl
		} else {
			window.location.href = '/'
		}
		return { retry: false }
	} else if (wsCloseCode === WEBSOCKET_CLOSE_CODES.MISSION_ENDED) {
		if (isWantingToRunMissionAsControl()) {
			const state = store.getState()
			const missionId = getMissionId(state)
			redirectToDashboard(
				missionId && getShouldRedirectToMissionSurveyOnMissionEnd(state) ? missionId : undefined
			)
		} else {
			window.location.href = '/'
		}
		return { retry: false }
	} else if (wsCloseCode === WEBSOCKET_CLOSE_CODES.NOT_FOUND) {
		return { retry: true } // the mission may be being loaded into the mission-server
	} else if (wsCloseCode === WEBSOCKET_CLOSE_CODES.PING_FAILURE) {
		return { retry: true, resetAttempts: true }
	} else if (wsCloseCode === WEBSOCKET_CLOSE_CODES.BAD_REQUEST) {
		console.error(`Websocket closed due to bad request`)
		return { retry: false }
	}

	return { retry: true }
}
