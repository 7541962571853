import LogRocket from 'logrocket'
import { SWITCH_STATION, STUDENT_DETAILS } from '../actionTypes'

import type { ReduxStore } from '../rootReducer'
import type { Action } from '../actionTypes'
import type { Dispatch as ReduxDispatch } from 'redux'
import { sendMessage } from '../../websockets/websocket'

export type Dispatch = ReduxDispatch<Action>

export type MiddleWare = (
	store: $ReadOnly<{
		getState: () => ReduxStore,
		dispatch: Dispatch,
	}>
) => (next: (action: Action) => any) => (action: Action) => any

const generalMiddleware: MiddleWare = store => next => action => {
	if (action.type === SWITCH_STATION) {
		sendMessage('STATION_SELECT', action.payload)
	} else if (action.type === STUDENT_DETAILS) {
		LogRocket.identify(action.payload.id)
	}
	return next(action)
}

export default generalMiddleware
