import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import {
	PiArrowCounterClockwiseBold,
	PiArrowClockwiseBold,
	PiPlayFill,
	PiPauseFill,
} from 'react-icons/pi'
import { IconButton } from './basics/Buttons'
import { ALMOST_BLACK, rgba } from '../constants/styles'
import classnames from 'classnames'

/**
 * A video component that can be controlled by the user. Includes a play/pause button,
 * as well as buttons to skip forward and backward.
 *
 * @param {Object} props - The component props
 * @param {string} [props.src] - The source URL of the video to play
 * @param {boolean} [props.autoPlay=true] - Whether the video should start playing automatically
 * @param {Function} [props.subscribe] - Callback function that receives the video element reference
 * @param {string} [props.className] - Additional CSS classes to apply to the container
 * @param {Function} [props.onLoad] - Callback function that fires when the video has loaded
 * @returns {React.Node} A controlled video player component
 */
export function VideoController({
	src,
	autoPlay = true,
	className,
	subscribe,
	onLoad,
}: {
	src: ?string,
	autoPlay?: boolean,
	subscribe?: ({ current: ?HTMLVideoElement }) => void,
	className?: string,
	onLoad?: () => void,
}): React$Node {
	const [playing, setPlaying] = useState(autoPlay === false ? null : true)
	const ref = useRef()

	return (
		<VideoContainer
			className={classnames(className, 'size-full')}
			$forceShowControls={playing === null}>
			<video
				autoPlay={autoPlay}
				loop={autoPlay}
				ref={ref}
				className="size-full"
				src={src}
				onLoadedData={() => {
					subscribe?.(ref)
					onLoad?.()
				}}
				onPlay={() => setPlaying(true)}
				onPause={() => setPlaying(false)}
			/>
			<VideoControlsContainer onClick={e => e.stopPropagation()}>
				<IconButton
					onClick={() => {
						if (ref.current) {
							ref.current.currentTime = ref.current.currentTime - 10
						}
					}}>
					<PiArrowCounterClockwiseBold />
				</IconButton>
				<IconButton
					css="&&& { font-size: 1.8em; }"
					onClick={() => {
						if (!ref.current) {
							return
						}

						if (ref.current.paused) {
							ref.current.play().catch(err => {
								console.error('Error playing video', err)
							})
						} else {
							ref.current.pause()
						}
					}}>
					{playing ? <PiPauseFill /> : <PiPlayFill />}
				</IconButton>
				<IconButton
					onClick={() => {
						if (ref.current) {
							ref.current.currentTime = ref.current.currentTime + 10
						}
					}}>
					<PiArrowClockwiseBold />
				</IconButton>
			</VideoControlsContainer>
		</VideoContainer>
	)
}

const VideoControlsContainer = styled.div`
	position: absolute;
	left: 50%;
	bottom: var(--spacing2x);
	transform: translateX(-50%);
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: ${rgba(ALMOST_BLACK, 0.7)};
	border-radius: 999px;
	padding: var(--spacing);

	${IconButton} {
		svg {
			display: block;
		}
		margin: 0 var(--spacing);
		font-size: 1.3em;
	}
`

const VideoContainer = styled.div`
	position: relative;


	${VideoControlsContainer} {
        ${({ $forceShowControls }) => `
		opacity: ${$forceShowControls ? 1 : 0};
		transition: all 0.2s ease;
        
        `}
	}

	&:hover ${VideoControlsContainer} {
		opacity: 0.7;
	}

	${VideoControlsContainer}:hover {
		opacity: 1;

		// background-color: ${rgba(ALMOST_BLACK, 0.7)};
	}
`
