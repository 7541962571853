import styled from 'styled-components'
import React from 'react'
import { Progress } from 'react-sweet-progress'
import { clamp } from '../../utility/functions'
import 'react-sweet-progress/lib/style.css'
import { FrownyFace, SadFace, MehFace, SmileyFace, HappyFace } from '../../images/surveyIcons'
import { IS_WEBKIT } from '../../constants/browser'

import { PRIMARY_GREEN, ACCENT_BLUE, DARKEST_SHADE, STATION_PADDING } from '../../constants/styles'
import type { StyledType } from '../../types/utilityTypes'

export const Row: StyledType<> = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

export const Station: StyledType<> = styled.div`
	height: 100%;
	width: 100%;
	padding: ${STATION_PADDING};
	position: relative;
`
export const ScrollBar: StyledType<> = styled.div`
	margin: 12px 0;
	overflow-y: auto;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px ${ACCENT_BLUE};
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px ${ACCENT_BLUE};
	}
`

const ProgressBarWrapper = ({
	percent,
	showPercentIcon,
	color,
	...props
}: {
	percent: number,
	showPercentIcon?: boolean,
	color?: string,
	...Object,
}): React$Node => (
	<Progress
		{...props}
		percent={clamp(percent, 0, 100)}
		symbolClassName={showPercentIcon || 'size-0 overflow-hidden'}
		status={props.status ? props.status : 'active'}
		theme={{
			active: { symbol: null, color: color || ACCENT_BLUE, trailColor: DARKEST_SHADE },
			error: { symbol: null, color: '#cb020e', trailColor: DARKEST_SHADE },
			success: { symbol: null, color: PRIMARY_GREEN },
			default: {
				symbol: null,
				trailColor: DARKEST_SHADE,
				color: color || ACCENT_BLUE,
			},
		}}
	/>
)

// webkit hack - `position: relative;` (used by react-sweet-progress) causes animation and progress bar to appear in wrong location within an svg.
// This hack fixes the wrong location bug, but makes the animation invisible.
export const ProgressBar:
	| StyledType<{}, typeof ProgressBarWrapper>
	| typeof ProgressBarWrapper = IS_WEBKIT
	? styled(ProgressBarWrapper)`
			.react-sweet-progress-line-inner {
				position: revert;

				&:before {
					position: revert;
				}
			}
	  `
	: ProgressBarWrapper

export const Column: StyledType<> = styled.div`
	display: flex;
	flex-direction: column;
`

export { SecondaryButton, Button, CloseButton } from './Buttons'
export { default as Name } from './Name'
export { default as Modal } from './Modal'
export { default as ReactModal } from './ReactModal'
export { default as Loading } from './Loading'

export const RATINGS = [
	{ value: 1, icon: FrownyFace },
	{ value: 2, icon: SadFace },
	{ value: 3, icon: MehFace },
	{ value: 4, icon: HappyFace },
	{ value: 5, icon: SmileyFace },
]
