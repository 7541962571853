import React, { useState, useEffect } from 'react'
import { mapObject, sortValues } from '../../utility/functions'
import styled from 'styled-components/macro'
import { Loading } from '../basics'
import { isMobileOnly } from 'react-device-detect'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as TeamLeadBadge } from '../../images/TeamLeadBadge.svg'
import config from '../../config'
import { ACCENT_BLUE_FAINT, EMPTY_SPACE } from '../../constants/styles'
import {
	type AdvancedStudents,
	type AdvancedStudent,
	type JrStudents,
	type AdvancedStudentsObject,
	useStudentsQuery,
} from './shared'
import { AddNewStudentButton } from './AddNewStudent'
import { Button } from '../basics/Buttons.jsx'
import { connectToMission } from '../../utility/urls'

/**
 * A page component that will display all the students in the class. Students
 * will select their name to finish the login process.
 */
export default function StudentSelect({
	missionCode,
	goBack,
	onError,
	missionServerUrl,
}: {
	missionCode: string,
	goBack: () => void,
	onError: Error => void,
	missionServerUrl: string,
}): React$Node {
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const { loading: loadingStudents, students, error } = useStudentsQuery(missionServerUrl, {
		immediate: { missionCode },
	})

	useEffect(() => {
		error && onError(error)
	}, [error, onError])

	const submitStudentCode = (studentCode: string) => {
		if (!loadingSubmit) {
			setLoadingSubmit(true)
			connectToMission(missionCode, studentCode)
		}
	}

	return loadingStudents || loadingSubmit || !students ? (
		<Loading className="h-80" />
	) : (
		<StyledStudentSelect>
			<div className="grid md:grid-cols-6 grid-cols-2 z-10 gap-2 sticky top-0 pt-3 md:pt-0 bg-[rgba(64,53,127,0.9)] justify-items-center items-center">
				<Button onClick={goBack} className="md:col-span-1 col-span-2 row-start-1" $small outline>
					Change Mission
				</Button>
				<div className="md:col-span-4 col-span-2 row-start-2 md:row-start-1">
					<div className="my-2.5 uppercase text-4xl flex flex-wrap items-center gap-2 justify-center">
						MISSION CODE:
						<div className="p-1 rounded-lg bg-white/25">{missionCode}</div>
					</div>
					<p className="text-center mb-4">Select your name from the list below</p>
				</div>
			</div>
			{config.useTeacherCardSignIn && isMobileOnly && (
				<TeacherCard
					teamName="Flight director"
					missionCode={missionCode}
					type={students.type}
					buttonText="Teacher"
				/>
			)}

			{students.type !== 'junior' ? (
				<AdvancedStudentsList students={students} onClickFunction={submitStudentCode} />
			) : (
				<JuniorStudentsList students={students} onClickFunction={submitStudentCode} />
			)}

			{config.useTeacherCardSignIn && !isMobileOnly && (
				<TeacherCard
					teamName="Flight director"
					missionCode={missionCode}
					type={students.type}
					buttonText="Teacher"
				/>
			)}
			{config.featureFlags.createNewStudent && (
				<AddNewStudentButton
					missionCode={missionCode}
					onNewStudentCode={submitStudentCode}
					className="mx-auto mr-6 mb-4 w-fit animate-pulse-shadow [--pulse-shadow-from:theme(colors.white/70%)] [--pulse-shadow-to:theme(colors.white/0%)]">
					Don’t see your name?
				</AddNewStudentButton>
			)}
		</StyledStudentSelect>
	)
}

/**
 * Component that displays a Teacher Card for a mission.
 * @returns {React$Node}
 */
function TeacherCard({
	teamName,
	buttonText,
	type,
	missionCode,
}: {
	teamName: string,
	buttonText: string,
	type: 'junior' | 'juniorPlus',
	missionCode: string,
}) {
	const handleClick = () => {
		const newUrl = `/${missionCode}/control`
		if (window.location.pathname !== newUrl) {
			window.location.href = newUrl
		}
	}

	return (
		<ul className="justify-around">
			{type === 'junior' ? (
				<StudentCard name={buttonText} onClick={handleClick} className="dark" />
			) : (
				<Card className="dark">
					<h2>{teamName}</h2>
					<ul>
						<StudentCard name={buttonText} onClick={handleClick} />
					</ul>
				</Card>
			)}
		</ul>
	)
}

/**
 * Component that displays a list of students for a K-3 mission
 * @param {JrStudents} props.students a list of student models
 * @param {string => void} props.onClickFunction callback for when a student is selected from the list
 * @returns {React$Node}
 */
function JuniorStudentsList({
	students,
	onClickFunction,
}: {
	students: JrStudents,
	onClickFunction: string => void,
}) {
	return (
		<ul>
			{sortValues(students.data, (s1, s2) => (s1.name < s2.name ? -1 : 1)).map(
				({ id, name, code }) => (
					<StudentCard
						className="dark"
						name={name}
						onClick={(e: SyntheticMouseEvent<>) => {
							e.preventDefault()
							onClickFunction(code)
						}}
						key={id}
					/>
				)
			)}
		</ul>
	)
}

/**
 * Sorts students alphabetically. If the mission type is 'junior plus' ('4+') then students are also sorted
 * by their team.
 * @param {'junior' | 'juniorPlus'} missionType
 * @param {AdvancedStudentsObject} students an object map of student ids to to student objects
 * @returns {AdvancedStudent[]} a list of students sorted
 */
function sortStudentsByMissionType(
	missionType: 'junior' | 'juniorPlus',
	students: AdvancedStudentsObject
): AdvancedStudent[] {
	if (missionType === 'juniorPlus') {
		return sortValues(students, (s1, s2) =>
			s1.isLead ? -1 : s2.isLead ? -1 : s1.name < s2.name ? -1 : 1
		)
	} else {
		return sortValues(students, (s1, s2) => (s1.name < s2.name ? -1 : 1))
	}
}

/**
 * Component that displays a list of students for a 4+ mission.
 * @param {AdvancedStudents} students
 * @param {string => void} props.onClickFunction callback for when a student is selected from the list
 * @returns {React$Node}
 * @returns
 */
function AdvancedStudentsList({
	students,
	onClickFunction,
}: {
	students: AdvancedStudents,
	onClickFunction: string => void,
}) {
	return (
		<ul className="justify-around">
			{mapObject(
				students.data,
				team =>
					Object.keys(team.students).length > 0 && (
						<Card className="dark" key={team.id}>
							<h2>{team.name}</h2>
							<ul>
								{sortStudentsByMissionType(students.type, team.students).map(student => (
									<StudentCard
										name={student.name}
										isLead={student.isLead}
										station={student.station ? student.station.name : undefined}
										onClick={(e: SyntheticMouseEvent<>) => {
											e.preventDefault()
											onClickFunction(student.code)
										}}
										key={student.id}
									/>
								))}
							</ul>
						</Card>
					)
			)}
		</ul>
	)
}

const TeamLeadBadgeStyle = styled(TeamLeadBadge)`
	right: 5px;
	position: absolute;
	top: 5px;
	width: 15%;
`

/**
 * A Card to display a student name
 * @param {(SyntheticMouseEvent<>) => mixed} onClick cb for when student is selected
 * @param {string} name of student
 * @param {[string]=null} station optional value (station name)
 * @param {[boolean]=null} isLead optional value whether student is a leader for the team
 * @returns {React$Node}
 */
function StudentCard({
	onClick,
	name,
	station,
	isLead,
	className,
}: {
	onClick: (SyntheticMouseEvent<>) => mixed,
	name: string,
	station?: string,
	isLead?: boolean,
	className?: string,
}): React$Node {
	return (
		<StyledStudentCard onClick={onClick} className={className}>
			{isLead && <TeamLeadBadgeStyle />}
			<div data-sentry-mask>{name}</div>
			{station && <p>{station}</p>}
		</StyledStudentCard>
	)
}

const Card = styled.li`
	overflow: visible;
	border-radius: 4px;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.25);
	&.dark {
		background-color: rgba(64, 53, 127, 0.9);
	}
	.card-header,
	h2 {
		font-size: 1.2rem;
		text-align: center;
		padding: 10px 0;
		border-bottom: 1px solid ${ACCENT_BLUE_FAINT};
		margin-top: 0;

		&.top {
			margin-top: 8px;
			border-top: 1px solid ${ACCENT_BLUE_FAINT};
		}
	}
	ul {
		justify-content: center;
	}

	.body {
		margin: 0 8px;
	}
`

const StyledStudentCard = styled(Card)`
	position: relative;
	font-size: 1.35em;
	width: 200px;
	height: 100px;
	cursor: pointer;
	&:hover {
		background-color: ${ACCENT_BLUE_FAINT};
	}
	&.dark:hover {
		background-color: ${EMPTY_SPACE};
	}
	transition: background-color 1s;
`

const StyledStudentSelect = styled.div.attrs({ className: 'font-game-body' })`
	display: flex;
	flex-direction: column;
	font-weight: 400;

	> p {
		font-size: 20px;
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		li {
			list-style-type: none;
			display: inline-flex;
			flex-direction: column;
			margin: 24px;
			text-align: center;
			justify-content: center;
		}
	}
	overflow-y: auto;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px ${ACCENT_BLUE_FAINT};
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px ${ACCENT_BLUE_FAINT};
	}
`
