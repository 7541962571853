import { getStudentId, isTraining as selectIsTraining } from '../../stores/general'
import type { TrainingData, Map, MapInfo } from '@mission.io/mission-toolkit'
import type { ReduxStore } from '../../rootReducer'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { sendMessage } from '../../../websockets/websocket'

export function getStepIndex(store: ReduxStore): number {
	const training = getTraining(store)
	const currentIndex = training ? training.currentStepIndex : 0
	return currentIndex > -1 ? currentIndex : 0
}

export function getTraining(store: ReduxStore): ?TrainingData {
	if (!selectIsTraining(store)) {
		return null
	}
	const studentId = getStudentId(store.general)
	if (!studentId) return null
	if (store.jrPlusState.training.status === 'INACTIVE') {
		return null
	}
	return store.jrPlusState.training.students[studentId]
}

/**
 * Gets the portion of training state that contains the map and map info for the sensors station.
 * Returns null if training is not active.
 * @param {ReduxStore} store
 * @returns {?{map: Map, mapInfo: MapInfo}}
 */
export function getTrainingSensorData(store: ReduxStore): ?{ map: Map, mapInfo: MapInfo } {
	if (!selectIsTraining(store)) {
		return null
	}
	return store.jrPlusState.training.status === 'ACTIVE' ? store.jrPlusState.training.sensors : null
}

type AdvanceTrainingPayload = {
	nextStepIndex?: number,
}
/**
 * A hook returning a callback which will progress training to the next step when invoked.
 * It is optimistic because it does not check whether the mission is in training mode or not.

 * @returns {(payload?: AdvanceTrainingPayload) => void} advanceTraining: takes an optional parameter, payload.
 *  Allows the next training step to be explicitly set, with `nextStepIndex` 
 *  also allows for additional data to be passed to the server (useful when calling 
 *  advanceTraining after a specific user action such as choosing a health task type)
 */
export function useOptimisticAdvanceTraining(): (payload?: AdvanceTrainingPayload) => void {
	const index = useSelector(getStepIndex)
	const advanceTraining = useCallback(
		(payload?: AdvanceTrainingPayload) => {
			const actionPayload = {}
			actionPayload.nextStepIndex = index + 1
			if (payload) {
				const { nextStepIndex: nextStepIndex_override, ...extraData } = payload
				if (nextStepIndex_override !== undefined) {
					actionPayload.nextStepIndex = nextStepIndex_override
				}
				if (Object.keys(extraData).length > 0) {
					actionPayload.extraData = extraData
				}
			}
			sendMessage('ADVANCE_TRAINING', actionPayload)
		},
		[index]
	)
	return advanceTraining
}

/**
 * A hook returning a callback which will progress training IF the mission is in training mode.
 * See `useOptimisticAdvanceTraining` for further documentation.
 * @returns {cb~advanceTraining}
 */
export function useAdvanceTraining(): ?(extraData?: AdvanceTrainingPayload) => void {
	const isTraining = useSelector(selectIsTraining)
	const advanceTraining = useOptimisticAdvanceTraining()
	return isTraining ? advanceTraining : null
}
