// @flow
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Robots } from './Robots'
import type { WorkingRobotLookup } from './Robots/constants'
import ShipRepairs from './ShipRepairs'
import { getStudentRepairData } from '../../store/selectors/jrState'
import { sendMessage } from '../../websockets/websocket'

export default function Repairs(): React$Node {
	const { myFixCount, myBrokenSystems } = useSelector(getStudentRepairData)
	const [workingRobots, setWorkingRobots] = useState<WorkingRobotLookup>({})

	const fixBrokenSystem = (systemId: string, robotId: string, [x, y]: [number, number]) => {
		const pointLocation = { x: '50%', y: '50%' }
		setWorkingRobots(state => ({
			...state,
			[robotId]: { systemId: systemId },
		}))
		sendMessage('REPAIR', { systemId: systemId, robotId: robotId }, { location: pointLocation })
	}
	useEffect(() => {
		if (myBrokenSystems) {
			const busyRobots = {}
			Object.keys(myBrokenSystems).forEach(systemId => {
				if (myBrokenSystems[systemId].robotId) {
					busyRobots[myBrokenSystems[systemId].robotId] = systemId
				}
			})
			setWorkingRobots(state => {
				const newState = { ...state }
				Object.keys(state).forEach(robotId => {
					if (!busyRobots[robotId]) {
						delete newState[robotId]
					}
				})
				return newState
			})
		}
	}, [myBrokenSystems])
	/**
	 * like all REACT apps, this render returns the issues fixed along with team issues
	 * along with the line of robots and the ship with/without errors
	 */

	return (
		<div className="flex justify-around py-8 station">
			<Robots workingRobots={workingRobots} />
			<ShipRepairs
				myBrokenSystems={myBrokenSystems || {}}
				fixBrokenSystem={fixBrokenSystem}
				workingRobots={workingRobots}
			/>
			<div className="absolute flex items-center gap-1 left-20 top-4 bg-primary-200 rounded-lg p-2 text-xl">
				{myFixCount ? (
					<>
						<span className="text-center bg-primary-600 rounded-full p-1 w-10 h-auto text-primary-200 m-auto">
							{myFixCount}
						</span>
						System{myFixCount > 1 && 's'} Fixed
					</>
				) : (
					'Get to work!'
				)}
			</div>
		</div>
	)
}
