import React from 'react'
import styled from 'styled-components/macro'
import { rgba, APP_WHITE } from '../../../../constants/styles'
import { ExtenderFrame, InformationFrame, type ExtenderMedia } from './InformationFrame'
import { OptionsFrame } from './OptionsFrame'
import { Overlay } from '../../helperComponents'

/**
 * A frame for displaying a literacy event. This is the main component for the literacy event display.
 * It accepts jsx and other components as props, allowing a parent component to decide how to display different variations of the literacy event.
 * @param {string} props.orientation the orientation of the literacy event frame. Can be either 'VERTICAL' or 'HORIZONTAL'.
 * @param {string} props.headline the headline for the literacy event.
 * @param {string} props.optionsTitle the title for the options. This is the action title for the literacy event.
 * @param {Array<React$Node>} props.optionsJsx the options to display. Should be 1, 2, or 3 options.
 * @param {React$Node} props.informationJsx the information to display.
 * @param {React$Node} props.extenderJsx the extender to display. This is the portion of the literacy event frame that can be used to display additional information, such as an image.
 * @returns {React$Node}
 */
export function LiteracyEventFrame({
	orientation,
	optionsJsx,
	optionsTitle,
	informationJsx,
	headline,
	extenderMedia,
	exitStatus,
	onFinishExit,
	onClickOption,
}: {
	orientation: 'VERTICAL' | 'HORIZONTAL',
	headline: string,
	optionsTitle: string,
	optionsJsx: Array<React$Node>,
	informationJsx: React$Node,
	extenderMedia?: null | ExtenderMedia,
	exitStatus: ?{ wholeFrame?: boolean, onlyOptionFrame?: boolean },
	onFinishExit: () => void,
	onClickOption?: (index: ?number) => void,
}): React$Node {
	const [exitWholeFrame, setExitWholeFrame] = React.useState(false)

	React.useEffect(() => {
		if (!exitStatus) {
			setExitWholeFrame(false)
		}
	}, [exitStatus])

	return (
		<Overlay>
			<WithScroll className="p-[4vh] pl-20">
				<RelativePositioner orientation={orientation}>
					<InformationFrame
						headline={headline}
						isExiting={exitWholeFrame}
						onFinishExit={onFinishExit}>
						{informationJsx}
					</InformationFrame>
					{orientation === 'HORIZONTAL' && extenderMedia && (
						<ExtenderFrame isExiting={exitWholeFrame} media={extenderMedia} />
					)}
					<OptionsFrame
						isExiting={exitStatus?.wholeFrame || Boolean(exitStatus?.onlyOptionFrame)}
						onFinishExit={
							exitStatus?.onlyOptionFrame ? onFinishExit : () => setExitWholeFrame(true)
						}
						orientation={orientation}
						optionsJsx={optionsJsx}
						onClickOption={onClickOption}
						optionsTitle={optionsTitle}
					/>
				</RelativePositioner>
			</WithScroll>
		</Overlay>
	)
}

/**
 * Positions both parts of the frame - information and options - relative to each other.
 */
export function RelativePositioner({
	style,
	children,
	orientation,
}: {
	style?: {},
	children: React$Node,
	orientation: 'VERTICAL' | 'HORIZONTAL',
}): React$Node {
	return (
		<div
			css={`
				position: relative;
				width: 100%;
				height: 60%;
				display: flex;
			`}
			style={style}>
			{children}
		</div>
	)
}

const WithScroll = styled.div.attrs({
	className: 'overflow-y-auto h-[calc(100%-24px)] w-full',
})`
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${rgba(APP_WHITE, 0.2)};
	}
`
