import React from 'react'
import { useDrop, useDragLayer } from 'react-dnd'
import shipImage from '../../images/ship.svg'

import type { System } from '@mission.io/mission-toolkit'
import { WorkingRobot } from './Robots'
import { mapObject } from '../../utility/functions'

import { DRAG_TYPES } from '../../constants'
import { ROBOTS, type WorkingRobotLookup } from './Robots/constants'

const COLLISION_SIZE = 100

type Props = {
	workingRobots: WorkingRobotLookup,
	myBrokenSystems: { [string]: System },
	fixBrokenSystem: Function,
}

export default function ShipRepairs(props: Props): React$Node {
	return (
		<div
			className="relative bg-center bg-contain bg-no-repeat my-0 mx-4 py-2 px-8 w-[70%]"
			style={{ backgroundImage: `url(${shipImage})` }}>
			<CustomRepairsDragLayer />
			<div className="max-h-full aspect-[1.5] max-w-full relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
				{mapObject(props.myBrokenSystems, brokenSystem => {
					const robotAssigned = Object.keys(props.workingRobots).find(
						robotId => props.workingRobots[robotId].systemId === brokenSystem.id
					)
					return brokenSystem.fixed ? null : (
						<BrokenSystem
							brokenSystem={brokenSystem}
							key={brokenSystem.id}
							robotId={brokenSystem.robotId || robotAssigned}
							fixBrokenSystem={(robotId: string) => {
								props.fixBrokenSystem(brokenSystem.id, robotId, brokenSystem.point)
							}}
						/>
					)
				})}
			</div>
		</div>
	)
}

// Broken System for the robot to fix

type BrokenSystemProps = {
	brokenSystem: System,
	robotId: ?string,
	fixBrokenSystem: Function,
}

function BrokenSystem({ robotId, brokenSystem, fixBrokenSystem }: BrokenSystemProps) {
	const [{ isOver }, dropRef] = useDrop(() => ({
		canDrop: () => !robotId,
		drop: (item, ...params) => {
			fixBrokenSystem(item.id)
		},
		accept: DRAG_TYPES.ROBOT,
		collect: monitor => ({
			isOver: monitor.isOver(),
		}),
	}))

	return (
		<div
			ref={dropRef}
			className={robotId ? '' : 'animate-pulse'}
			style={getBrokenSystemStyle(brokenSystem, isOver)}>
			{robotId ? (
				<WorkingRobot
					id={robotId}
					systemId={brokenSystem.id}
					dimensions={ROBOTS[robotId].dimensions}
				/>
			) : (
				''
			)}
		</div>
	)
}

/**
 * This function helps determine the size of the blinking dot
 *	so size is how big the issue's blink is. FunFact the robot can be dropped
 *	to fix an issue anywhere within the size radius. (So size 1000 means after the blink hits 1000,
 *	the robot can be dropped almost anywhere in the screen and it will fix the issue)
 *
 */
function getBrokenSystemStyle(brokenSystem: System, isOver: boolean) {
	const size = isOver && !brokenSystem.robotId ? 100 : 25
	return {
		position: 'absolute',
		backgroundImage: `radial-gradient(${size / 2}px, rgb(255, 0, 0), rgba(255, 0, 0, 0))`,
		top: `calc(${brokenSystem.point[1]}% - ${COLLISION_SIZE / 2}px)`,
		left: `calc(${brokenSystem.point[0]}% - ${COLLISION_SIZE / 2}px)`,
		padding: `${COLLISION_SIZE / 2}px`,
		width: '0px',
		height: '0px',
	}
}

/**
 * Custom Drag Layer for the robot using react-dnd's useDrag to display the robot being dragged and dropped
 */
function CustomRepairsDragLayer(): React$Node {
	const { currentOffset, dimensions, src, isDragging } = useDragLayer(monitor => {
		if (!monitor.getItem() || monitor.getItemType() !== DRAG_TYPES.ROBOT) return {}
		const robotId = monitor.getItem()?.id
		const robot = ROBOTS[robotId]
		return {
			isDragging: monitor.isDragging(),
			currentOffset: monitor.getClientOffset(),
			dimensions: robot.dimensions,
			src: robot.dragSrc,
		}
	}, [])

	if (!isDragging || !dimensions || !src || !currentOffset) {
		return null
	}

	const style = {
		top: currentOffset.y,
		left: currentOffset.x,
		width: dimensions[0],
		height: dimensions[1],
		backgroundImage: `url(${src})`,
	}

	return (
		<div
			className={
				'fixed pointer-events-none -translate-x-1/2 -translate-y-1/2 bg-no-repeat bg-contain bg-center cursor-grabbing'
			}
			style={style}
		/>
	)
}
