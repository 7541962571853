// @flow
import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { CONTROLLER_Z_INDEX } from '../../constants/styles'
import type { StyledType } from '../../types/utilityTypes'

/**
 * A Popover component that will display an inline popup when the given trigger react element is clicked on.
 * Example usage:
 * <Popover trigger={<div>Click to see Popover!</div>}>
 *   <div>Popover Content</div>
 * </Popover>
 * Note: The trigger passed to the popover will be wrapped in a button html tag to facilitate queries for testing.
 * @param {{trigger: React$Node, children: React.ReactNode}} props
 * @returns A React component
 */
export default function PopoverWrapper({
	trigger,
	children,
	positions = ['bottom', 'top'],
	parentElement,
	arrowSize = 0,
	arrowStyle,
	align = 'center',
	hideOnInsideClick = true,
	...props
}: {
	padding?: number,
	positions?: string[],
	arrowColor?: string,
	arrowSize?: number,
	trigger: React$Node,
	children: React$Node,
	className?: string,
	parentElement?: HTMLElement,
	hideOnInsideClick?: boolean,
	style?: Object,
	triggerOnHover?: boolean,
	handleClickTrigger?: () => void,
	triggerStyle?: Object,
	align?: string,
	arrowStyle?: Object,
}): React$Node {
	const [showPopup, setShowPopup] = useState(false)

	const togglePopup = () => {
		setShowPopup(state => !state)
	}
	const closePopup = () => {
		setShowPopup(false)
	}
	const themeContext = useContext(ThemeContext)
	return (
		<Popover
			isOpen={showPopup}
			parentElement={parentElement}
			positions={positions}
			align={align}
			padding={props.padding ? props.padding : themeContext.spacingHalfValue}
			onClickOutside={closePopup}
			reposition={true}
			containerStyle={{ zIndex: CONTROLLER_Z_INDEX }}
			content={({ position, childRect, popoverRect }) => (
				<PopupView
					{...props}
					onClick={hideOnInsideClick ? togglePopup : undefined}
					$show={showPopup}>
					<ArrowContainer
						position={position}
						childRect={childRect}
						popoverRect={popoverRect}
						arrowColor={props.arrowColor ? props.arrowColor : themeContext.lightPrimaryColor}
						arrowSize={arrowSize}
						arrowStyle={arrowStyle}>
						<>{children}</>
					</ArrowContainer>
				</PopupView>
			)}>
			<TriggerButton
				onClick={() => {
					togglePopup()
					if (props.handleClickTrigger) props.handleClickTrigger()
				}}
				onMouseOver={() => {
					if (props.triggerOnHover) togglePopup()
				}}
				onMouseOut={() => {
					if (props.triggerOnHover) closePopup()
				}}
				style={props.triggerStyle}>
				{trigger}
			</TriggerButton>
		</Popover>
	)
}

const TriggerButton = styled.button`
	border: none;
	background-image: none;
	background-color: transparent;
	box-shadow: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
`

export const PopupView: StyledType<{ $show: boolean }> = styled.div`
	${({ $show }) =>
		$show
			? `
		animation: fadeIn 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000); 
		visibility: visible;`
			: `visibility: hidden;`}

	border-radius: 6px;
	width: fit-content;
	max-height: 60vh;
	min-width: 100px;
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`
