import React, { useState, useCallback } from 'react'
import ConnectingCanvas from './ConnectingCanvas'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getCurrentMapId, getVisitedMaps } from '../../store/selectors/jrPlusState/sensor'
import { getMainObjectiveFromMission, type Map } from '@mission.io/mission-toolkit'
import { NO_OBJECTIVE } from '@mission.io/mission-toolkit/constants'
import { getMissionState } from '../../store/stores/general'
import { Prompt } from '../../components/CreativeCanvas/components/Prompt'
import { rgba, APP_WHITE, PROGRESS, COLLECTED_DATA_Z_INDEX } from '../../constants/styles'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { FaStar } from 'react-icons/fa'
import type { VisitedMap } from './types'
import { useLineDrawingContext, getCardId } from './LineDrawingContext'
import { ListStyles, EmptyList } from '../../components/CollectedData/Container'
import { useContextualData } from '../../store/selectors/selectorHooks'
import { useMaps } from '../../store/stores/staticData'
import type { StudentSpecificContextualDataEntry } from '@mission.io/mission-toolkit'
import { ContextualDataCard } from '../../components/ContextualData/ContextualDataCard'

type CollectedDataProps = {
	isCulminatingMoment: boolean,
}

/**
 * A data view for Sensor maps. Displays contextual data for all maps, with a tab for each map. This component is displayed on the Sensor station
 * and during Culminating Moments.
 */
export default function CollectedData({ isCulminatingMoment }: CollectedDataProps): React$Node {
	const currentMapId: ?string = useSelector(getCurrentMapId)
	const visitedMaps: Array<VisitedMap> = useSelector(getVisitedMaps)
	const [tabIndex, setTabIndex] = useState(0)
	const { resetConnectingIds } = useLineDrawingContext()

	const handleScroll = useCallback(() => {
		resetConnectingIds && resetConnectingIds({ drawLines: false })
	}, [resetConnectingIds])

	const maps = useMaps()

	// build a tab for each map
	const tabs = []
	const tabPanels = []
	let tabIndexForCurrentMap = null
	for (let index = visitedMaps.length - 1; index >= 0; index--) {
		const map = visitedMaps[index]
		const fullMap = maps.find(m => m._id === map.id)
		if (!fullMap) {
			continue
		}
		if (currentMapId && map.id === currentMapId) {
			tabIndexForCurrentMap = tabs.length
		}
		tabs.push(
			<StyledTab key={map.id}>
				{map.id === currentMapId && <Star />}
				{map.name}
			</StyledTab>
		)
		tabPanels.push(
			<StyledTabPanel key={map.id} onScroll={handleScroll} className="space-y-3">
				<ContextualDataForMap
					{...{
						map: fullMap,
						includeUnscannedObjects: isCulminatingMoment,
						onHover: contextualData => {
							if (contextualData.dataSource.mapObjectId) {
								resetConnectingIds?.({
									drawLines: true,
									mapObjectId: contextualData.dataSource.mapObjectId,
									cardId: getCardId(contextualData.id),
								})
							} else {
								resetConnectingIds?.({
									drawLines: false,
								})
							}
						},
						onBlur: () => {
							resetConnectingIds?.({
								drawLines: false,
							})
						},
					}}
				/>
			</StyledTabPanel>
		)
	}

	const mainObj = useSelector(state => getMainObjectiveFromMission(getMissionState(state)))
	// Only show the objective if it exists and it is NOT the default, "no current objective"
	const showObjective = mainObj?.objectiveText && mainObj.objectiveText !== NO_OBJECTIVE

	return (
		<>
			<ConnectingCanvas />
			<Wrapper $isCulminatingMoment={isCulminatingMoment} className="collectedData">
				{showObjective ? <Prompt content={`Objective: ${mainObj?.objectiveText}`} /> : null}
				{tabs.length > 0 && tabPanels.length > 0 ? (
					<div>
						{tabIndexForCurrentMap === tabIndex && tabs.length > 1 && (
							<CurrentLocation>Current Location</CurrentLocation>
						)}
						<Sleeve>
							<Tabs
								selectedTabPanelClassName="is-selected"
								selectedIndex={tabIndex}
								onSelect={selectedTabIndex => setTabIndex(selectedTabIndex)}>
								<StyledTabList>{tabs}</StyledTabList>
								{tabPanels}
							</Tabs>
						</Sleeve>
					</div>
				) : (
					<EmptyList>No Scanned Data Results</EmptyList>
				)}
			</Wrapper>
		</>
	)
}

/**
 * Displays all contextual data for a given map
 *
 * @param props
 * @param props.map - The map to display contextual data for
 * @param props.includeUnscannedObjects - Whether or not to include data from unscanned objects
 * @param props.onHover - Called when a card is hovered over
 * @param props.onBlur - Called when a card is no longer hovered over
 *
 */
function ContextualDataForMap({
	map,
	includeUnscannedObjects,
	onHover,
	onBlur,
}: {
	map: Map,
	includeUnscannedObjects: boolean,
	onHover: (contextualData: StudentSpecificContextualDataEntry) => mixed,
	onBlur: () => void,
}): React$Node {
	const contextualData = useContextualData({
		type: 'MAP',
		map,
		includeUnscannedObjects,
	})

	return contextualData.length > 0 ? (
		contextualData.map((contextualData: StudentSpecificContextualDataEntry, index) => {
			return (
				<ContextualDataCard
					{...{
						key: contextualData.id,
						lookup: {
							type: 'MAP',
							map,
							includeUnscannedObjects,
							id: contextualData.id,
						},
						contextualData,
						id: getCardId(contextualData.id),
						onHover: () => {
							onHover(contextualData)
						},
						onBlur,
					}}
				/>
			)
		})
	) : (
		<EmptyList>No Scanned Data Results</EmptyList>
	)
}

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	gap: var(--spacing2x);
	align-items; center;
	flex-direction: column;
	z-index: ${COLLECTED_DATA_Z_INDEX};
	${({ $isCulminatingMoment }) => ($isCulminatingMoment ? '' : 'width: 50%;')};
	overflow-x: hidden;
	position: relative;
	overflow-y: hidden;
`

const Sleeve = styled.div`
	overflow-y: hidden;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${rgba(APP_WHITE, 0.2)};
	}
`

const CurrentLocation = styled.div`
	top: 0;
	font-family: 'Orbitron', sans-serif;
	letter-spacing: 2px;
	font-size: 1rem;
	color: ${PROGRESS};
	text-align: left;
	text-transform: uppercase;
	padding: 0px 0 10px 20px;
`

const StyledTabList = styled(TabList)`
	list-style-type: none;
	padding: 4px;
	display: flex;
	margin: 0;
`
// $FlowFixMe[incompatible-use] We need to set `tabsRole` to use react-tabs with styled-components https://github.com/reactjs/react-tabs/issues/148
StyledTabList.tabsRole = 'TabList'

const StyledTab = styled(Tab).attrs({
	selectedClassName: 'selected',
	className: 'flex items-center px-2 py-1 text-base',
})`
	user-select: none;
	cursor: arrow;
	font-family: 'Orbitron', sans-serif;
	text-transform: uppercase;
	margin: 0 8px 0 -4px;
	overflow: hidden;
	background-color: #192b5e;
	color: white;
	border-radius: var(--spacing) var(--spacing) 0 0;
	cursor: pointer;

	&.selected {
		background-color: var(--color-primary-darker) !important;
		outline: none;
		color: white !important;
	}

	&:focus {
		background-color: var(--color-primary-darker) !important;
		outline: none;
	}
`
// $FlowFixMe[incompatible-use] We need to set `tabsRole` to use react-tabs with styled-components https://github.com/reactjs/react-tabs/issues/148
StyledTab.tabsRole = 'Tab'

const StyledTabPanel = styled(TabPanel)`
	display: none;
	position: absolute;
	${ListStyles}

	&.is-selected {
		display: block;
	}
`
// $FlowFixMe[incompatible-use] We need to set `tabsRole` to use react-tabs with styled-components https://github.com/reactjs/react-tabs/issues/148
StyledTabPanel.tabsRole = 'TabPanel'
// $FlowFixMe[incompatible-use] We need to set `tabsRole` to use react-tabs with styled-components https://github.com/reactjs/react-tabs/issues/148
StyledTab.tabsRole = 'Tab'

const Star = styled(FaStar)`
	color: ${PROGRESS};
	width: 15px;
	height: 15px;
	margin: 0 5px 5px 0;
`
