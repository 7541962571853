import React from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag } from 'react-dnd'

import { DRAG_TYPES } from '../../../constants'
import { ROBOTS } from './constants'
import classnames from 'classnames'

/**
 * A robot component that can be dragged and dropped to fix repairs on the ship
 * @param {string} props.id - The id of the robot
 * @param {string} props.className - The class name of the robot
 * @param {boolean} props.isBusy - The busy status of the robot
 * @returns
 */
export default function RobotComponent({
	id,
	className,
	isBusy,
}: {
	id: string,
	className?: string,
	isBusy: boolean,
}): React$Node {
	const [{ isDragging }, dragRef, dragPreview] = useDrag(
		() => ({
			type: DRAG_TYPES.ROBOT,
			collect: monitor => ({ isDragging: monitor.isDragging() }),
			item: () => ({ id }),
			canDrag: () => !isBusy,
		}),
		[isBusy, id]
	)

	React.useEffect(() => {
		// Hide the default drag preview since the drag preview is handled by the drag layer
		dragPreview(getEmptyImage(), { captureDraggingState: true })
	}, [dragPreview])

	return (
		<>
			<div
				ref={dragRef}
				className={classnames(className, 'cursor-grab bg-no-repeat bg-contain bg-center')}
				style={{
					backgroundImage: `url(${ROBOTS[id].src})`,
					opacity: isDragging || isBusy ? 0.1 : 1,
				}}
			/>
		</>
	)
}
