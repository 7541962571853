import config from '../config'

import React from 'react'

/**
 * A component that wraps the login screen and redirect page. Displays a background video and sets up page layout.
 * @param {React$Node} props.children
 */
export function LandingPageWrapper({ children }: { children: React$Node }): React$Node {
	const year = new Date().getFullYear()

	return (
		<>
			<BackgroundVideo />
			<div className="w-screen h-full font-game-body font-thin py-12 text-white overflow-auto absolute">
				{children}
				<div className="mb-2 text-center">
					<p className="text-sm">
						Copyright &copy; 2017-
						{year}{' '}
						<a
							href={config.companyMarketingWebsiteUrl}
							className="text-neutral-light hover:opacity-80">
							{config.companyName}
						</a>{' '}
						| All rights reserved
					</p>
				</div>
			</div>
		</>
	)
}

const BACKGROUND_VIDEO =
	'https://s3.us-west-2.amazonaws.com/infinid.public/videos/loginBackgroundVideo.mp4'

function BackgroundVideo() {
	return (
		<div className="absolute w-full h-full bg-black">
			<video autoPlay playsInline muted loop className="w-full h-full object-cover">
				<source src={BACKGROUND_VIDEO} />
			</video>
		</div>
	)
}
