import React from 'react'
import { Station } from '../../components/basics'
import { connect } from 'react-redux'
import ShooterGame from './components/ShooterGame'

import type { ReduxStore } from '../../store/rootReducer'
import { sendMessage } from '../../websockets/websocket'

function Defense() {
	const miss = () => {
		sendMessage('DEFENSE', { targetHit: false })
	}
	const hit = (location, instanceId) => {
		sendMessage('DEFENSE', { targetHit: true, instanceId }, { location })
	}
	return (
		<Station>
			<ShooterGame onMiss={miss} onHit={hit} />
		</Station>
	)
}
const mapStateToProps = (state: ReduxStore) => {
	return {
		status: state.jrPlusState.stationData.defense.status,
	}
}

export default (connect(mapStateToProps, {})(Defense): (props: $Shape<{||}>) => React$Node)
