import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import styled from 'styled-components/macro'
import { FaQuestionCircle } from 'react-icons/fa'
import { getAnalyticsCategoryScore, useCategories } from './categories'
import { getAnalyticsOverview } from '@mission.io/mission-toolkit'
import { getStudentId, getStudentName, isSurvey } from '../../store/stores/general'
import { getAnalyticsStore } from '../../store/selectors/sharedSelectors'
import { getMissionName } from '../../store/stores/staticData'
import MainSummaryBackgroundImage from '../../images/HoneyCombBackground.jpg'
import { getFormattedDate, reduceObject } from '../../utility/functions'
import { Loading } from '../basics'
import CategoryModal from './CategoryModal'
import PastMissions from './PastMissions'
import QuestionReview from './QuestionReview'
import ProficiencyLegend from './ProficiencyLegend'
import { isController } from '../../utility/urls'
import Popover from '../Controller/Popover'
import { CustomModal } from '../Controller/Dialogs/CustomModal.jsx'
import { Button } from '../basics/Buttons.jsx'

/**
 * At the start of the end of mission sequence, this screen appears, so that students can see how
 * they did during the mission.
 */
export default function MissionReviewScreen(): React$Node {
	const analyticsStore = useSelector(getAnalyticsStore)
	const phaseIsSurvey = useSelector(isSurvey)

	const isRunningTeacherStation = isController()

	return (
		<PageBackground
			className={classnames(
				!isRunningTeacherStation && 'p-12',
				'font-game-body h-full w-full text-white overflow-y-auto  [--proficiency-level-1:#ff6f1e] [--proficiency-level-2:var(--color-warning)] [--proficiency-level-3:#98eb43] [--proficiency-level-4:#38f9ff]'
			)}>
			{analyticsStore ? (
				<div>
					<MainSummary />
					<PastMissions />
					<QuestionReview />
				</div>
			) : (
				<>
					<h1 className="mt-[20%] text-center">
						{phaseIsSurvey
							? 'Waiting for crew members to complete survey'
							: 'Analyzing student performance...'}
					</h1>
					<Loading className="h-52" />
				</>
			)}
		</PageBackground>
	)
}

/**
 * A component that displays a question mark icon with a popover that explains that the analytics are incomplete
 */
export function IncompleteAnalyticsPopover({ className }: { className?: string }): React$Node {
	return (
		<div className={`absolute right-2 top-2 ${className ?? ''}`}>
			<Popover
				trigger={<FaQuestionCircle size={12} />}
				triggerOnHover={true}
				hideOnInsideClick={true}
				align="center">
				<div className=" bg-primary shadow-xl p-5 text-white rounded max-w-sm text-center">
					The Mission wasn’t able to gather enough data for a reliable score in this category.
				</div>
			</Popover>
		</div>
	)
}

/**
 * A modal that informs the user that the analytics are incomplete
 */
function IncompleteAnalyticsModal({ onClose }: { onClose: () => void }): React$Node {
	return (
		<CustomModal
			title="Heads Up!"
			className="max-w-2xl"
			onClose={onClose}
			buttons={[<Button onClick={onClose}>Got it!</Button>]}>
			<div>
				We’re updating our system to work with the new stuff in this Mission. The scores you see
				might not be perfect yet, but we’re working on it!
			</div>
		</CustomModal>
	)
}

/**
 * The main summary for the mission that was just completed.
 * @returns {React$Node}
 */
function MainSummary(): React$Node {
	const analyticsStore = useSelector(getAnalyticsStore)
	const studentId = useSelector(store => getStudentId(store.general))
	const { overview, student, studentPoints } = useMemo(() => {
		const summary = {}
		if (analyticsStore) {
			summary.overview = getAnalyticsOverview(analyticsStore.analytics)
			if (studentId) {
				summary.student = analyticsStore.analytics?.studentAnalytics?.[studentId]
				summary.studentPoints = summary.student?.studentPoints
					? reduceObject(
							summary.student.studentPoints,
							(total, studentPointValue) => total + studentPointValue,
							0
					  ) +
					  reduceObject(
							analyticsStore.analytics?.extraCrewPoints ?? {},
							(total, crewPoints) => total + crewPoints,
							0
					  )
					: null
			}
		}
		return summary
	}, [analyticsStore, studentId])
	const hasIncompleteAnalytics = analyticsStore?.hasIncompleteAnalytics
	const missionName = useSelector(getMissionName)
	const studentName = useSelector(getStudentName)
	const date = getFormattedDate(new Date())
	const [category, setCategory] = useState()
	const [showIncompleteModal, setShowIncompleteModal] = useState(false)
	const categories = useCategories()
	const ModalContent = category?.ModalContent

	useEffect(() => {
		if (hasIncompleteAnalytics) {
			setShowIncompleteModal(true)
		}
	}, [hasIncompleteAnalytics])

	return (
		<>
			{showIncompleteModal && (
				<IncompleteAnalyticsModal onClose={() => setShowIncompleteModal(false)} />
			)}
			<div
				className="p-4 mb-8 rounded w-full"
				style={{ background: `url(${MainSummaryBackgroundImage})` }}>
				<div className="flex align-center mb-4">
					<div className="flex-1">
						<h3>{studentName || 'Not available'}</h3>
						<div>Date: {date}</div>
						<div>Mission: {missionName || 'Unknown'}</div>
					</div>
					<div className="flex-2 bg-primary-600 p-4 rounded text-center relative">
						<h2>Your Mission Score: {getPointDisplay(studentPoints)}</h2>
						<div>Average Student Score: {getPointDisplay(overview?.averageStudentScore)}</div>
						{hasIncompleteAnalytics && <IncompleteAnalyticsPopover />}
					</div>
					<div className="flex-1" />
				</div>
				<div className="flex items-center w-full">
					<div className="mr-2 w-fit">
						<div className="bg-primary-600 p-6 rounded">
							<ProficiencyLegend />
						</div>
					</div>
					<div className="flex justify-around flex-grow">
						{categories.map(category => {
							const score = student
								? getAnalyticsCategoryScore(
										category.accessor(student),
										analyticsStore?.analytics?.weights
								  )
								: null
							return (
								<div
									key={category.title}
									data-testid={category.key}
									className="text-center max-w-24"
									style={{
										width: `calc(100% / ${categories.length} - 8px)`,
									}}>
									<div
										style={{
											backgroundColor: score
												? `var(--proficiency-level-${score})`
												: 'var(--color-primary-600)',
											color: score ? 'black' : 'white',
										}}
										className="drop-shadow-lg aspect-square rounded-full relative"
										data-testid={`${category.key}-button`}
										onClick={() => setCategory(category)}>
										{score ? (
											<div className="inset-center text-3xl">{score}</div>
										) : (
											<div className="inset-center opacity-75">{NA_TEXT}</div>
										)}
									</div>
									<div className="relative">
										{hasIncompleteAnalytics && (
											<IncompleteAnalyticsPopover className="!right-0 !-top-3" />
										)}
										<div>{category.title}</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				{category && ModalContent && (
					<CategoryModal open={!!category} onClose={() => setCategory(null)}>
						<ModalContent title={`How to improve your ${category.title} score`} />
					</CategoryModal>
				)}
			</div>
		</>
	)
}

/**
 * Gets a string representation for the given score. If the score is null or undefined, returns
 * 'N/A'. Otherwise, returns the score with up to one decimal place.
 */
function getPointDisplay(points: ?number) {
	if (points == null) {
		return NA_TEXT
	}

	return new Intl.NumberFormat('en-US', {
		maximumFractionDigits: 0,
	}).format(points)
}

const PageBackground = styled.div`
	h2,
	h3 {
		// Undo default styles for headers
		font-family: proxima-nova, sans-serif;
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0;
		text-transform: capitalize;
	}

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: #5b5889;
		border-radius: 20px;
	}

	--canvas-border-radius: ${({ theme }) => theme.spacing};
`

export const NA_TEXT = '—'
