import React, { useState, useRef, useCallback } from 'react'
import useInterval from 'use-interval'
import styled from 'styled-components'
import Progress from '../../components/Progress'
import { useSpring } from '@react-spring/web'
import { connect } from 'react-redux'
import type { ReduxStore } from '../../store/rootReducer'
import { clamp } from '../../utility/functions'
import { Wand, Wedge, ScanProgress } from './components'
import { getStudentValue } from '../../utility/selectors'
import { DISPLAY_STATUS } from './components/extras'
import { getGroupScans } from '../../store/selectors/jrState'
import { getScannerDefault } from '../../store/stores/staticData'
import { sendMessage } from '../../websockets/websocket'

const Circle = styled.circle`
	cx: 0;
	cy: 0;
	r: ${props => props.r || 1};
`

const InnerCircleStyle = styled(Circle)`
	opacity: ${props => props.opacity || 1};
	fill: #989898;
	transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1) 0ms;
`

const StyledCircle = styled(Circle)`
	fill: rgba(0, 0, 0, 0.6);
`

const CenteringWrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
`

const StyledScanner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: middle;
	text-align: center;
	height: 100%;
	padding: 30px;
`

const StyledSVG = styled.svg`
	margin: auto 0;
	height: 100%;
	max-height: 100%;
	width: 100%;
	max-width: 100%;
	flex: 1 1 0;
	transform: rotate(-0.25turn);
`

const SvgHorizontalWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`

const SvgVerticalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`

function ScannerSVG({ children, viewBox }: { viewBox: string, children: React$Node }) {
	return (
		<SvgHorizontalWrapper>
			<SvgVerticalWrapper>
				<StyledSVG viewBox={viewBox} height="2" width="2" preserveAspectRatio="xMidYMid meet">
					<defs>
						<Circle id="circle-frame" x="0" y="0" r="1" />
					</defs>
					<clipPath id="new-frame">
						<use xlinkHref="#circle-frame" overflow="invisible" />
					</clipPath>
					<g clipPath="url(#new-frame)">{children}</g>
				</StyledSVG>
			</SvgVerticalWrapper>
		</SvgHorizontalWrapper>
	)
}

const InnerCircle = React.memo(function InnerCircle({ show, ...rest }: { show: boolean }) {
	return <InnerCircleStyle {...rest} r={show ? 1 : 0.1} opacity={show ? 0.5 : 1} />
})

const WAND_TIME_UNIT = 1 // in milliseconds

const CIRCLE_DISTANCE_COVERED_PER_UNIT = 0.001

type Props = {
	groupScans: number,
	groupRequiredScans: number,
	active: boolean,
	wedgeStart: number,
	wedgeLength: number,
	lastStatus: boolean,
}

function Scanner({
	groupScans,
	groupRequiredScans,
	active,
	wedgeStart,
	wedgeLength,
	lastStatus,
}: Props) {
	const [wandPosition, setWandPosition] = useState(0)
	const [clickStatus, setClickStatus] = useState(null)

	const ref = useRef(null)

	// Set the interval for the spinning wand
	useInterval(() => {
		active &&
			setWandPosition(state => {
				const blockClick = clickStatus !== null
				if (blockClick) {
					return 0
				} else return (state + CIRCLE_DISTANCE_COVERED_PER_UNIT) % 1
			})
	}, WAND_TIME_UNIT)

	// Handler for clicks on the screen
	const handleClick = useCallback(() => {
		if (!clickStatus && ref.current) {
			const { x, y, height, width } = ref.current.getBoundingClientRect()

			const newX: number = x + width / 2
			const newY: number = y + height / 2
			const pointLocation = { x: newX, y: newY }

			sendMessage('SCANNER', { scanPosition: wandPosition }, { location: pointLocation })
			if (wandTouchesWedge(wandPosition, wedgeStart, wedgeLength)) {
				setClickStatus({ status: DISPLAY_STATUS.SUCCESS })
			} else {
				setClickStatus({ status: DISPLAY_STATUS.FAIL })
			}
		}
	}, [clickStatus, wandPosition, wedgeStart, wedgeLength])

	const wedgeProps = useSpring({ start: wedgeStart })

	const progressOutOf100 = clamp((groupScans / groupRequiredScans) * 100, 0, 100)

	return (
		<StyledScanner className="station" onClick={handleClick}>
			<CenteringWrapper>
				<ScannerSVG viewBox="-1 -1 2 2">
					<StyledCircle x="0" y="0" r="1" />
					<Wedge ref={ref} start={wedgeProps.start} />
					<Wand start={wandPosition} disabled={!!clickStatus} />
					<InnerCircle r={0.1} show={!!clickStatus} />
					<ScanProgress show={clickStatus} setClickStatus={setClickStatus} />
				</ScannerSVG>
			</CenteringWrapper>
			<Progress vertical={true} progress={progressOutOf100} animate={true} />
		</StyledScanner>
	)
}

export function wandTouchesWedge(
	position: number,
	targetValue: number,
	targetSpan: number
): boolean {
	const targetLimit = (targetValue + targetSpan) % 1
	if (position >= targetValue && position <= targetLimit) return true
	else if (targetValue <= 1 && targetLimit < targetValue) {
		// This case checks for when the target spans across 360 degrees. I.E starts at 350 degrees and ends at 10 degrees
		if (position <= targetLimit || (position >= targetValue && position <= 1)) return true
	}
	return false
}

function mapStateToProps(state: ReduxStore) {
	const studentScan = getStudentValue(state, state.jrState.stationData.scanner.studentScans)
	return {
		groupScans: getGroupScans(state),
		groupRequiredScans: state.jrState.stationData.scanner.groupRequiredScans,
		active: state.jrState.stationData.scanner.isActive,
		wedgeStart: studentScan ? studentScan.target.value : getScannerDefault(state),
		wedgeLength: studentScan ? studentScan.target.span : 0.1,
		lastStatus: studentScan ? studentScan.lastStatus : null,
	}
}

export default (connect(mapStateToProps)(Scanner): (props: $Shape<{||}>) => React$Node)
